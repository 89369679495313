import useSWR from 'swr';
import config from '../../utils/data/config';
import { fetcher } from '../../utils/data/fetcher';


export const useOngoingBids = (path, token) => {
    const { data, error } = useSWR([`${config.baseUrl}/${path}`, token], fetcher)
    //show error here
    return {
        data: data ? data.bids : [],
        isLoading: !error && !data,
        isError: error
    }
}

export const useClosedBids = (path, token) => {
    const { data, error } = useSWR([`${config.baseUrl}/${path}`, token], fetcher)
    //show error here
    return {
        data: data ? data.bids : [],
        isLoading: !error && !data,
        isError: error
    }
}


export const useBids = (path, token) => {
    const { data, error } = useSWR([`${config.baseUrl}/${path}`, token], fetcher)
    //show error here
    console.log(data)
    return {
        data: data ? data : {},
        isLoading: !error && !data,
        isError: error
    }
}

export const useBidsByTenderId = (path, token) => {
    const { data, error } = useSWR(path ? [`${config.baseUrl}/${path}`, token] : null, fetcher)
    //show error here

    return {
        data: data ? data.bids : [],
        isLoading: !error && !data,
        isError: error
    }
}

export const useBid = (path, token) => {
    const { data, error } = useSWR([`${config.baseUrl}/${path}`, token], fetcher)
    //show error here
    return {
        data: data ? data.bid : {},
        isLoading: !error && !data,
        isError: error
    }
}


export const useBidHistory = (path, token) => {
    const { data, error } = useSWR([`${config.baseUrl}/${path}`, token], fetcher)

    return {
        bidHistory: data ? data.bids : [],
        loadingBidHistory: !error && !data,
        isError: error
    }
}


export const useOpenTenders = (path, token) => {
    const { data, error } = useSWR([`${config.baseUrl}/${path}`, token], fetcher)
    //show error here
    return {
        data: data ? data.tenders.filter(item => item.status === 0) : [],
        isLoading: !error && !data,
        isError: error
    }
}

export const useAdminActions = (path, token) => {
    const { data, error } = useSWR([`${config.baseUrl}/${path}`, token], fetcher)
    //show error here
    return {
        data: data ? formatAdmnActions(data.adminactions) : [],
        isLoading: !error && !data,
        isError: error
    }
}

export const useBidsForSubItem = (path, token) => {
    const { data, error } = useSWR(path ? [`${config.baseUrl}/${path}`, token] : null, fetcher)
    return {
        bids: data ? data.bids : [],
        isLoading: !error && !data && path !== null,
        isError: error
    }
}


const formatAdmnActions = (actions) => {
    let actionsArray = [];
    Object.keys(actions).forEach(action => actionsArray.push({ action, id: actions[action] }))
    return actionsArray
}




