import React, { useContext, useState } from 'react';

import { useLocation, useHistory } from 'react-router-dom';
import { Box, Stack, Heading, Flex } from '@chakra-ui/core'
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Table, Button } from 'antd';
import { Context } from '../context/Context'
import { useBids } from './helpers/fetcher';
import globalStyles from '../../globalStyles';
import { formatCurrency } from '../utils/formatCurrency';

function ReverseAuctionBids() {
    const columns = [
        {
            title: 'Supplier',
            dataIndex: 'supplierid',
            key: 'supplierid',
        },
        {
            title: 'Unit',
            dataIndex: 'units',
            key: 'units',
        },
        {
            title: 'Requested Quantity',
            dataIndex: 'requestedQuantity',
            key: 'requestedQuantity',
        },
        {
            title: 'Bid Quantity',
            dataIndex: 'supplierQuantity',
            key: 'supplierQuantity',
        },
        {
            title: 'Unit Bid Price',
            dataIndex: 'unitprice',
            key: 'unitprice',
            render: (text) => {
                return <span>{formatCurrency(parseInt(text))}</span>
            }
        },
        {
            title: 'Total Bid Price',
            dataIndex: 'totalbidprice',
            key: 'totalbidprice',
            render: (text) => {
                return <span>{formatCurrency(parseInt(text))}</span>
            }
        },
        // {
        //     title: 'Action',
        //     dataIndex: 'action',
        //     key: 'action',
        //     render: (_, record) => {
        //         return (
        //             <Popover
        //                 visible={visible}
        //                 placement="topLeft"
        //                 onVisibleChange={(visible) => setVisible(visible)}
        //                 content={content(record)} title="Action" trigger="click">
        //                 <IconButton
        //                     // onClick={() => history.push(`/user/${record.id}`)}
        //                     variant="outline"
        //                     isRound
        //                     aria-label="Send email"
        //                     icon={MdMoreVert}
        //                 />
        //             </Popover>
        //         )
        //     }
        // },
    ];
    const { user } = useContext(Context);

    const location = useLocation()
    const history = useHistory()
    const tenderId = location.pathname.split("/")[2]
    const [page] = useState(1)

    const filterPage = `&page=${page}`;

    const filterPath = filterPage

    const { data, isLoading } = useBids(`filterbidsbytenderid?tenderid=${tenderId}${filterPath}`, user.token)

    return (
        <Box>
            <Stack spacing={6}>
                <Flex justify="space-between">
                    <Heading as="h1" size="sm" color={globalStyles.green}>Reverse Auction</Heading>
                    <Flex>
                        <Button onClick={history.goBack} type="link" icon={<ArrowLeftOutlined />}>Back</Button>
                    </Flex>
                </Flex>
                <Box shadow="lg" borderWidth="1px" p="5">
                    <Table rowKey={record => record.id} loading={isLoading} columns={columns} dataSource={data} />
                </Box>
            </Stack>

        </Box>
    )
}
export default ReverseAuctionBids;