import React from 'react';
import { Field } from 'formik';
import { FormControl, FormLabel, Heading, InputGroup, Input, FormErrorMessage, InputLeftAddon } from '@chakra-ui/core';
import globalStyles from '../../../globalStyles';

function ChakraInput(props) {
    const { label, name, prefix, required, ...rest } = props;

    return (
        <Field name={name}>
            {(props) => {
                const { field, form } = props
                return (
                    <FormControl
                        isInvalid={form.errors[name] && form.touched[name]}
                        isRequired={required === false ? required : true}
                    >
                        <FormLabel htmlFor={name} mb={2}>
                            <Heading as="a" fontSize="sm">{label}</Heading>
                        </FormLabel>
                        {prefix ?
                            <InputGroup >
                                <InputLeftAddon children={prefix} />
                                <Input
                                    {...field}
                                    {...rest}
                                    id={name}
                                    border="0"
                                    autocomplete="off"
                                  
                                    bg={globalStyles.lightGrey}
                                    focusBorderColor={globalStyles.green} />
                            </InputGroup>
                            :
                            <Input
                                {...field}
                                {...rest}
                                id={name}
                                border="0"
                                autocomplete="off"
                                bg={globalStyles.lightGrey}
                                focusBorderColor={globalStyles.green} />
                        }
                        <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
                    </FormControl>
                )
            }}
        </Field >
    )
}

export default ChakraInput;


