import React, { useContext } from 'react';
import { mutate } from 'swr';
import { Box, Stack, Heading, Flex, useDisclosure } from '@chakra-ui/core'
import { Link } from 'react-router-dom';
import { Button, Space } from 'antd';
import { useToasts } from 'react-toast-notifications'

import axios from 'axios';
import { Context } from '../context/Context'
import globalStyles from '../../globalStyles';
import { useUsers } from './helpers/fetcher';
import Can from '../utils/rbac/Can'
import UploadFile from '../upload/UploadDragger';
import config from '../utils/data/config'
import UsersTable from './helpers/data/users';

function ManageUsers() {
    const { user } = useContext(Context)
    const { addToast } = useToasts()

    const requestOptions = {
        headers: { "Authorization": `Bearer ${user.token}` },
    }

    const roleString = user.role
    const { isOpen, onOpen, onClose } = useDisclosure();

    const userUrl = roleString === 1 ? `superadminviewusers` : `getallusers`;
    const { data, isLoading } = useUsers(userUrl, user.token)

    const usersToShow = data ? data.filter(item => item.role !== 1) : []

    const deleteUser = async (id) => {
        try {
            const res = await axios.post(`${config.baseUrl}/deleteuser`, { id }, requestOptions)
            console.log(res)
        }
        catch (e) {
            console.log(e.message)
        }
    }


    const checkValue = async (val, record) => {
        const body = {
            userId: record.id,
            is_active: val ? 1 : 0
        }
        //mutate
        try {
            const res = await axios.post(`${config.baseUrl}/changeuseractivestatus`, body, { headers: { "Authorization": `Bearer ${user.token}` } })
            if (res.data.error) {
                showToast("error", res.data.error)
            }
            else {
                mutate([`${config.baseUrl}/getallusers`, user.token])
                showToast("success", "Successfully changed status")
            }
        }
        catch (e) {
            console.log(e)
            showToast("error", e.message)
        }
    }

    const showToast = (status, message) => {
        addToast(message, { appearance: status })
    }

    return (
        <Box>
            <Stack spacing={6}>
                <Flex justify="space-between">
                    <Heading as="h1" size="sm" color={globalStyles.green}>Manage Users</Heading>
                    <Flex>
                        <Space size={24}>
                            <Can
                                role={roleString}
                                perform="manage-users:add"
                                yes={() => (
                                    <Link to="/users/add">
                                        <Button type="primary">Add New User</Button>
                                    </Link>
                                )}
                                no={() => null}
                            />
                            <Can
                                role={roleString}
                                perform="manage-users:upload"
                                yes={() => (
                                    <Button onClick={onOpen} type="primary">Upload Users</Button>
                                )}
                                no={() => null}
                            />
                        </Space>
                    </Flex>
                </Flex>

                <Box shadow="lg" borderWidth="1px" p="5">
                    <UsersTable
                        loading={isLoading}
                        dataSource={usersToShow}
                        deleteUser={deleteUser}
                        checkValue={checkValue}
                    />
                    {/* <Table size="middle" rowKey={record => record.id} loading={isLoading} columns={columns} dataSource={usersToShow} /> */}
                </Box>
                <UploadFile downloadUrl="downloadusersample" isOpen={isOpen} onClose={onClose} module={"Users"} url="importusers" />
            </Stack>

        </Box>

    )
}
export default ManageUsers;