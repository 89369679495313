import React from 'react';
import { Table } from 'antd';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { IconButton } from '@chakra-ui/core';
import { formatCurrency } from '../../../utils/formatCurrency';

const { Column } = Table;

function OngoingBidsTable(props) {
    const history = useHistory()

    return (
        <Table rowKey={record => record.id} loading={props.loading} dataSource={props.dataSource}>
            <Column
                title="Tender Name"
                dataIndex="tenderName"
                key="tenderName"
            />
            <Column
                title="Unit"
                dataIndex="units"
                key="units"
            />
            <Column
                title="Requested Quantity"
                dataIndex="requestedQuantity"
                key="requestedQuantity"
            />
            <Column
                title="Bid Quantity"
                dataIndex="supplierQuantity"
                key="supplierQuantity"
            />
            <Column
                title="Unit Bid Price"
                dataIndex="unitprice"
                key="unitprice"
                render={(text) => <span>{formatCurrency(parseInt(text))}</span>}
            />
            <Column
                title="Total Bid Price"
                dataIndex="totalbidprice"
                key="totalbidprice"
                render={(text) => <span>{formatCurrency(parseInt(text))}</span>}
            />
            <Column
                title="Date Due"
                dataIndex="dueDate"
                key="dueDate"
                render={(text) => <span>{dayjs(text).format('DD/MM/YYYY')}</span>}
            />

            <Column
                title="Status"
                dataIndex="status"
                key="status"
                render={(text) => text === 1 ?
                    <span style={{ color: 'orange', fontWeight: 'bold' }}>Pending</span> :
                    <span style={{ color: 'green', fontWeight: 'bold' }}>Submitted</span>}
            />

            <Column
                title="Edit"
                dataIndex="status"
                key="edit"
                render={(text, record) =>
                    <IconButton
                        onClick={() => history.push(`/supplier-bids/edit/${record.id}`)}
                        variant="outline"
                        isRound
                        aria-label="Send email"
                        icon="edit"
                    />
                }
            />
        </Table>
    )
}

export default OngoingBidsTable
