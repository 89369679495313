import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
    Box, Stack, Flex, Heading,
    Alert,
    AlertIcon,
    Text,
    AccordionItem, AccordionHeader,
    Accordion,
    AccordionPanel,
    AccordionIcon,
    Tag,
    Input,
    Avatar,
    Divider,
    Button,
    FormControl,

} from '@chakra-ui/core';
import * as dayjs from 'dayjs';
import { useToasts } from 'react-toast-notifications';
import axios from 'axios'
import { Button as AntButton, Spin, Table, Modal } from 'antd';
import { AiOutlineDownload } from 'react-icons/ai';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { MdFileUpload } from 'react-icons/md';
import UploadButton from '../upload/UploadButton';
import globalStyles from '../../globalStyles'
import { Context } from '../context/Context';
import { useTender } from '../tenders/helpers/fetcher';
import config from '../utils/data/config'


const { Column } = Table;


function Respond() {
    const history = useHistory()
    const location = useLocation()
    const { addToast } = useToasts()
    const [file, setFile] = useState(null)
    const { user, dispatchUser } = useContext(Context)
    const [subItems, setSubItems] = useState([])
    const [submitting, setSubmitting] = useState(false)
    const [loginLoading, setLoginLoading] = useState(false)
    const id = location.pathname.split("/")[3]
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const { data } = useTender(`gettenderbyid/${id}`, user.token)

    const selectedTender = Object.keys(data).length > 0 ? data : null

    const login = async () => {
        if (!email) {
            showToast("error", "Please enter your email address")
            return
        }
        if (!password) {
            showToast("error", "Please enter your password")
            return
        }
        setLoginLoading(true)
        try {
            const res = await axios.post(`${config.baseUrl}/loginuser`, { email, password })
            if (res.data.error) {
                showToast("error", res.data.error)
            }
            else {
                const user = { ...res.data.user, token: res.data.token }
                dispatchUser({
                    type: 'LOG_IN_RESPOND', user: user
                });
                setIsModalVisible(false)
            }
        }
        catch (e) {
            showToast("error", e.message)
        }
        finally {
            setLoginLoading(false)
        }
    }

    const showToast = (status, message) => {
        addToast(message, { appearance: status })
    }


    const handleSubmit = async () => {
        if (!subItems.length) {
            showToast("error", "Please bid for at least one item")
            return;
        }
        const isSubItemsValid = subItems.every(item => item.supplierQuantity && item.unitprice && item.dueDate)
        if (!isSubItemsValid) {
            showToast("error", "Please complete bid for item")
            return;
        }
        setSubmitting(true)
        const body = { tenderid: id, subItems: JSON.stringify(subItems), attachement: file }
        try {
            const res = await axios.post(`${config.baseUrl}/suppliercreatebid`, body, { headers: { "Authorization": `Bearer ${user.token}` } })
            if (res.data.error) {
                showToast("error", res.data.error)
            }
            else {
                showToast("success", "Successfully created bid")
                history.goBack() //go to supplier's bids
            }
        }
        catch (e) {
            console.log(e)
            showToast("error", e.message)
        }
        finally {
            setSubmitting(false)
        }

    }


    const updateUnitPrice = (record, value) => {

        const found = subItems.find(item => item.subItemId === record.id);
        if (!found) {
            const subItem = {
                subItemId: record.id,
                unitprice: parseInt(value),
                supplierQuantity: 0,
                dueDate: "",
            }
            setSubItems([...subItems, subItem])
            return
        }
        const arr = subItems.filter(item => item.subItemId !== record.id);
        const subItem = { ...found, unitprice: parseInt(value) }
        setSubItems([...arr, subItem])
    }


    const updateBidQuantity = (record, value) => {
        const found = subItems.find(item => item.subItemId === record.id);
        if (!found) {
            const subItem = {
                subItemId: record.id,
                unitprice: 0,
                supplierQuantity: parseInt(value),
                dueDate: "",
            }
            setSubItems([...subItems, subItem])
            return
        }
        const arr = subItems.filter(item => item.subItemId !== record.id);
        const subItem = { ...found, supplierQuantity: parseInt(value) }
        setSubItems([...arr, subItem])
    }

    const updateDueDate = (record, value) => {
        const found = subItems.find(item => item.subItemId === record.id);
        if (!found) {
            const subItem = {
                subItemId: record.id,
                unitprice: 0,
                supplierQuantity: 0,
                dueDate: value,
            }
            setSubItems([...subItems, subItem])
            return
        }
        const arr = subItems.filter(item => item.subItemId !== record.id);
        const subItem = { ...found, dueDate: value }
        setSubItems([...arr, subItem])
    }

    const renderAlert = (selectedTender) => {
        if (selectedTender.hasBidded === "yes") {
            return (
                <Alert status="warning">
                    <AlertIcon />
                    You have already placed a bid for this tender
                </Alert>
            )
        }
        else if (selectedTender.hasBidded === "no") {
            return null
        }
        else {
            return (
                <Alert status="warning">
                    <AlertIcon />
                                   You need to log in to bid for this tender
                    <AntButton onClick={showModal} type="link" >Log In</AntButton>
                </Alert>
            )
        }
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        login()
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <Box>
            <Stack spacing={6}>
                <Flex justify="space-between">
                    <Heading as="h1" size="sm" color={globalStyles.green}>Create Bid</Heading>
                    <Flex>
                        <AntButton onClick={history.goBack} type="link" icon={<ArrowLeftOutlined />}>Back</AntButton>
                    </Flex>
                </Flex>
                <>
                    {selectedTender ?
                        <Box shadow="lg" borderWidth="1px" p="3">
                            {renderAlert(selectedTender)}

                            <br />
                            <Flex justiFfy="space-between" px="4" pt="2">
                                <Stack isInline spacing={4}>
                                    <Avatar name="Christian Nwamba" src="https://bit.ly/code-beast" size="lg" />
                                    <Stack spacing={1}>
                                        <Text color="#7F63F4">{selectedTender.createdBy?.name} {selectedTender.createdBy?.lastName}</Text>
                                        <Text fontSize="xs" color={globalStyles.darkGrey}>Due Date:  {dayjs(selectedTender.dueDate).format('DD MMM YYYY')}</Text>
                                        <Text fontSize="xs" color={globalStyles.darkGrey}>Created:   {dayjs(selectedTender.created_at).format('DD MMM YYYY')}</Text>
                                        <Box>
                                            {selectedTender.status === 0 ? <Heading as="h6" size="xs" color={globalStyles.green}>Open</Heading> : <Heading as="h6" size="xs" color={globalStyles.red}>Closed</Heading>}
                                        </Box>
                                    </Stack>
                                </Stack>
                            </Flex>
                            <Divider />
                            <Box px="4" pb="8">
                                <Stack spacing={8}>
                                    <Box>
                                        <Text color={globalStyles.green}>{selectedTender.tenderName}</Text>
                                        {/* <Text color={globalStyles.darkGrey} fontSize="xs">To:me,chisom@gmail.com</Text> */}
                                    </Box>
                                    {/* <Box>
                                        <Text color={globalStyles.darkGrey} fontSize="xs">Ingredia Nutrisha, A collection of textile samples lay spread out on the table - Samsa was a travelling salesman - and above it there hung a picture</Text>
                                    </Box> */}
                                    <Box>
                                        <Text color={globalStyles.darkGrey} fontSize="xs">
                                            {selectedTender.description}
                                        </Text>
                                    </Box>
                                    {selectedTender.attachement &&
                                        <Box w="50%" borderWidth="1px" p="4">
                                            <Flex justify="space-between" align="center">
                                                <Box>
                                                    <Text fontWeight="bold" fontSize="xs">Attachment</Text>
                                                    <Text fontSize="xs" color={globalStyles.darkGrey}>Australia-trip.ppt</Text>
                                                </Box>
                                                <Heading as="h4" size="md"><AiOutlineDownload /></Heading>
                                            </Flex>
                                        </Box>
                                    }
                                </Stack>
                            </Box>
                            <br />
                            <Box px="4">
                                <Flex justify="space-between" align="center">
                                    <Heading as="h6" size="xs">BID TERMS</Heading>
                                    <Stack isInline spacing={8} align="center">
                                        <Text>Upload Additional Information</Text>
                                        <UploadButton accept={true} path="adminuploadattachment" setFile={setFile}>
                                            <Button
                                                leftIcon={MdFileUpload}
                                                variantColor="teal"
                                                variant="outline"
                                                _hover={{ bg: globalStyles.green, color: 'white' }}
                                            >
                                                Upload
                                        </Button>
                                        </UploadButton>
                                    </Stack>
                                </Flex>

                            </Box>
                            <br />
                            <Accordion allowToggle>
                                {JSON.parse(selectedTender.tenderItemsObject).map(item => {
                                    return (
                                        <AccordionItem key={item.id}>
                                            <AccordionHeader>
                                                <Box flex="1" textAlign="left">
                                                    <Flex justify="space-between" align="center">
                                                        <Box>
                                                            <Heading as="h6" size="xs">{item.itemName}</Heading>
                                                            <Text fontSize="xs" color={globalStyles.darkGrey}>Staggered Delivery</Text>
                                                        </Box>
                                                        <Box>
                                                            <Tag variantColor="cyan" rounded="full" size="sm" variant="subtle">
                                                                Yes
                                                            </Tag>
                                                            <Text fontSize="xs" color={globalStyles.darkGrey}>Fixed Prices</Text>
                                                        </Box>
                                                        <Box>
                                                            <Tag variantColor="cyan" rounded="full" size="sm">
                                                                90 Days
                                                            </Tag>
                                                            <Text fontSize="xs" color={globalStyles.darkGrey}>Invoice Payment Terms</Text>
                                                        </Box>
                                                        <AccordionIcon />
                                                    </Flex>
                                                </Box>
                                            </AccordionHeader>
                                            <AccordionPanel pb={4}>
                                                <Table pagination={false} rowKey={record => record.id} dataSource={item.subItems} size="small">
                                                    <Column
                                                        title={<Heading as="h6" size="xs">Material</Heading>}
                                                        dataIndex='materialDescription'
                                                        key='materialDescription'

                                                    />
                                                    <Column
                                                        title={<Heading as="h6" size="xs">Unit</Heading>}
                                                        dataIndex='unit'
                                                        key='unit'
                                                    />
                                                    <Column
                                                        title={<Heading as="h6" size="xs">Quantity</Heading>}
                                                        dataIndex='quantity'
                                                        key='quantity'
                                                    />
                                                    <Column
                                                        title={<Heading as="h6" size="xs">Location</Heading>}
                                                        dataIndex='location'
                                                        key='location'
                                                    />
                                                    <Column
                                                        title={<Heading as="h6" size="xs">Delivery Time</Heading>}
                                                        dataIndex='deliveryTime'
                                                        key='deliveryTime'
                                                        render={(text) => dayjs(text).format('DD MMM YYYY')}
                                                    />
                                                    <Column
                                                        title={<Heading as="h6" size="xs">Your Bid Quantity</Heading>}
                                                        dataIndex='id'
                                                        key='supplierBidQuantity'
                                                        render={(text, record) => {
                                                            return (
                                                                <Input
                                                                    type="number"
                                                                    onChange={e => {
                                                                        updateBidQuantity(record, e.target.value)
                                                                    }}
                                                                />
                                                            )
                                                        }}
                                                    />
                                                    <Column
                                                        title={<Heading as="h6" size="xs">Bid Amount per Unit</Heading>}
                                                        dataIndex='id'
                                                        key='supplierUnitPrice'
                                                        render={(text, record) => {
                                                            return (
                                                                <Input
                                                                    type="number"
                                                                    onChange={e => {
                                                                        updateUnitPrice(record, e.target.value)
                                                                    }}
                                                                />
                                                            )
                                                        }}
                                                    />
                                                    <Column
                                                        title={<Heading as="h6" size="xs">Delivery Time</Heading>}
                                                        dataIndex='id'
                                                        key='dueDate'
                                                        render={(text, record) => {
                                                            return (
                                                                <Input
                                                                    type="date"
                                                                    onChange={e => {
                                                                        updateDueDate(record, e.target.value)
                                                                    }}
                                                                />
                                                            )
                                                        }}
                                                    />
                                                </Table>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    )
                                })}
                            </Accordion>
                            <br />

                            <Box mt={8}>
                                {selectedTender.hasBidded === "no" &&
                                    <Button
                                        onClick={handleSubmit}
                                        disabled={submitting}
                                        isLoading={submitting}
                                        loadingText="Submitting"
                                        type="submit"
                                        bg={globalStyles.green}
                                        color="white"
                                        _hover={{ bg: globalStyles.green }}
                                    >
                                        Submit
                                    </Button>
                                }

                            </Box>
                        </Box>
                        :
                        <Flex shadow="lg" borderWidth="1px" p="3" w="100%" h="50vh" justify="center" align="center">
                            <Spin />
                        </Flex>
                    }
                </>
            </Stack>
            <Modal title="Log In" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>
                <Stack spacing={8}>
                    <Box>
                        <FormControl
                            isRequired
                        >
                            {/* <FormLabel htmlFor="email">Email</FormLabel> */}
                            <Input
                                onChange={(e) => setEmail(e.target.value)}
                                focusBorderColor={globalStyles.green}
                                id="email"
                                placeholder="Email"
                                bg={globalStyles.lightGrey} />
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl
                            isRequired
                        >
                            {/* <FormLabel htmlFor="password">Password</FormLabel> */}
                            <Input
                                onChange={(e) => setPassword(e.target.value)}
                                focusBorderColor={globalStyles.green}
                                type="password"
                                id="password"
                                placeholder="Password"
                                bg="#F0F0F1"
                                border="0" />
                        </FormControl>
                    </Box>
                    <Button
                        disabled={loginLoading}
                        isLoading={loginLoading}
                        loadingText="Logging In"
                        width="100%"
                        type="submit"
                        onClick={() => login()}
                        bg={globalStyles.green}
                        color="white"
                        _hover={{ bg: globalStyles.green }}
                    >
                        Login
                    </Button>
                </Stack>
            </Modal>
        </Box >
    )
}

export default Respond;