import React, { useContext } from 'react';
import { Box, Flex, Stack, Avatar, Heading, Text } from '@chakra-ui/core';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { IoMdCog } from "react-icons/io"
import Icon, {
    DashboardOutlined,
    UserOutlined,
    SettingOutlined,
    UserSwitchOutlined,
    AuditOutlined,
    FileDoneOutlined,
    MessageOutlined,
} from '@ant-design/icons';
import Header from './Header';
import globalStyles from '../../globalStyles';
import { Context } from '../context/Context';
import Can from '../utils/rbac/Can';

const { SubMenu } = Menu;

function AppDrawer({ children }) {
    const { user } = useContext(Context)
    const location = useLocation()

    const roleString = user.role

    const menuProps = {
        mode: "inline",
        selectedKeys: [location.pathname, `/${location.pathname.split("/")[1]}`],
        // defaultSelectedKeys: [location.pathname],
        // defaultOpenKeys: [location.pathname.split("/")[1]],
        style: { borderRight: 0 }
    }
    return (
        <Box>
            <Header />
            <Box>
                <Flex>
                    <Box
                        pt={8}
                        borderRightWidth="1px"
                        width={[
                            "100%", // base
                            "50%", // 480px upwards
                            "25%", // 768px upwards
                            "20%", // 992px upwards
                        ]} height="100vh"
                    >
                        <Stack spacing={4}>
                            {user?.firstName &&
                                <Stack isInline spacing={4} px="6" align="center">
                                    <Avatar showBorder name={`${user.firstName} ${user.lastName}`} size="sm" p={4} bg={globalStyles.green} />
                                    <Stack spacing={1}>
                                        <Heading as="h2" size="sm" color={globalStyles.darkGrey}>
                                            {user.firstName} {user.lastName}
                                        </Heading>
                                        <Text fontSize="xs" color={globalStyles.darkGrey}>{user.roleObject ? user.roleObject.role : ""}</Text>
                                        <Text fontSize="xs" color={globalStyles.darkGrey}>{user.aliass}</Text>
                                    </Stack>
                                </Stack>
                            }
                            {/* dashboard */}
                            <Can
                                role={roleString}
                                perform="dashboard:visit"
                                yes={() => (
                                    <Menu
                                        {...menuProps}>
                                        <Menu.Item key="/dashboard">
                                            <Stack direction='horizontal' align="center" spacing={8}>
                                                <DashboardOutlined />
                                                <Link to="/dashboard">Dashboard</Link>
                                            </Stack>
                                        </Menu.Item>
                                    </Menu>

                                )}
                                no={() => null}
                            />
                            <Can
                                role={roleString}
                                perform="manage-roles-sub:visit"
                                yes={() => (
                                    <Menu
                                        {...menuProps}
                                    >
                                        <SubMenu key="users" title={
                                            <Stack direction='horizontal' align="center" spacing={8}>
                                                <UserOutlined />
                                                <Text color={globalStyles.gr}>User Management</Text>
                                            </Stack>}>
                                            <Menu.Item key="/users">
                                                <Link to="/users">Manage Users</Link>
                                            </Menu.Item>
                                            <Menu.Item key="/roles">
                                                <Link to="/roles">Manage Roles</Link>
                                            </Menu.Item>
                                        </SubMenu>
                                    </Menu>

                                )}
                                no={() => null}
                            />

                            <Can
                                role={roleString}
                                perform="manage-users-sub:visit"
                                yes={() => (
                                    <Menu
                                        {...menuProps}
                                    >
                                        <SubMenu key="users" title={
                                            <Stack direction='horizontal' align="center" spacing={8}>
                                                <UserOutlined />
                                                <Text color={globalStyles.gr}>User Management</Text>
                                            </Stack>}>
                                            <Menu.Item key="/users">
                                                <Link to="/users">Manage Users</Link>
                                            </Menu.Item>
                                        </SubMenu>
                                    </Menu>
                                )}
                                no={() => null}
                            />
                            <Can
                                role={roleString}
                                perform="manage-suppliers:visit"
                                yes={() => (
                                    <Menu
                                        {...menuProps}
                                    >
                                        <Menu.Item key="/suppliers">
                                            <UserSwitchOutlined />
                                            <Link to="/suppliers">Manage Suppliers</Link>
                                        </Menu.Item>
                                    </Menu>

                                )}
                                no={() => null}
                            />

                            <Can
                                role={roleString}
                                perform="tenders:visit"
                                yes={() => (
                                    <Menu {...menuProps}>
                                        <Menu.Item key="/tenders">
                                            <Stack direction='horizontal' align="center" spacing={8}>
                                                <SettingOutlined />
                                                <Link to="/tenders">Tenders</Link>
                                            </Stack>
                                        </Menu.Item>
                                    </Menu>
                                )}
                                no={() => null}
                            />
                            <Can
                                role={roleString}
                                perform="bids:visit"
                                yes={() => (
                                    <Menu {...menuProps}>
                                        <Menu.Item key="/supplier-bids">
                                            <Stack direction='horizontal' align="center" spacing={8}>
                                                <SettingOutlined />
                                                <Link to="/supplier-bids">Bids</Link>
                                            </Stack>
                                        </Menu.Item>
                                    </Menu>
                                )}
                                no={() => null}
                            />

                            <Can
                                role={roleString}
                                perform="reverse-auction:visit"
                                yes={() => (
                                    <Menu {...menuProps}>
                                        <Menu.Item key="/vendor-reverse-auction">
                                            <Stack direction='horizontal' align="center" spacing={8}>
                                                <SettingOutlined />
                                                <Link to="/vendor-reverse-auction">Reverse Auction</Link>
                                            </Stack>
                                        </Menu.Item>
                                    </Menu>
                                )}
                                no={() => null}
                            />

                            <Can
                                role={roleString}
                                perform="manage-sourcing:visit"
                                yes={() => (
                                    <Menu
                                        {...menuProps}
                                    >
                                        <SubMenu key="sourcing" title={
                                            <Stack direction='horizontal' align="center" spacing={8}>
                                                <UserOutlined />
                                                <Text color={globalStyles.gr}>Manage Sourcing</Text>
                                            </Stack>}>
                                            <Menu.Item key="/tenders">
                                                <Link to="/tenders">Tenders</Link>
                                            </Menu.Item>
                                            <Menu.Item key="/bids">
                                                <Link to="/bids">Bids</Link>
                                            </Menu.Item>
                                            <Menu.Item key="/reverse-auction">
                                                <Link to="/reverse-auction">Reverse Auction</Link>
                                            </Menu.Item>
                                        </SubMenu>
                                    </Menu>

                                )}
                                no={() => null}
                            />
                            <Can
                                role={roleString}
                                perform="super-manage-sourcing:visit"
                                yes={() => (
                                    <Menu
                                        {...menuProps}
                                    >
                                        <SubMenu key="sourcing" title={
                                            <Stack direction='horizontal' align="center" spacing={8}>
                                                <UserOutlined />
                                                <Text color={globalStyles.gr}>Manage Sourcing</Text>
                                            </Stack>}>
                                            <Menu.Item key="/tenders">
                                                <Link to="/tenders">Tenders</Link>
                                            </Menu.Item>
                                            <Menu.Item key="/bids">
                                                <Link to="/bids">Bids</Link>
                                            </Menu.Item>
                                        </SubMenu>
                                    </Menu>
                                )}
                                no={() => null}
                            />
                            <Can
                                role={roleString}
                                perform="configuration:visit"
                                yes={() => (
                                    <Can
                                        role={roleString}
                                        perform="categories:manage"
                                        yes={() => (
                                            <Menu
                                                {...menuProps}
                                            >
                                                <SubMenu key="categories" title={
                                                    <Stack direction='horizontal' align="center" spacing={8}>
                                                        <SettingOutlined />
                                                        <Text color={globalStyles.gr}>Settings</Text>
                                                    </Stack>}>
                                                    <Menu.Item key="/categories">
                                                        <Link to="/categories">Manage Categories</Link>
                                                    </Menu.Item>
                                                    <Menu.Item key="/subcategories">
                                                        <Link to="/subcategories">Manage Sub-Categories</Link>
                                                    </Menu.Item>
                                                    <Menu.Item key="/locations">
                                                        <Link to="/locations">Manage Locations</Link>
                                                    </Menu.Item>
                                                    <Menu.Item key="/departments">
                                                        <Link to="/departments">Manage Departments</Link>
                                                    </Menu.Item>
                                                    <Menu.Item key="/units">
                                                        <Link to="/units">Manage Units</Link>
                                                    </Menu.Item>
                                                    <Menu.Item key="/edit-profile">
                                                        <Link to={`/edit-profile/${user.id}`}>Edit Profile</Link>
                                                    </Menu.Item>
                                                    <Menu.Item key="/change-password">
                                                        <Link to="/change-password">Change Password</Link>
                                                    </Menu.Item>

                                                </SubMenu>
                                            </Menu>
                                        )}
                                        no={() => (
                                            <Menu
                                                {...menuProps}
                                            >
                                                <SubMenu key="categories" title={
                                                    <Stack direction='horizontal' align="center" spacing={8}>
                                                        <SettingOutlined />
                                                        <Text color={globalStyles.gr}>Settings</Text>
                                                    </Stack>}>
                                                    <Menu.Item key="/locations">
                                                        <Link to="/locations">Manage Locations</Link>
                                                    </Menu.Item>
                                                    <Menu.Item key="/change-password">
                                                        <Link to="/change-password">Change Password</Link>
                                                    </Menu.Item>
                                                </SubMenu>
                                            </Menu>

                                        )}
                                    />
                                )}
                                no={() => null}
                            />
                            <Can
                                role={roleString}
                                perform="merge-contracts:visit"
                                yes={() => (
                                    <Menu {...menuProps}>
                                        <Menu.Item key="/contracts">
                                            <Stack direction='horizontal' align="center" spacing={8}>
                                                <FileDoneOutlined />
                                                <Link to="/contracts">Manage Contracts <Text fontSize="xs" as="i">(Coming Soon)</Text></Link>
                                            </Stack>

                                        </Menu.Item>
                                    </Menu>
                                )}
                                no={() => null}
                            />

                            <Can
                                role={roleString}
                                perform="performace:manage"
                                yes={() => (
                                    <Menu {...menuProps}>
                                        <Menu.Item key="/performance">
                                            <Stack direction='horizontal' align="center" spacing={8}>
                                                <Icon component={IoMdCog} style={{ fontSize: 16 }} />
                                                <Link to="/performance">Manage Performance <Text fontSize="xs" as="i">(Coming...)</Text></Link>
                                            </Stack>

                                        </Menu.Item>
                                    </Menu>
                                )}
                                no={() => null}
                            />
                            <Can
                                role={roleString}
                                perform="notifications:visit"
                                yes={() => (
                                    <Menu
                                        {...menuProps}
                                    >
                                        <SubMenu key="notifications" title={
                                            <Stack direction='horizontal' align="center" spacing={8}>
                                                <MessageOutlined />
                                                <Text color={globalStyles.gr}>Notifications</Text>
                                            </Stack>}>
                                            <Menu.Item key="/notifications/setup">
                                                <Link to="/notifications/setup">Setup</Link>
                                            </Menu.Item>
                                            <Menu.Item key="/notifications/new">
                                                <Link to="/notifications/new">New Notification</Link>
                                            </Menu.Item>
                                            <Menu.Item key="/notifications/all">
                                                <Link to="/notifications/all">Sent Notifications</Link>
                                            </Menu.Item>
                                        </SubMenu>
                                    </Menu>
                                )}
                                no={() => null}
                            />
                            <Can
                                role={roleString}
                                perform="tenants:visit"
                                yes={() => (
                                    <Menu {...menuProps}>
                                        <Menu.Item key="/tenants">
                                            <Stack direction='horizontal' align="center" spacing={8}>
                                                <Icon component={IoMdCog} style={{ fontSize: 16 }} />
                                                <Link to="/tenants">Tenants</Link>
                                            </Stack>

                                        </Menu.Item>
                                    </Menu>
                                )}
                                no={() => null}
                            />

                            <Can
                                role={roleString}
                                perform="audit-log:visit"
                                yes={() => (
                                    <Menu {...menuProps}>
                                        <Menu.Item key="/audit-log">
                                            <Stack direction='horizontal' align="center" spacing={8}>
                                                <AuditOutlined />
                                                <Link to="/audit-log">Audit Log</Link>
                                            </Stack>

                                        </Menu.Item>
                                    </Menu>
                                )}
                                no={() => null}
                            />

                            <Can
                                role={roleString}
                                perform="audit-trail:visit"
                                yes={() => (
                                    <Menu {...menuProps}>
                                        <Menu.Item key="/audit-trail">
                                            <Stack direction='horizontal' align="center" spacing={8}>
                                                <AuditOutlined />
                                                <Link to="/audit-trail">Audit Log</Link>
                                            </Stack>

                                        </Menu.Item>
                                    </Menu>
                                )}
                                no={() => null}
                            />
                        </Stack>
                    </Box>
                    <Box p={8} bg="#FAFAFB" flexGrow="1">
                        {children}
                    </Box>
                </Flex>
            </Box>

        </Box >
    )
}

export default AppDrawer;