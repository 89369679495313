import React, { createContext, useReducer, useEffect } from 'react';
import { authReducer } from '../reducers/authReducer'

export const Context = createContext();


const ContextProvider = ({ children }) => {
    const [user, dispatchUser] = useReducer(authReducer, {}, () => {
        const localData = localStorage.getItem('userData')
        return localData ? JSON.parse(localData) : {}
    });

    useEffect(() => {
        localStorage.setItem('userData', JSON.stringify(user))
    }, [user])
    return (
        <Context.Provider
            value={{ user, dispatchUser }}
        >
            {children}
        </Context.Provider>
    )
}

export default ContextProvider;