import React, { useContext } from 'react';
import { Table, Switch } from 'antd';
import { useHistory } from 'react-router-dom';
import { IconButton, } from '@chakra-ui/core';
import { MdVisibility } from 'react-icons/md';
import { Context } from '../../../context/Context';

const { Column } = Table;

function SuppliersTable(props) {
    const history = useHistory()
    const { user } = useContext(Context)

    const roleString = user.role;
    return (
        <Table
            size="middle"
            onChange={props.onChange}
            pagination={props.pagination}
            rowKey={record => record.id}
            loading={props.loading}
            dataSource={props.dataSource}>
            <Column
                title='Contact'
                dataIndex='name'
                key='name'
                render={(_, record) => {
                    return (
                        <span>{record.firstName} {record.lastName}</span>
                    )

                }}

            />
            <Column
                title='Company'
                dataIndex='supplyInfo'
                key='supplyInfo'
                render={(text) => (
                    <span>{text.businessName}</span>
                )}
            />
            <Column
                title='Email'
                dataIndex='email'
                key='email'
            />
            <Column
                title='Phone'
                dataIndex='phone'
                key='phone'
            />
            <Column
                title='Category'
                dataIndex='categoryName'
                key='categoryName'
            />
            {
                roleString === 1 && <Column
                    title='Tenant Name'
                    dataIndex='tenantName'
                    key='tenantName'
                    render={(text, record) => {
                        return (
                            <span>{text ? text.tenantName : ''}</span>
                        )

                    }}
                />
            }
            <Column
                title='Status'
                dataIndex='is_active'
                key='is_active'
                render={(text, record) => {
                    return (
                        <Switch
                            onChange={(val) => props.checkValue(val, record)}
                            checkedChildren={"Active"}
                            unCheckedChildren={"Inactive"}
                            checked={text === 1}
                        />
                    )
                }}
            />
            <Column
                title='Created By'
                dataIndex='createdBy'
                key='createdBy'
                render={(text, record) => {
                    return (
                        <span>{text.name}</span>
                    )
                }}
            />

            <Column
                title='View'
                dataIndex='view'
                key='view'
                render={(_, record) => {
                    return (
                        <IconButton
                            onClick={() => history.push(`/supplier/${record.id}`)}
                            variant="outline"
                            isRound
                            aria-label="Send email"
                            icon={MdVisibility}
                        />
                    )
                }}
            />
            <Column
                title='Edit'
                dataIndex='edit'
                key='edit'
                render={(_, record) => {
                    return (
                        <IconButton
                            onClick={() => history.push(`/supplier/edit/${record.id}`)}
                            variant="outline"
                            isRound
                            aria-label="Send email"
                            icon="edit"
                        />
                    )
                }}
            />
        </Table>
    )
}

export default SuppliersTable;


