import React, { useState, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Box, Stack, Heading, Flex, useToast } from '@chakra-ui/core';
import { Space, Button } from 'antd';
import axios from 'axios';
import UploadPreviewData from './data/UploadPreviewData';
import globalStyles from '../../globalStyles';
import { Context } from '../context/Context';
import config from '../utils/data/config';


function UploadPreview() {
    const history = useHistory()
    const location = useLocation()
    const [loading, setLoading] = useState(false)
    const { user } = useContext(Context)
    const toast = useToast()
    const state = location.state

    const upload = async () => {
        const body = {
            data: JSON.stringify(state.preview)
        }
        setLoading(true)
        try {
            const res = await axios.post(`${config.baseUrl}/${state.uploadUrl}`, body, { headers: { "Authorization": `Bearer ${user.token}` } })
            if (res.data.error) {
                showToast("error", res.data.error)
                setLoading(false)
                console.log(body)
            }
            else {
                // mutate([`${config.baseUrl}/getallusers`, user.token])
                showToast("success", "Successfully uploaded data")
                setLoading(false)
                history.goBack()
            }
        }
        catch (e) {
            setLoading(false)
            showToast("error", e.message)
        }
    }

    const showToast = (status, message) => {
        toast({
            title: message.toUpperCase(),
            status: status,
            duration: 2000,
            isClosable: true,
        })
    }

    return (
        <Box>
            <Stack spacing={6}>
                <Flex justify="space-between">
                    <Heading as="h1" size="sm" color={globalStyles.green}>Preview {state.module}</Heading>
                    <Flex>
                        <Space size={24}>
                            <Button loading={loading} onClick={upload} type="primary">Upload Suppliers</Button>
                        </Space>
                    </Flex>
                </Flex>
                <Box shadow="lg" borderWidth="1px" p="5">
                    <UploadPreviewData dataSource={state.preview} />
                </Box>
            </Stack>
        </Box>

    )
}

export default UploadPreview;