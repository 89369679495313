import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button as AntButton } from 'antd';
import { Form, Formik, Field } from 'formik';
import { useToasts } from 'react-toast-notifications';
import {
    Box, Stack, Flex, Heading,
    Button, Checkbox, Select, FormControl, FormLabel, Skeleton, FormErrorMessage
} from '@chakra-ui/core';
import axios from 'axios';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { pickBy, identity } from 'lodash';
import * as dayjs from 'dayjs';
import globalStyles from '../../globalStyles'
import { newNotificationSchema } from './helpers/validationSchema';
import { Context } from '../context/Context'
import { useRoles } from '../roles/helpers/fetcher';
import config from '../utils/data/config'
import FormikControl from '../utils/form/FormControl';


function NewNotification() {
    const { user } = useContext(Context)
    const { addToast } = useToasts()
    const history = useHistory()
    const [scheduleDateVisible, setScheduleDateVisible] = useState(false)
    const [messageType, setMessageType] = useState("group")

    const today = dayjs().format('YYYY-MM-DD')

    const { data: roles, isLoading } = useRoles("getallroles", user.token)


    const requestOptions = {
        headers: { "Authorization": `Bearer ${user.token}` },
    }

    const initialValues = {
        message: '',
        recepients: "",
        scheduleDate: "",
        userGroup: ""
    }

    const handleSubmit = async (values, onSubmitProps) => {
        const body = { ...values }
        const data = pickBy(body, identity)

        const path = messageType === "group" ? "sendgroupmessages" : "sendcustommessages"

        if (messageType === "group" && !body.userGroup) {
            return showToast("error", "Please select a user group")
        }
        if (messageType === "custom" && !body.recepients) {
            return showToast("error", "Please add at least one message receipient")
        }

        try {
            const res = await axios.post(`${config.baseUrl}/${path}`, data, requestOptions)
            console.log(res)
            if (res.data.error) {
                showToast("error", res.data.error)
            }
            else {
                showToast("success", "Messages Sent")
            }
        }
        catch (e) {
            showToast("error", e.message)
        }
        onSubmitProps.setSubmitting(false)

    }

    const showToast = (status, message) => {
        addToast(message, { appearance: status })
    }

    return (
        <Box>
            <Stack spacing={6}>
                <Flex justify="space-between">
                    <Heading as="h1" size="sm" color={globalStyles.green}>New Notifications</Heading>
                    <Flex>
                        <AntButton onClick={history.goBack} type="link" icon={<ArrowLeftOutlined />}>Back</AntButton>
                    </Flex>
                </Flex>
                <Box shadow="lg" borderWidth="1px" p="5" w="50%">
                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={initialValues}
                        validationSchema={newNotificationSchema}
                    >
                        {
                            formik => (
                                <Form>
                                    <Stack spacing={8}>
                                        <FormControl
                                            isRequired
                                        >
                                            <FormLabel htmlFor="categoryId" mb={2}>
                                                <Heading as="a" fontSize="sm">Message Type</Heading>
                                            </FormLabel>
                                            <Box w="80%">
                                                <Select

                                                    onChange={(e) => {

                                                        setMessageType(e.target.value)
                                                    }}


                                                    bg={globalStyles.lightGrey}
                                                    border="0"
                                                    focusBorderColor={globalStyles.green}
                                                >
                                                    <option value={"group"}>Group Message</option>
                                                    <option value={"custom"}>Custom Message</option>
                                                </Select>
                                            </Box>

                                        </FormControl>
                                        {messageType === "group" &&
                                            <Box w="80%">
                                                <Field name="userGroup">
                                                    {(props) => {
                                                        const { field, form } = props
                                                        return (
                                                            <FormControl
                                                                isInvalid={form.errors.userGroup && form.touched.userGroup}
                                                                isRequired
                                                            >
                                                                <FormLabel htmlFor="userGroup" mb={2}>
                                                                    <Heading as="a" fontSize="sm">User Group</Heading>
                                                                </FormLabel>
                                                                <Skeleton isLoaded={!isLoading}>
                                                                    <Select
                                                                        {...field}
                                                                        placeholder="Select User Group"
                                                                        id="userGroup"
                                                                        bg={globalStyles.lightGrey}
                                                                        border="0"
                                                                        focusBorderColor={globalStyles.green}
                                                                    >
                                                                        {
                                                                            roles.map(role => (
                                                                                <option key={role.id} value={role.id}>{role.role}</option>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </Skeleton>
                                                                <FormErrorMessage>{form.errors.userGroup}</FormErrorMessage>
                                                            </FormControl>
                                                        )
                                                    }}
                                                </Field>
                                            </Box>

                                        }
                                        {messageType === "custom" &&
                                            <Box w="80%">
                                                <FormikControl
                                                    control="textarea"
                                                    type="text"
                                                    label="recepients (Phone numbers separated by comma)"
                                                    name="recepients"
                                                    placeholder={`080...\n080...`}
                                                />
                                            </Box>
                                        }
                                        <Box w="80%">
                                            <FormikControl
                                                control="textarea"
                                                type="text"
                                                label="Message"
                                                name="message"
                                                placeholder="Message"
                                            />
                                        </Box>
                                        <Flex w="80%" align="center">
                                            <Checkbox size="lg" onChange={(e) => setScheduleDateVisible(e.target.checked)} variantColor="teal" isChecked={scheduleDateVisible}>Schedule Message</Checkbox>
                                        </Flex>
                                        {scheduleDateVisible &&
                                            <Box w="80%">
                                                <FormikControl
                                                    control="input"
                                                    type="date"
                                                    label="Schedule Date"
                                                    name="scheduleDate"
                                                    min={today}
                                                    required={false}
                                                />
                                            </Box>
                                        }

                                    </Stack>
                                    <Box mt={8}>
                                        <Button
                                            disabled={formik.isSubmitting}
                                            isLoading={formik.isSubmitting}
                                            loadingText="Submitting"
                                            type="submit"
                                            bg={globalStyles.green}
                                            color="white"
                                            _hover={{ bg: globalStyles.green }}
                                        >
                                            Submit
                                        </Button>
                                    </Box>
                                </Form>
                            )
                        }
                    </Formik>
                </Box>
            </Stack>
        </Box >
    )
}

export default NewNotification;