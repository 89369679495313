import React, { useContext } from 'react';
import { Table } from 'antd';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { IconButton } from '@chakra-ui/core';
import { MdVisibility } from 'react-icons/md'
import { formatCurrency } from '../../../utils/formatCurrency';
import { Context } from '../../../context/Context';

const { Column } = Table;

function BidHistoryTable(props) {
    const history = useHistory()
    const { user } = useContext(Context)

    const roleString = user.role;

    return (
        <Table
            size="middle"
            rowKey={record => record.id}
            loading={props.loading}
            dataSource={props.dataSource}>
            <Column
                title="Tender Name"
                dataIndex="tenderName"
                key="tenderName"
            />
            <Column
                title="Unit"
                dataIndex="units"
                key="units"
            />
            <Column
                title="Requested Quantity"
                dataIndex="requestedQuantity"
                key="requestedQuantity"
            />
            <Column
                title="Bid Quantity"
                dataIndex="supplierQuantity"
                key="supplierQuantity"
            />
            <Column
                title="Unit Bid Price"
                dataIndex="unitprice"
                key="unitprice"
                render={(text) => <span>{formatCurrency(parseInt(text))}</span>}
            />
            <Column
                title="Total Bid Price"
                dataIndex="totalbidprice"
                key="totalbidprice"
                render={(text) => <span>{formatCurrency(parseInt(text))}</span>}
            />
            <Column
                title="Date Submitted"
                dataIndex="updated_at"
                key="updated_at"
                render={(text) => <span>{dayjs(text).format('DD/MM/YYYY')}</span>}
            />

            {
                roleString === 1 && <Column
                    title='Tenant Name'
                    dataIndex='tenantName'
                    key='tenantName'
                    render={(text, record) => {
                        return (
                            <span>{text ? text.tenantName : ''}</span>
                        )

                    }}
                />
            }

            <Column
                title="Status"
                dataIndex="adminaction"
                key='adminaction'
                render={(text) => {
                    return props.renderAdminAction(text)
                }}
            />

            <Column
                title="View"
                dataIndex="view"
                key="view"
                render={(text, record) => {
                    return (
                        <IconButton
                            onClick={() => history.push(`/bids/${record.id}`)}
                            variant="outline"
                            isRound
                            aria-label="Send email"
                            icon={MdVisibility}
                        />
                    )
                }}
            />
        </Table>
    )
}

export default BidHistoryTable

