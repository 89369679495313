import React, { useContext, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
    Box, Stack, Flex, Heading, FormControl, Input, FormErrorMessage,
    FormLabel,
    Grid,
    Button,
    Select,
    Skeleton,
    useToast
} from '@chakra-ui/core';
import { Formik, Form, Field } from 'formik';
import axios from 'axios'
import { Button as AntButton, Select as AntSelect, Tag } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import globalStyles from '../../globalStyles'
import { editUserValidationSchema } from './helpers/validationSchemas';
import { Context } from '../context/Context';
import { useUser } from '../users/helpers/fetcher';
import { useRoles } from '../roles/helpers/fetcher';
import { useCategories } from '../categories/helpers/fetcher';
import FormikControl from '../utils/form/FormControl';
import config from '../utils/data/config'
// import { LinkAddCategories } from '../utils/link';


const { Option } = AntSelect


const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    role: "",
    alias: "",
}

function EditUser() {
    const history = useHistory()

    const toast = useToast()
    const { user } = useContext(Context)


    const location = useLocation()
    const id = location.pathname.split("/")[2]

    const requestOptions = {
        headers: { "Authorization": `Bearer ${user.token}` },
    }
    const [selectedCats, setSelectedCats] = useState([])
    const { data, isLoading } = useUser(`getuserbyid/${id}`, user.token)
    const roles = useRoles("getallroles", user.token)
    const categories = useCategories("fetchallcategories", user.token)



    const savedValues = Object.entries(data).length > 0 ? {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        role: data.role,
        alias: data.aliass || ""
    } : initialValues

    useEffect(() => {
        if (Object.entries(data).length > 0) {
            setSelectedCats(data.adminandprocurementcats || [])
        }

    }, [data])


    const handleSubmit = async (values, onSubmitProps) => {
        const body = { ...values, id: data.id, adminandprocurementcats: selectedCats }
        if (!selectedCats.length) {
            return showToast("error", "Please select at least one category")
        }
        try {
            const res = await axios.post(`${config.baseUrl}/edituser`, body, requestOptions)
            if (res.data.error) {
                showToast("error", res.data.error)
            }
            else {
                showToast("success", "Successfully edited user")
                history.goBack()
            }
        }
        catch (e) {
            showToast("error", e.message)
        }
        onSubmitProps.setSubmitting(false)
    }

    const showToast = (status, message) => {
        toast({
            title: message.toUpperCase(),
            status: status,
            duration: 2000,
            isClosable: true,
        })
    }
    function tagRender(props) {
        const { label, closable, onClose } = props;

        return (
            <Tag color={globalStyles.green} closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
                {(label.length > 15) ? label.substr(0, 15 - 1) + '...' : label}
            </Tag>
        );
    }


    return (
        <Box>
            <Stack spacing={6}>
                <Flex justify="space-between">
                    <Heading as="h1" size="sm" color={globalStyles.green}>Edit User</Heading>
                    <Flex>
                        <AntButton onClick={history.goBack} type="link" icon={<ArrowLeftOutlined />}>Back</AntButton>
                    </Flex>
                </Flex>
                <Box shadow="lg" borderWidth="1px" p="5">
                    <Formik
                        enableReinitialize={true}
                        onSubmit={handleSubmit}
                        initialValues={savedValues}
                        validationSchema={editUserValidationSchema}
                    >
                        {
                            formik => (
                                <Form>
                                    <Stack spacing={8}>
                                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                            <Box w="80%">
                                                <Field name="firstName">
                                                    {(props) => {
                                                        const { field, form } = props
                                                        return (
                                                            <FormControl
                                                                isInvalid={form.errors.firstName && form.touched.firstName}
                                                                isRequired
                                                            >
                                                                <FormLabel htmlFor="firstName" mb={2}>
                                                                    <Heading as="a" fontSize="sm">First Name</Heading>
                                                                </FormLabel>
                                                                <Skeleton isLoaded={!isLoading}>
                                                                    <Input
                                                                        {...field}
                                                                        id="firstName"
                                                                        border="0"
                                                                        bg={globalStyles.lightGrey}
                                                                        focusBorderColor={globalStyles.green} />
                                                                </Skeleton>

                                                                <FormErrorMessage>{form.errors.firstName}</FormErrorMessage>
                                                            </FormControl>
                                                        )
                                                    }}
                                                </Field>
                                            </Box>
                                            <Box w="80%">
                                                <Field name="lastName">
                                                    {(props) => {
                                                        const { field, form } = props
                                                        return (
                                                            <FormControl
                                                                isInvalid={form.errors.lastName && form.touched.lastName}
                                                                isRequired
                                                            >
                                                                <FormLabel htmlFor="lastName" mb={2}>
                                                                    <Heading as="a" fontSize="sm">Last Name</Heading>
                                                                </FormLabel>
                                                                <Skeleton isLoaded={!isLoading}>
                                                                    <Input
                                                                        {...field} id="lastName"
                                                                        border="0"
                                                                        bg={globalStyles.lightGrey}
                                                                        focusBorderColor={globalStyles.green} />
                                                                </Skeleton>
                                                                <FormErrorMessage>{form.errors.lastName}</FormErrorMessage>
                                                            </FormControl>
                                                        )
                                                    }}
                                                </Field>
                                            </Box>
                                        </Grid>

                                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                            <Box w="80%">
                                                <Field name="email">
                                                    {(props) => {
                                                        const { field, form } = props
                                                        return (
                                                            <FormControl
                                                                isInvalid={form.errors.email && form.touched.email}
                                                                isRequired
                                                            >
                                                                <FormLabel htmlFor="email" mb={2}>
                                                                    <Heading as="a" fontSize="sm">Email</Heading>
                                                                </FormLabel>
                                                                <Skeleton isLoaded={!isLoading}>
                                                                    <Input
                                                                        {...field}
                                                                        id="email"
                                                                        disabled
                                                                        bg={globalStyles.lightGrey}
                                                                        border="0"
                                                                        focusBorderColor={globalStyles.green} />
                                                                </Skeleton>
                                                                <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                                                            </FormControl>
                                                        )
                                                    }}
                                                </Field>
                                            </Box>
                                            <Box w="80%">
                                                <Field name="phone">
                                                    {(props) => {
                                                        const { field, form } = props
                                                        return (
                                                            <FormControl

                                                                isRequired
                                                                isInvalid={form.errors.phone && form.touched.phone}
                                                            >
                                                                <FormLabel htmlFor="phone" mb={2}>
                                                                    <Heading as="a" fontSize="sm">Phone Number</Heading>
                                                                </FormLabel>
                                                                <Skeleton isLoaded={!isLoading}>
                                                                    <Input
                                                                        {...field}
                                                                        disabled
                                                                        id="phone"
                                                                        bg={globalStyles.lightGrey}
                                                                        border="0"
                                                                        focusBorderColor={globalStyles.green} />
                                                                </Skeleton>
                                                                <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                                                            </FormControl>
                                                        )
                                                    }}
                                                </Field>
                                            </Box>
                                        </Grid>
                                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                            <Box w="80%">
                                                <Field name="role">
                                                    {(props) => {
                                                        const { field, form } = props
                                                        return (
                                                            <FormControl
                                                                isInvalid={form.errors.role && form.touched.role}
                                                                isRequired
                                                            >
                                                                <FormLabel htmlFor="role" mb={2}>
                                                                    <Heading as="a" fontSize="sm">Role</Heading>
                                                                </FormLabel>
                                                                <Skeleton isLoaded={!roles.isLoading}>
                                                                    <Select
                                                                        {...field}
                                                                        placeholder="Select Role"
                                                                        id="role"
                                                                        bg={globalStyles.lightGrey}
                                                                        border="0"
                                                                        focusBorderColor={globalStyles.green}
                                                                    >
                                                                        {
                                                                            roles.data.filter(item => item.id === 3 || item.id === 2).map(role => (
                                                                                <option key={role.id} value={role.id}>{role.role}</option>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </Skeleton>
                                                                <FormErrorMessage>{form.errors.role}</FormErrorMessage>
                                                            </FormControl>
                                                        )
                                                    }}
                                                </Field>
                                            </Box>
                                            {/* {dataRoleString === 2 && */}
                                            <Box w="80%">
                                                <FormControl
                                                    isRequired
                                                >
                                                    <FormLabel htmlFor="role" mb={2}>
                                                        <Heading as="a" fontSize="sm">Categories</Heading>
                                                    </FormLabel>
                                                    <Skeleton isLoaded={!roles.isLoading}>
                                                        <AntSelect
                                                            mode="multiple"
                                                            allowClear
                                                            style={{ width: '100%', backgroundColor: globalStyles.lightGrey, minHeight: 40 }}
                                                            placeholder="Select Categories"
                                                            showArrow
                                                            tagRender={tagRender}
                                                            value={selectedCats}
                                                            onChange={(v) => {
                                                                setSelectedCats(v)
                                                            }}
                                                            bordered={false}
                                                        >
                                                            {
                                                                categories.data.map(item => (
                                                                    <Option key={item.id} value={item.id}>{item.category}</Option>
                                                                ))
                                                            }
                                                        </AntSelect>
                                                    </Skeleton>
                                                </FormControl>
                                                {/* <LinkAddCategories /> */}
                                            </Box>

                                            {/* } */}

                                        </Grid>
                                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                            <Box w="80%">
                                                <FormikControl
                                                    control="input"
                                                    type="text"
                                                    label="Alias"
                                                    name="alias"
                                                    placeholder="Alias"
                                                    required={false}
                                                />
                                            </Box>
                                        </Grid>
                                    </Stack>
                                    <Box mt={8}>
                                        <Button
                                            disabled={formik.isSubmitting}
                                            isLoading={formik.isSubmitting}
                                            loadingText="Submitting"
                                            type="submit"
                                            bg={globalStyles.green}
                                            color="white"
                                            _hover={{ bg: globalStyles.green }}
                                        >
                                            Submit
                                        </Button>
                                    </Box>
                                </Form>
                            )
                        }

                    </Formik>
                </Box>
            </Stack>
        </Box>
    )
}

export default EditUser;