import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button as AntButton } from 'antd';
import { Form, Formik, Field } from 'formik';
import {
    Box, Stack, Flex, Heading, FormControl, FormLabel, Input, FormErrorMessage,
    Button,
    useToast
} from '@chakra-ui/core';
import axios from 'axios';

import { ArrowLeftOutlined } from '@ant-design/icons';
import globalStyles from '../../globalStyles'
import { editRoleValidationShema } from './helpers/validationSchema';
import { Context } from '../context/Context'
import config from '../utils/data/config'


function EditRole() {
    const { user } = useContext(Context)
    const toast = useToast()
    const history = useHistory()
    const location = useLocation()

    const requestOptions = {
        headers: { "Authorization": `Bearer ${user.token}` },
    }

    console.log(location.state)

    const initialValues = {
        role: location.state.role
    }

    const handleSubmit = async (values, onSubmitProps) => {
        const body = { ...values, id: location.state.id }
        try {
            const res = await axios.post(`${config.baseUrl}/editrole`, body, requestOptions)
            if (res.data.error) {
                showToast("error", res.data.error)
            }
            else {
                showToast("success", "Successfully edited role")
                history.goBack()
            }
        }
        catch (e) {
            showToast("error", e.messager)
        }
        onSubmitProps.setSubmitting(false)
    }

    const showToast = (status, message) => {
        toast({
            title: message.toUpperCase(),
            status: status,
            duration: 2000,
            isClosable: true,
        })
    }

    return (
        <Box>
            <Stack spacing={6}>
                <Flex justify="space-between">
                    <Heading as="h1" size="sm" color={globalStyles.green}>Edit Role</Heading>
                    <Flex>
                        <AntButton onClick={history.goBack} type="link" icon={<ArrowLeftOutlined />}>Back</AntButton>
                    </Flex>
                </Flex>
                <Box shadow="lg" borderWidth="1px" p="5" w="50%">
                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={initialValues}
                        validationSchema={editRoleValidationShema}
                    >
                        {
                            formik => (
                                <Form>
                                    <Stack spacing={8}>
                                        <Box w="80%">

                                            <Field name="role">
                                                {(props) => {
                                                    const { field, form } = props
                                                    return (
                                                        <FormControl
                                                            isInvalid={form.errors.role && form.touched.role}
                                                            isRequired
                                                        >
                                                            <FormLabel htmlFor="role" mb={2}>
                                                                <Heading as="a" fontSize="sm">Role</Heading>
                                                            </FormLabel>
                                                            <Input
                                                                {...field}
                                                                id="role"
                                                                border="0"
                                                                bg={globalStyles.lightGrey}
                                                                focusBorderColor={globalStyles.green} />

                                                            <FormErrorMessage>{form.errors.role}</FormErrorMessage>
                                                        </FormControl>
                                                    )
                                                }}
                                            </Field>
                                        </Box>
                                    </Stack>
                                    <Box mt={8}>
                                        <Button
                                            disabled={formik.isSubmitting}
                                            isLoading={formik.isSubmitting}
                                            loadingText="Submitting"
                                            type="submit"
                                            bg={globalStyles.green}
                                            color="white"
                                            _hover={{ bg: globalStyles.green }}
                                        >
                                            Submit
                                        </Button>
                                    </Box>
                                </Form>
                            )
                        }
                    </Formik>
                </Box>
            </Stack>
        </Box >
    )
}

export default EditRole;