import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button as AntButton } from 'antd';
import { Form, Formik } from 'formik';
import {
    Box, Stack, Flex, Heading,
    Button,
} from '@chakra-ui/core';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';
import { ArrowLeftOutlined } from '@ant-design/icons';
import globalStyles from '../../globalStyles'
import { addUnitValidationSchema } from './helpers/validationSchema';
import { Context } from '../context/Context'
import config from '../utils/data/config'
import FormikControl from '../utils/form/FormControl';


const initialValues = {
    unit: "",
}

function AddUnit() {
    const { user } = useContext(Context)
    const { addToast } = useToasts()
    const history = useHistory()

    const requestOptions = {
        headers: { "Authorization": `Bearer ${user.token}` }
    }

    const handleSubmit = async (values, onSubmitProps) => {
        try {
            const res = await axios.post(`${config.baseUrl}/createunit`, values, requestOptions)
            if (res.data.error) {
                showToast("error", res.data.error)
            }
            else {
                showToast("success", "Successfully added unit")
                history.goBack()
            }
        }
        catch (e) {
            showToast("error", e.message)
        }
        onSubmitProps.setSubmitting(false)

    }

    const showToast = (status, message) => {
        addToast(message, { appearance: status })
    }

    return (
        <Box>
            <Stack spacing={6}>
                <Flex justify="space-between">
                    <Heading as="h1" size="sm" color={globalStyles.green}>Add New Unit</Heading>
                    <Flex>
                        <AntButton onClick={history.goBack} type="link" icon={<ArrowLeftOutlined />}>Back</AntButton>
                    </Flex>
                </Flex>
                <Box shadow="lg" borderWidth="1px" p="5" w="50%">
                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={initialValues}
                        validationSchema={addUnitValidationSchema}
                    >
                        {
                            formik => (
                                <Form>
                                    <Stack spacing={8}>
                                        <Box w="80%">
                                            <FormikControl
                                                control="input"
                                                type="text"
                                                label="Unit"
                                                name="unit"
                                                placeholder="Unit"
                                            />
                                        </Box>
                                    </Stack>
                                    <Box mt={8}>
                                        <Button
                                            disabled={formik.isSubmitting}
                                            isLoading={formik.isSubmitting}
                                            loadingText="Submitting"
                                            type="submit"
                                            bg={globalStyles.green}
                                            color="white"
                                            _hover={{ bg: globalStyles.green }}
                                        >
                                            Submit
                                        </Button>
                                    </Box>
                                </Form>
                            )
                        }
                    </Formik>
                </Box>
            </Stack>
        </Box>
    )
}

export default AddUnit;