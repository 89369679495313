import React, { useContext, useState } from 'react';
import { mutate } from 'swr';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Space } from 'antd';
import { Link } from 'react-router-dom';
import {
    Box, Stack, Flex, Heading, Text, Skeleton
} from '@chakra-ui/core';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';
import { ArrowLeftOutlined, DownloadOutlined } from '@ant-design/icons';
import globalStyles from '../../globalStyles'
import { Context } from '../context/Context';
import { useBid } from './helpers/fetcher';
import { renderAdminAction } from '../utils/renderRole';
import { downloadFile } from '../utils/download';
import config from '../utils/data/config';
import { formatNumber } from '../utils/formatCurrency';

function ViewBid() {
    const history = useHistory()
    const { addToast } = useToasts()
    const location = useLocation()
    const id = location.pathname.split("/")[2]


    const { user } = useContext(Context);
    const [isSetting, setIsSetting] = useState(false)

    const path = `getbidbyid/${id}`

    const { data, isLoading } = useBid(path, user.token)

    const setWinningBid = async () => {
        const body = { id, adminaction: 3 }
        setIsSetting(true)
        try {
            const res = await axios.post(`${config.baseUrl}/adminactiononbid`, body, { headers: { "Authorization": `Bearer ${user.token}` } })
            if (res.data.error) {
                showToast("error", res.data.error)
            }
            else {
                showToast("success", "Successfully set winning bid")
                mutate([`${config.baseUrl}/${path}`, user.token])
            }
            setIsSetting(false)
        }
        catch (e) {
            console.log(e.response)
            showToast("error", e.message)
            setIsSetting(false)
        }

    }

    const showToast = (status, message) => {
        addToast(message, { appearance: status })
    }


    return (
        <Box>
            <Stack spacing={6}>
                <Flex justify="space-between">
                    <Heading as="h1" size="sm" color={globalStyles.green}>View Bid</Heading>
                    <Flex>
                        <Button onClick={history.goBack} type="link" icon={<ArrowLeftOutlined />}>Back</Button>
                    </Flex>
                </Flex>
                <Box shadow="lg" borderWidth="1px" p="5">
                    <Flex>
                        <Box pt={5} pb={10} mr={20}>
                            <Space size={20} direction="vertical">
                                <Skeleton isLoaded={!isLoading}>
                                    <Space size={40}>
                                        <Box w="150px">
                                            <Heading as="h4" color={globalStyles.darkGrey} fontSize="md">Tender Name:</Heading>
                                        </Box>
                                        <Text color={globalStyles.darkGrey} fontSize="md">{data && data.tenderName}</Text>
                                    </Space>
                                </Skeleton>
                                <Skeleton isLoaded={!isLoading}>
                                    <Space size={40}>
                                        <Box w="150px">
                                            <Heading as="h4" color={globalStyles.darkGrey} fontSize="md">Tender ID:</Heading>
                                        </Box>
                                        <Text color={globalStyles.darkGrey} fontSize="md">{data && `TD-${data.tenderid}`}</Text>
                                    </Space>
                                </Skeleton>
                                <Skeleton isLoaded={!isLoading}>
                                    <Space size={40}>
                                        <Box w="150px">
                                            <Heading as="h4" color={globalStyles.darkGrey} fontSize="md">Requested Quantity:</Heading>
                                        </Box>
                                        <Text color={globalStyles.darkGrey} fontSize="sm">{data && data.requestedQuantity}</Text>
                                    </Space>
                                </Skeleton>
                                {!isLoading &&
                                    <Link to={`/tender/${data && data.tenderid}`}>
                                        <Button type="primary">View Tender</Button>
                                    </Link>
                                }

                            </Space>
                        </Box>
                        <Box pt={5} pb={10} ml={20}>
                            <Space size={20} direction="vertical" >
                                <Skeleton isLoaded={!isLoading}>
                                    <Space size={40}>
                                        <Box w="150px">
                                            <Heading as="h4" color={globalStyles.darkGrey} fontSize="md">Bid Description:</Heading>
                                        </Box>
                                        <Text color={globalStyles.darkGrey} fontSize="sm">{data && data.description}</Text>
                                    </Space>
                                </Skeleton>
                                <Skeleton isLoaded={!isLoading}>
                                    <Space size={40}>
                                        <Box w="150px">
                                            <Heading as="h4" color={globalStyles.darkGrey} fontSize="md">Bid Unit:</Heading>
                                        </Box>
                                        <Text color={globalStyles.darkGrey} fontSize="sm">{data && data.units}</Text>
                                    </Space>
                                </Skeleton>
                                <Skeleton isLoaded={!isLoading}>
                                    <Space size={40}>
                                        <Box w="150px">
                                            <Heading as="h4" color={globalStyles.darkGrey} fontSize="md">Bid Quantity</Heading>
                                        </Box>
                                        <Text color={globalStyles.darkGrey} fontSize="sm">{data && data.supplierQuantity}</Text>
                                    </Space>

                                </Skeleton>
                                <Skeleton isLoaded={!isLoading}>
                                    <Space size={40}>
                                        <Box w="150px">
                                            <Heading as="h4" color={globalStyles.darkGrey} fontSize="md">Bid Price:</Heading>
                                        </Box>
                                        <Text color={globalStyles.darkGrey} fontSize="sm">{data && formatNumber(data.unitprice)}</Text>
                                    </Space>
                                </Skeleton>
                                <Skeleton isLoaded={!isLoading}>
                                    <Space size={40}>
                                        <Box w="150px">
                                            <Heading as="h4" color={globalStyles.darkGrey} fontSize="md">Total Bid Price:</Heading>
                                        </Box>
                                        <Text color={globalStyles.darkGrey} fontSize="sm">{data && formatNumber(data.totalbidprice)}</Text>
                                    </Space>
                                </Skeleton>
                                <Skeleton isLoaded={!isLoading}>
                                    <Space size={40}>
                                        <Box w="150px">
                                            <Heading as="h4" color={globalStyles.darkGrey} fontSize="md">Status:</Heading>
                                        </Box>
                                        <Text color={globalStyles.darkGrey} fontSize="sm">{data && renderAdminAction(data.adminaction)}</Text>
                                    </Space>
                                </Skeleton>
                                <Skeleton isLoaded={!isLoading}>
                                    <Space size={40}>
                                        <Box w="150px">
                                            <Heading as="h4" color={globalStyles.darkGrey} fontSize="md">Additional Information:</Heading>
                                        </Box>
                                        {data.attachement ?
                                            <Button
                                                onClick={() => downloadFile(`${config.baseUrl}/admindownloadattachement/${data.attachement}`)}
                                                icon={<DownloadOutlined />}
                                                type="default"
                                                style={{ color: globalStyles.green }}>Download</Button>
                                            :
                                            <Text color={globalStyles.darkGrey} fontSize="sm">N/A</Text>}

                                    </Space>
                                </Skeleton>
                                <Skeleton isLoaded={!isLoading}>
                                    <Box>
                                        {(data.adminaction === '0' || data.tenderStatus === 0) &&
                                            <Button loading={isSetting} onClick={setWinningBid} type="primary">Set Winning Bid</Button>}
                                    </Box>
                                </Skeleton>
                            </Space>
                        </Box>

                    </Flex>
                </Box>
            </Stack>
        </Box >
    )
}

export default ViewBid;