import * as Yup from 'yup';

export const addCategoryValidationSchema = Yup.object({
    category: Yup.string().required("Category Name is required"),
    description: Yup.string().notRequired(),
})

export const addSubCategorySchema = Yup.object({
    subcategory: Yup.string().required("Sub Category Name is required"),
    description: Yup.string().notRequired(),
    categoryId: Yup.string().required("Category Name is required"),
})