import React, { useContext } from 'react';
import { MdVisibility } from 'react-icons/md';
import dayjs from 'dayjs';
import { IconButton } from '@chakra-ui/core'
import { Table } from 'antd';
import { renderRoleString } from '../utils/renderRole'
import { Context } from '../context/Context'
import { useHistory } from 'react-router-dom';


function Tenders({ data, isLoading }) {
    const history = useHistory()
    const { user } = useContext(Context);
    const roleString = renderRoleString(user.role)
    const columns = [
        {
            title: 'Tender Name',
            dataIndex: 'tenderName',
            key: 'tenderName',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Category',
            dataIndex: roleString === "supplier" ? 'categoryName' : 'category',
            key: 'category',
            render: (text) => {
                return (
                    <span>{roleString === "supplier" ? text : text.category}</span>
                )
            }
        },
        {
            title: 'Sub Category',
            dataIndex: roleString === 4 ? 'subcategoryName' : 'subcategory',
            key: 'subcategory',
            render: (text) => {
                if (roleString === 4) {
                    return <span>{text ? text : ""}</span>
                }
                return (
                    <span>{text ? text.subcategory : ""}</span>
                )
            }
        },
        {
            title: 'Date Created',
            dataIndex: 'created_at',
            key: 'createed_at',
            render: (text) => {
                return (
                    <span>{dayjs(text).format('DD/MM/YYYY')}</span>
                )
            }
        },
        {
            title: 'Date Due',
            dataIndex: 'dueDate',
            key: 'dueDate',
            render: (text) => {
                return (
                    <span>{dayjs(text).format('DD/MM/YYYY')}</span>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) => {
                return (
                    text === 0 ?
                        <span style={{ fontWeight: 'bold', color: 'green' }}>Open</span> :
                        <span style={{ fontWeight: 'bold', color: 'red' }}>Closed</span>
                )
            }
        },
        {
            title: 'View',
            dataIndex: 'address',
            key: 'address',
            render: (_, record) => {
                return (
                    <IconButton
                        onClick={() => history.push(`/tender/${record.id}`)}
                        variant="outline"
                        isRound
                        aria-label="Send email"
                        icon={MdVisibility}
                    />
                )
            }
        },
    ];

    return (
        <Table rowKey={record => record.id} loading={isLoading} columns={columns} dataSource={data.tenders || []} />
    )

}
export default Tenders