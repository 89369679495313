import React, { useContext } from 'react';
import { Box, Stack, Heading, Flex, IconButton } from '@chakra-ui/core'
import { useHistory, Link } from 'react-router-dom';
import { Table, Button } from 'antd';
import globalStyles from '../../globalStyles';
import { Context } from '../context/Context';
import { useCategories } from './helpers/fetcher';
import { MdVisibility } from 'react-icons/md';

function ManageCategories() {

    const history = useHistory()
    const columns = [

        {
            title: 'Category Name',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (text) => {
                return <span>{text ? text : 'N/A'}</span>
            }
        },
        {
            title: 'View Sub Categories',
            dataIndex: 'edit',
            key: 'edit',
            render: (_, record) => {
                return (
                    <IconButton
                        onClick={() => history.push(`/categories/sub/${record.id}`, record)}
                        variant="outline"
                        isRound
                        aria-label="Send email"
                        icon={MdVisibility}
                    />
                )
            }
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            render: (_, record) => {
                return (
                    <IconButton
                        onClick={() => history.push(`/category/${record.id}/edit`, record)}
                        variant="outline"
                        isRound
                        aria-label="Send email"
                        icon="edit"
                    />
                )
            }
        },
    ];
    const { user } = useContext(Context)

    const { data, isLoading } = useCategories("fetchcategories", user.token)
    return (
        <Box>
            <Stack spacing={6}>
                <Flex justify="space-between">
                    <Heading as="h1" size="sm" color={globalStyles.green}>Manage Categories</Heading>
                    <Link to="/categories/add">
                        <Button type="primary">
                            Add New Category
                    </Button>
                    </Link>
                </Flex>
                <Box shadow="lg" borderWidth="1px" p="5">
                    <Table rowKey={record => record.id} loading={isLoading} columns={columns} dataSource={data} pagination={null} />
                </Box>
            </Stack>
        </Box>

    )
}
export default ManageCategories;