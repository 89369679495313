import React, { useContext } from 'react';
import { Box, Stack, Heading, Flex, IconButton } from '@chakra-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { Table, Switch, Button } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import globalStyles from '../../globalStyles';
import { Context } from '../context/Context';
import { useTenants } from './helpers/fetcher';

function ManageTenants() {
    const history = useHistory()

    const columns = [
        {
            title: 'Name',
            dataIndex: 'tenantName',
            key: 'tenantName',
        },
        {
            title: 'Descrption',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Tenant ID',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Status',
            dataIndex: 'address',
            key: 'address',
            render: () => {
                return (
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        defaultChecked
                    />
                )
            }
        },
        {
            title: 'Edit',
            dataIndex: 'address',
            key: 'address',
            render: (_, record) => {
                return (
                    <IconButton
                        onClick={() => history.push(`/tenant/${record.id}`)}
                        variant="outline"
                        isRound
                        aria-label="Send email"
                        icon="edit"
                    />
                )
            }
        },
    ];

    const { user } = useContext(Context)

    const { data, isLoading } = useTenants("getalltenants", user.token)


    return (
        <Box>
            <Stack spacing={6}>
                <Flex justify="space-between">
                    <Heading as="h1" size="sm" color={globalStyles.green}>Manage Tenants</Heading>
                    <Link to="/tenants/add">
                        <Button type="primary">
                            Add New Tenant
                    </Button>
                    </Link>
                </Flex>

                <Box shadow="lg" borderWidth="1px" p="5">
                    <Table rowKey={record => record.id} loading={isLoading} columns={columns} dataSource={data} pagination={null} />
                </Box>
            </Stack>

        </Box>

    )
}
export default ManageTenants;