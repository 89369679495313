import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button as AntButton } from 'antd';
import { Form, Formik, Field } from 'formik';
import {
    Box, Stack, Flex, Heading, FormControl, FormLabel, Input, FormErrorMessage,
    Button,
    useToast
} from '@chakra-ui/core';
import axios from 'axios';

import { ArrowLeftOutlined } from '@ant-design/icons';
import globalStyles from '../../globalStyles'
import { addCategoryValidationSchema } from './helpers/validationSchema';
import { Context } from '../context/Context'
import config from '../utils/data/config'
import FormikControl from '../utils/form/FormControl';


function AddCategory() {
    const { user } = useContext(Context)
    const toast = useToast()
    const history = useHistory()


    const requestOptions = {
        headers: { "Authorization": `Bearer ${user.token}` },
    }

    const initialValues = {
        category: '',
        description: ''
    }

    const handleSubmit = async (values, onSubmitProps) => {
        const body = { ...values }
        try {
            const res = await axios.post(`${config.baseUrl}/createcategory`, body, requestOptions)
            if (res.data.error) {
                showToast("error", res.data.error)
            }
            else {
                showToast("success", "Successfully Added Category")
                history.goBack()
            }
        }
        catch (e) {
            showToast("error", e.message)
        }
        onSubmitProps.setSubmitting(false)
    }

    const showToast = (status, message) => {
        toast({
            title: message.toUpperCase(),
            status: status,
            duration: 2000,
            isClosable: true,
        })
    }

    return (
        <Box>
            <Stack spacing={6}>
                <Flex justify="space-between">
                    <Heading as="h1" size="sm" color={globalStyles.green}>Add Category</Heading>
                    <Flex>
                        <AntButton onClick={history.goBack} type="link" icon={<ArrowLeftOutlined />}>Back</AntButton>
                    </Flex>
                </Flex>
                <Box shadow="lg" borderWidth="1px" p="5" w="50%">
                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={initialValues}
                        validationSchema={addCategoryValidationSchema}
                    >
                        {
                            formik => (
                                <Form>
                                    <Stack spacing={8}>
                                        <Box w="80%">

                                            <Field name="category">
                                                {(props) => {
                                                    const { field, form } = props
                                                    return (
                                                        <FormControl
                                                            isInvalid={form.errors.category && form.touched.category}
                                                            isRequired
                                                        >
                                                            <FormLabel htmlFor="role" mb={2}>
                                                                <Heading as="a" fontSize="sm">Category</Heading>
                                                            </FormLabel>
                                                            <Input
                                                                {...field}
                                                                id="catrgory"
                                                                border="0"
                                                                bg={globalStyles.lightGrey}
                                                                focusBorderColor={globalStyles.green} />

                                                            <FormErrorMessage>{form.errors.category}</FormErrorMessage>
                                                        </FormControl>
                                                    )
                                                }}
                                            </Field>
                                        </Box>
                                        <Box w="80%">
                                            <FormikControl
                                                control="textarea"
                                                type="text"
                                                label="Description"
                                                name="description"
                                                placeholder="Description"
                                                required={false}
                                            />
                                        </Box>
                                    </Stack>
                                    <Box mt={8}>
                                        <Button
                                            disabled={formik.isSubmitting}
                                            isLoading={formik.isSubmitting}
                                            loadingText="Submitting"
                                            type="submit"
                                            bg={globalStyles.green}
                                            color="white"
                                            _hover={{ bg: globalStyles.green }}
                                        >
                                            Submit
                                        </Button>
                                    </Box>
                                </Form>
                            )
                        }
                    </Formik>
                </Box>
            </Stack>
        </Box >
    )
}

export default AddCategory;