import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Box, Stack, Flex, Heading, FormControl, FormErrorMessage,
    FormLabel,
    Grid,
    Button,
    Select,
    Skeleton,
    IconButton,
    Text,
    Radio,
    RadioGroup,
    Input
} from '@chakra-ui/core';
import { useToasts } from 'react-toast-notifications'
import { Formik, Form, Field, FieldArray } from 'formik';
import axios from 'axios';
import { Button as AntButton, Table, Switch, Space } from 'antd';
import { ArrowLeftOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { MdVisibility, MdFileUpload, MdAdd, MdRemove } from 'react-icons/md';
import * as dayjs from 'dayjs';
import globalStyles from '../../globalStyles'
import { addTenderValidationSchema } from './helpers/validationSchema';
import { Context } from '../context/Context';
import { useSuppliers } from '../suppliers/helpers/fetcher';
import { useCategories, useSubCategories } from '../categories/helpers/fetcher';
import config from '../utils/data/config'
import FormikControl from '../utils/form/FormControl';
import UploadButton from '../upload/UploadButton';
import { LinkAddCategories, LinkAddSubCategories } from '../utils/link';
import { useUnits } from '../units/helpers/fetcher';
import { useDepartments } from '../departments/helpers/fetcher';
import { useLocations } from '../locations/helpers/fetcher';


const subItemsObject = {
    materialDescription: "",
    unitId: "",
    locationId: "",
    quantity: "",
    deliveryTime: ""
}

const tenderItemsObject = {
    itemName: '',
    invoicePaymentTerms: '',
    deliveryTerm: '1',
    fixedPrice: '0',
    subItems: [subItemsObject]
}


const initialValues = {
    tenderName: "",
    description: "",
    categoryId: "",
    subcategoryId: "",
    dueDate: "",
    departmentId: '',
    tenderItems: [tenderItemsObject]

}

function AddTender() {
    const { addToast } = useToasts()
    const [file, setFile] = useState(null)
    const [page] = useState(1)
    const [selectedCategory, setSelectedCategory] = useState("")
    const [selectedSubCategory, setSelectedSubCategory] = useState("")

    const [selectedSuppliers, setSelectedSuppliers] = useState([])

    const history = useHistory()
    const { user } = useContext(Context)

    const filterPage = `?page=${page}`;
    const filterCategory = selectedCategory ? `&categoryid=${selectedCategory}` : ''
    const filterSubCategory = selectedSubCategory ? `&subcategoryid=${selectedSubCategory}` : ''
    const filterLimit = `&limit=1000`

    const filterPath = filterPage + filterCategory + filterSubCategory + filterLimit

    const categories = useCategories("fetchcategoriesadminandprocurementuserbelong", user.token)
    const { data: units, isLoading: loadingUnits } = useUnits("fetchunits", user.token)
    const { data: locations, isLoading: loadingLocations } = useLocations("getlocations", user.token)
    const { data: departments, isLoading: loadingDepartments } = useDepartments("fetchdepartments", user.token)
    const { subcategories, isLoading: subcategoryLoading, isWaiting } = useSubCategories("fetchsubcategorybycategoryid", user.token, selectedCategory)

    const { data, isLoading: suppliersLoading } = useSuppliers(`filtersuppliers${filterPath}`, user.token)
    const suppliers = data ? data.user : []
    const today = dayjs().format('YYYY-MM-DD')

    const handleSubmit = async (values, onSubmitProps) => {

        const suppliersIds = selectedSuppliers.map(supplier => supplier.id)
        // const tenderItemsIsValid = values.tenderItems.every(item => item.materialDescription && item.quantity && item.deliveryTime)

        const itemNameIsValid = values.tenderItems.every(item => item.itemName)

        if (!itemNameIsValid) {
            showToast("error", "Each Tender Item must have a name")
            return
        }

        if (suppliersIds.length < 1) {
            showToast("error", "Please select one or more suppliers")
            return
        }
        const body = { ...values, suppliersIds: JSON.stringify(suppliersIds), attachement: file, tenderItems: JSON.stringify(values.tenderItems) }
        try {
            const res = await axios.post(`${config.baseUrl}/createtender`, body, { headers: { "Authorization": `Bearer ${user.token}` } })
            if (res.data.error) {
                showToast("error", res.data.error)
            }
            else {
                showToast("success", "Successfully added tender")
                history.goBack()
            }
        }
        catch (e) {
            console.log(e)
            showToast("error", e.message)
        }
        onSubmitProps.setSubmitting(false)
    }

    const showToast = (status, message) => {
        addToast(message, { appearance: status })
    }

    const columns = [
        {
            title: 'Company',
            dataIndex: 'supplyInfo',
            key: 'supplyInfo',
            render: (text) => (
                <span>{text && text.businessName}</span>
            )
        },
        {
            title: 'Contact',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => (
                <span>{record.firstName} {record.lastName}</span>
            )
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Category',
            dataIndex: 'categoryName',
            key: 'categoryName',
        },
        {
            title: 'Status',
            dataIndex: 'address',
            key: 'address',
            render: () => {
                return (
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        defaultChecked
                    />
                )
            }
        },
        {
            title: 'View',
            dataIndex: 'view',
            key: 'view',
            render: (_, record) => {
                return (
                    <IconButton
                        onClick={() => history.push(`/supplier/${record.id}`)}
                        variant="outline"
                        isRound
                        aria-label="Send email"
                        icon={MdVisibility}
                    />
                )
            }
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedSuppliers(selectedRows)
        }
    }

    return (
        <Box>
            <Stack spacing={6}>
                <Flex justify="space-between">
                    <Heading as="h1" size="sm" color={globalStyles.green}>New Tender Information</Heading>
                    <Flex>
                        <AntButton onClick={history.goBack} type="link" icon={<ArrowLeftOutlined />}>Back</AntButton>
                    </Flex>
                </Flex>
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    validationSchema={addTenderValidationSchema}
                >
                    {
                        formik => (
                            <Form>
                                <Stack spacing={8}>
                                    <Box shadow="lg" borderWidth="1px" p="5">
                                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                            <Box w="80%">
                                                <FormikControl
                                                    control="input"
                                                    type="text"
                                                    label="Tender Name"
                                                    name="tenderName"
                                                    placeholder="Tender Name"
                                                />
                                            </Box>
                                            <Box w="80%">
                                                <Field name="categoryId">
                                                    {(props) => {
                                                        const { field, form } = props
                                                        return (
                                                            <FormControl
                                                                isInvalid={form.errors.categoryId && form.touched.categoryId}
                                                                isRequired
                                                            >
                                                                <FormLabel htmlFor="categoryId" mb={2}>
                                                                    <Heading as="a" fontSize="sm">Category</Heading>
                                                                </FormLabel>
                                                                <Skeleton isLoaded={!categories.isLoading}>
                                                                    <Select
                                                                        {...field}
                                                                        onChange={(e) => {
                                                                            form.handleChange(e)
                                                                            setSelectedCategory(e.target.value)
                                                                        }}
                                                                        placeholder="Select a Category"
                                                                        id="categoryId"
                                                                        bg={globalStyles.lightGrey}
                                                                        border="0"
                                                                        focusBorderColor={globalStyles.green}
                                                                    >
                                                                        {
                                                                            categories.data.map(item => (
                                                                                <option key={item.id} value={item.id}>{item.category}</option>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </Skeleton>
                                                                <FormErrorMessage>{form.errors.categoryId}</FormErrorMessage>
                                                            </FormControl>
                                                        )
                                                    }}
                                                </Field>
                                                <LinkAddCategories />
                                            </Box>
                                        </Grid>
                                        <br />
                                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                            <Box w="80%">
                                                <Field name="subcategoryId">
                                                    {(props) => {
                                                        const { field, form } = props
                                                        return (
                                                            <FormControl
                                                                isInvalid={form.errors.subcategoryId && form.touched.subcategoryId}
                                                            >
                                                                <FormLabel htmlFor="subcategoryId" mb={2}>
                                                                    <Heading as="a" fontSize="sm">Sub Category</Heading>
                                                                </FormLabel>
                                                                <Skeleton isLoaded={!subcategoryLoading || !isWaiting}>
                                                                    <Select
                                                                        {...field}
                                                                        placeholder="Select Sub Category"
                                                                        onChange={(e) => {
                                                                            form.handleChange(e)
                                                                            setSelectedSubCategory(e.target.value)
                                                                        }}
                                                                        id="subcategoryId"
                                                                        bg={globalStyles.lightGrey}
                                                                        border="0"
                                                                        focusBorderColor={globalStyles.green}
                                                                    >
                                                                        {
                                                                            subcategories.map(subcategory => (
                                                                                <option key={subcategory.id} value={subcategory.id}>{subcategory.subcategory}</option>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </Skeleton>
                                                                <FormErrorMessage>{form.errors.subcategoryId}</FormErrorMessage>
                                                            </FormControl>
                                                        )
                                                    }}
                                                </Field>
                                                <LinkAddSubCategories />
                                            </Box>
                                            <Box w="80%">
                                                <Field name="departmentId">
                                                    {(props) => {
                                                        const { field, form } = props
                                                        return (
                                                            <FormControl
                                                                isInvalid={form.errors.departmentId && form.touched.departmentId}
                                                            >
                                                                <FormLabel htmlFor="departmentId" mb={2}>
                                                                    <Heading as="a" fontSize="sm">Department</Heading>
                                                                </FormLabel>
                                                                <Skeleton isLoaded={!loadingDepartments}>
                                                                    <Select
                                                                        {...field}
                                                                        placeholder="Select Department"
                                                                        id="departmentId"
                                                                        bg={globalStyles.lightGrey}
                                                                        border="0"
                                                                        focusBorderColor={globalStyles.green}
                                                                    >
                                                                        {
                                                                            departments.map(department => (
                                                                                <option key={department.id} value={department.id}>{department.department}</option>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </Skeleton>
                                                                <FormErrorMessage>{form.errors.departmentId}</FormErrorMessage>
                                                            </FormControl>
                                                        )
                                                    }}
                                                </Field>
                                            </Box>
                                        </Grid>
                                        <br />
                                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                            <Box w="80%">
                                                <FormikControl
                                                    control="textarea"
                                                    type="text"
                                                    label="Description"
                                                    name="description"
                                                    placeholder="Description"
                                                />
                                            </Box>
                                            <Box w="80%">
                                                <FormikControl
                                                    control="input"
                                                    type="date"
                                                    label="Due Date"
                                                    name="dueDate"
                                                    min={today}
                                                />
                                            </Box>
                                        </Grid>
                                        <br />
                                        <Box>
                                            <Space size={10} direction="vertical">
                                                <Text color={globalStyles.darkGrey} fontWeight="bold" as="h4" fontSize="md">Upload Additional Information</Text>
                                                <UploadButton path="adminuploadattachment" setFile={setFile}>
                                                    <Button
                                                        leftIcon={MdFileUpload}
                                                        variantColor="teal"
                                                        variant="outline"
                                                        _hover={{ bg: globalStyles.green, color: 'white' }}
                                                    >
                                                        Upload
                                                            </Button>
                                                </UploadButton>
                                            </Space>
                                        </Box>
                                    </Box>
                                    <Heading as="h1" size="sm" color={globalStyles.green}>Specify Bid Terms</Heading>
                                    <Box shadow="lg" borderWidth="1px" p="5">
                                        <Box>
                                            <Box>
                                                <Box>
                                                    <FieldArray
                                                        name="tenderItems"
                                                        render={arrayHelpers => (
                                                            <div>
                                                                {formik.values.tenderItems && formik.values.tenderItems.length > 0 ? (
                                                                    formik.values.tenderItems.map((friend, index) => (
                                                                        <Box key={index} mb={4}>
                                                                            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                                                                <Box w="80%">
                                                                                    <Field name={`tenderItems.${index}.itemName`}>
                                                                                        {(props) => {
                                                                                            const { field } = props
                                                                                            return (
                                                                                                <FormControl
                                                                                                >
                                                                                                    <Stack spacing={4} isInline>
                                                                                                        <Flex rounded="50%" bg={globalStyles.green} w={6} h={6} justify="center" align="center">
                                                                                                            <Heading as="a" color="white" fontSize="sm">{index + 1}</Heading>
                                                                                                        </Flex>
                                                                                                        <FormLabel htmlFor={`tenderItems.${index}.itemName`} mb={2}>
                                                                                                            <Heading as="a" fontSize="sm">Item Name</Heading>
                                                                                                        </FormLabel>
                                                                                                        <Button variantColor="red" onClick={() => arrayHelpers.remove(index)} size="xs">
                                                                                                            Remove Item
                                                                                                        </Button>
                                                                                                    </Stack>
                                                                                                    <Input
                                                                                                        {...field}
                                                                                                        required
                                                                                                        border="0"
                                                                                                        placeholder="Item Name"
                                                                                                        autocomplete="off"
                                                                                                        bg={globalStyles.lightGrey}
                                                                                                        focusBorderColor={globalStyles.green} />
                                                                                                </FormControl>
                                                                                            )
                                                                                        }}
                                                                                    </Field>
                                                                                </Box>
                                                                            </Grid>
                                                                            <br />
                                                                            <Heading as="h5" fontSize="sm">Item Specifications</Heading>
                                                                            <br />
                                                                            <FieldArray
                                                                                name={`tenderItems.${index}.subItems`}
                                                                                render={subItemArrayHelpers => (
                                                                                    <div>
                                                                                        {formik.values.tenderItems[index].subItems.map((item, subIndex) => (
                                                                                            <Flex key={subIndex} justify="space-between" align="center" mb={2}>
                                                                                                <Field name={`tenderItems.${index}.subItems.${subIndex}.materialDescription`}>
                                                                                                    {(props) => {
                                                                                                        const { field } = props
                                                                                                        return (
                                                                                                            <FormControl
                                                                                                            >
                                                                                                                <Input
                                                                                                                    {...field}
                                                                                                                    border="0"
                                                                                                                    required
                                                                                                                    placeholder="Material Description"
                                                                                                                    autocomplete="off"
                                                                                                                    bg={globalStyles.lightGrey}
                                                                                                                    focusBorderColor={globalStyles.green} />
                                                                                                            </FormControl>
                                                                                                        )
                                                                                                    }}
                                                                                                </Field>
                                                                                                <Field name={`tenderItems.${index}.subItems.${subIndex}.unitId`}>
                                                                                                    {(props) => {
                                                                                                        const { field } = props
                                                                                                        return (
                                                                                                            <Skeleton isLoaded={!loadingUnits}>
                                                                                                                <Select
                                                                                                                    {...field}
                                                                                                                    placeholder="Unit"
                                                                                                                    id="unitId"
                                                                                                                    bg={globalStyles.lightGrey}
                                                                                                                    border="0"
                                                                                                                    focusBorderColor={globalStyles.green}
                                                                                                                >
                                                                                                                    {
                                                                                                                        units.map(item => (
                                                                                                                            <option key={item.id} value={item.id}>{item.unit}</option>
                                                                                                                        ))
                                                                                                                    }
                                                                                                                </Select>
                                                                                                            </Skeleton>
                                                                                                        )
                                                                                                    }}
                                                                                                </Field>
                                                                                                <Field name={`tenderItems.${index}.subItems.${subIndex}.locationId`}>
                                                                                                    {(props) => {
                                                                                                        const { field } = props
                                                                                                        return (
                                                                                                            <Skeleton isLoaded={!loadingLocations}>
                                                                                                                <Select
                                                                                                                    {...field}
                                                                                                                    placeholder="Location"
                                                                                                                    id="locationId"
                                                                                                                    bg={globalStyles.lightGrey}
                                                                                                                    border="0"
                                                                                                                    focusBorderColor={globalStyles.green}
                                                                                                                >
                                                                                                                    {
                                                                                                                        locations.map(item => (
                                                                                                                            <option key={item.id} value={item.id}>{item.location}</option>
                                                                                                                        ))
                                                                                                                    }
                                                                                                                </Select>
                                                                                                            </Skeleton>
                                                                                                        )
                                                                                                    }}
                                                                                                </Field>
                                                                                                <Field name={`tenderItems.${index}.subItems.${subIndex}.quantity`}>
                                                                                                    {(props) => {
                                                                                                        const { field } = props
                                                                                                        return (
                                                                                                            <FormControl
                                                                                                            >
                                                                                                                <Input
                                                                                                                    {...field}
                                                                                                                    border="0"
                                                                                                                    type="number"
                                                                                                                    w={40}
                                                                                                                    required
                                                                                                                    placeholder="Quantity"
                                                                                                                    autocomplete="off"
                                                                                                                    bg={globalStyles.lightGrey}
                                                                                                                    focusBorderColor={globalStyles.green} />
                                                                                                            </FormControl>
                                                                                                        )
                                                                                                    }}
                                                                                                </Field>
                                                                                                <Field name={`tenderItems.${index}.subItems.${subIndex}.deliveryTime`}>
                                                                                                    {(props) => {
                                                                                                        const { field } = props
                                                                                                        return (
                                                                                                            <FormControl
                                                                                                            >
                                                                                                                <Input
                                                                                                                    {...field}
                                                                                                                    border="0"
                                                                                                                    type="date"
                                                                                                                    w={40}
                                                                                                                    required
                                                                                                                    placeholder="Days"
                                                                                                                    autocomplete="off"
                                                                                                                    bg={globalStyles.lightGrey}
                                                                                                                    focusBorderColor={globalStyles.green} />
                                                                                                            </FormControl>
                                                                                                        )
                                                                                                    }}
                                                                                                </Field>
                                                                                                <IconButton
                                                                                                    onClick={() => subItemArrayHelpers.remove(index)}
                                                                                                    aria-label="Send email"
                                                                                                    icon={MdRemove}
                                                                                                    ml={2}
                                                                                                />
                                                                                                <IconButton
                                                                                                    onClick={() => subItemArrayHelpers.push(subItemsObject)}
                                                                                                    aria-label="Send email"
                                                                                                    icon={MdAdd}
                                                                                                    ml={2}
                                                                                                />
                                                                                            </Flex>

                                                                                        ))}
                                                                                    </div>
                                                                                )}
                                                                            />
                                                                            <br />
                                                                            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                                                                <Box w="80%">
                                                                                    <Field name={`tenderItems.${index}.invoicePaymentTerms`}>
                                                                                        {(props) => {
                                                                                            const { field } = props
                                                                                            return (
                                                                                                <FormControl
                                                                                                    isRequired
                                                                                                >
                                                                                                    <FormLabel htmlFor="invoicePaymentTerms" mb={2}>
                                                                                                        <Heading as="a" fontSize="sm">Invoice Payment Terms</Heading>
                                                                                                    </FormLabel>
                                                                                                    <Select
                                                                                                        {...field}
                                                                                                        placeholder="Select Invoice Terms"
                                                                                                        id="invoicePaymentTerms"
                                                                                                        bg={globalStyles.lightGrey}
                                                                                                        border="0"
                                                                                                        focusBorderColor={globalStyles.green}
                                                                                                    >
                                                                                                        <option value="7">7</option>
                                                                                                        <option value="14">14</option>
                                                                                                        <option value="30">30</option>
                                                                                                        <option value="90">90</option>
                                                                                                    </Select>
                                                                                                </FormControl>
                                                                                            )
                                                                                        }}
                                                                                    </Field>
                                                                                </Box>
                                                                            </Grid>
                                                                            <br />
                                                                            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                                                                <Box w="80%">
                                                                                    <Heading as="h5" fontSize="sm">Delivery Term</Heading>
                                                                                    <br />
                                                                                    <Field name={`tenderItems.${index}.deliveryTerm`}>
                                                                                        {({ field }) => {
                                                                                            return (
                                                                                                <RadioGroup {...field} spacing={5} isInline>
                                                                                                    <Radio variantColor="teal" value="1">
                                                                                                        Staggered
                                                                                                    </Radio>
                                                                                                    <Radio variantColor="teal" value="2">
                                                                                                        Bulk
                                                                                                    </Radio>
                                                                                                </RadioGroup>
                                                                                            )

                                                                                        }}
                                                                                    </Field>
                                                                                </Box>
                                                                                <Box w="80%">
                                                                                    <Heading as="h5" fontSize="sm">Fixed Price</Heading>
                                                                                    <br />
                                                                                    <Field name={`tenderItems.${index}.fixedPrice`}>
                                                                                        {({ field }) => {
                                                                                            return (
                                                                                                <RadioGroup {...field} spacing={5} isInline>
                                                                                                    <Radio variantColor="teal" value="1">
                                                                                                        Yes
                                                                                                    </Radio>
                                                                                                    <Radio variantColor="teal" value="0">
                                                                                                        No
                                                                                                    </Radio>
                                                                                                </RadioGroup>
                                                                                            )
                                                                                        }}
                                                                                    </Field>
                                                                                </Box>
                                                                            </Grid>
                                                                            <br />
                                                                            <br />
                                                                        </Box>
                                                                    ))
                                                                ) : (
                                                                        <Button onClick={() => arrayHelpers.push(tenderItemsObject)} variantColor="teal">Add Item</Button>
                                                                    )}
                                                                <Box mt={4}>
                                                                    <Button onClick={() => arrayHelpers.push(tenderItemsObject)} variantColor="teal">Add Item</Button>
                                                                </Box>
                                                            </div>
                                                        )}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Box>
                                        <Heading as="h1" size="sm" color={globalStyles.green}>Select Suppliers</Heading>
                                    </Box>

                                    <Box shadow="lg" borderWidth="1px" p="5">
                                        <Table rowSelection={rowSelection} rowKey={record => record.id} loading={suppliersLoading} columns={columns} dataSource={suppliers ? suppliers.filter(item => item.role === 4) : []} />
                                    </Box>
                                </Stack>
                                <Box mt={8}>
                                    <Button
                                        disabled={formik.isSubmitting}
                                        isLoading={formik.isSubmitting}
                                        loadingText="Submitting"
                                        type="submit"
                                        bg={globalStyles.green}
                                        color="white"
                                        _hover={{ bg: globalStyles.green }}
                                    >
                                        Submit
                                        </Button>
                                </Box>
                            </Form>
                        )
                    }
                </Formik>
            </Stack>
        </Box>
    )
}

export default AddTender;