import useSWR from 'swr';
import config from '../../utils/data/config';
import { fetcher } from '../../utils/data/fetcher';


export const useTenants = (path, token) => {
    const { data, error } = useSWR([`${config.baseUrl}/${path}`, token], fetcher)
    //show error here
    return {
        data: data ? data.tenant : [],
        isLoading: !error && !data,
        isError: error
    }
}

export const useSuperAdminTenants = (path, token, role) => {
    const { data, error } = useSWR(role === 1 ? [`${config.baseUrl}/${path}`, token] : null, fetcher)
    //show error here
    return {
        data: data ? data.tenants : [],
        isLoading: !error && !data,
        isError: error
    }
}

export const useTenant = (path, token) => {
    const { data, error } = useSWR([`${config.baseUrl}/${path}`, token], fetcher)
    //show error here
    return {
        data: data ? data.tenant : {},
        isLoading: !error && !data,
        isError: error
    }
}

