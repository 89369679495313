import React from 'react';
import { Line } from 'react-chartjs-2';

function AmountLineChart(props) {

    const { chartData } = props
    const labels = chartData.map(item => Object.keys(item)[0])
    const amount = chartData.map(item => Object.values(item)[0].amountOfBidsWon)

    const data = {
        labels,
        datasets: [
            {
                label: "Total Amount of Bids Won",
                data: amount,
                borderColor: ['#009688'],
                backgroundColor: ['rgba(0,150,136,0.2)']
            },
        ]

    }
    return (
        <Line
            data={data}
            width={100}
            height={400}
            options={{
                maintainAspectRatio: false,
                scales: { xAxes: [{ gridLines: { display: false } }] }

            }}
        />
    )
}

export default AmountLineChart