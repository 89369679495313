import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button as AntButton } from 'antd';
import { Form, Formik, Field } from 'formik';
import {
    Box, Stack, Flex, Heading, FormControl, FormLabel, Input, FormErrorMessage,
    Button,
    Textarea,
    Grid,
    Skeleton, useToast
} from '@chakra-ui/core';
import axios from 'axios';

import { ArrowLeftOutlined } from '@ant-design/icons';
import globalStyles from '../../globalStyles'
import { editTenantValidationSchema } from './helpers/validationSchemas';
import { Context } from '../context/Context'
import config from '../utils/data/config'
import { useTenant } from './helpers/fetcher';

const initialValues = {
    firstName: "",
    lastName: "",
    tenantName: "",
    description: "",
}

function EditTenant() {
    const { user } = useContext(Context)
    const toast = useToast()
    const history = useHistory()

    const location = useLocation()
    const id = location.pathname.split("/")[2]

    const requestOptions = {
        headers: { "Authorization": `Bearer ${user.token}` },
    }

    const { data, isLoading } = useTenant(`gettenantbyid/${id}`, user.token)


    const savedValues = Object.entries(data).length > 0 ? {
        firstName: data.firstName,
        lastName: data.lastName,
        tenantName: data.tenantName,
        description: data.description
    } : initialValues


    const handleSubmit = async (values, onSubmitProps) => {
        const body = { ...values, id: data.id }
        try {
            const res = await axios.post(`${config.baseUrl}/edittenant`, body, requestOptions)
            if (res.data.error) {
                showToast("error", res.data.error)
            }
            else {
                showToast("success", "Successfully added tenant")
                history.goBack()
            }
        }
        catch (e) {
            showToast("error", e.message)
        }
        onSubmitProps.setSubmitting(false)
    }

    const showToast = (status, message) => {
        toast({
            title: message.toUpperCase(),
            status: status,
            duration: 2000,
            isClosable: true,
        })
    }

    return (
        <Box>
            <Stack spacing={6}>
                <Flex justify="space-between">
                    <Heading as="h1" size="sm" color={globalStyles.green}>Edit Tenant</Heading>
                    <Flex>
                        <AntButton onClick={history.goBack} type="link" icon={<ArrowLeftOutlined />}>Back</AntButton>
                    </Flex>
                </Flex>
                <Box shadow="lg" borderWidth="1px" p="5">
                    <Formik
                        enableReinitialize={true}
                        onSubmit={handleSubmit}
                        initialValues={savedValues}
                        validationSchema={editTenantValidationSchema}
                    >
                        {
                            formik => (
                                <Form>
                                    <Stack spacing={8}>
                                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                            <Box w="80%">

                                                <Field name="firstName">
                                                    {(props) => {
                                                        const { field, form } = props
                                                        return (
                                                            <FormControl
                                                                isInvalid={form.errors.firstName && form.touched.firstName}
                                                                isRequired
                                                            >
                                                                <FormLabel htmlFor="firstName" mb={2}>
                                                                    <Heading as="a" fontSize="sm">First Name</Heading>
                                                                </FormLabel>
                                                                <Skeleton isLoaded={!isLoading}>
                                                                    <Input
                                                                        {...field}
                                                                        id="firstName"
                                                                        border="0"
                                                                        bg={globalStyles.lightGrey}
                                                                        focusBorderColor={globalStyles.green} />
                                                                </Skeleton>

                                                                <FormErrorMessage>{form.errors.firstName}</FormErrorMessage>
                                                            </FormControl>
                                                        )
                                                    }}
                                                </Field>
                                            </Box>
                                            <Box w="80%">

                                                <Field name="lastName">
                                                    {(props) => {
                                                        const { field, form } = props
                                                        return (
                                                            <FormControl
                                                                isInvalid={form.errors.lastName && form.touched.lastName}
                                                                isRequired
                                                            >
                                                                <FormLabel htmlFor="lastName" mb={2}>
                                                                    <Heading as="a" fontSize="sm">Last Name</Heading>
                                                                </FormLabel>
                                                                <Skeleton isLoaded={!isLoading}>
                                                                    <Input
                                                                        {...field}
                                                                        id="lastName"
                                                                        border="0"
                                                                        bg={globalStyles.lightGrey}
                                                                        focusBorderColor={globalStyles.green} />
                                                                </Skeleton>
                                                                <FormErrorMessage>{form.errors.lastName}</FormErrorMessage>
                                                            </FormControl>
                                                        )
                                                    }}
                                                </Field>
                                            </Box>
                                        </Grid>
                                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                            <Box w="80%">
                                                <Field name="tenantName">
                                                    {(props) => {
                                                        const { field, form } = props
                                                        return (
                                                            <FormControl
                                                                isInvalid={form.errors.tenantName && form.touched.tenantName}
                                                                isRequired
                                                            >
                                                                <FormLabel htmlFor="tenantName" mb={2}>
                                                                    <Heading as="a" fontSize="sm">Tenant Name</Heading>
                                                                </FormLabel>
                                                                <Skeleton isLoaded={!isLoading}>
                                                                    <Input
                                                                        {...field}
                                                                        id="tenantName"
                                                                        border="0"
                                                                        bg={globalStyles.lightGrey}
                                                                        focusBorderColor={globalStyles.green} />
                                                                </Skeleton>

                                                                <FormErrorMessage>{form.errors.tenantName}</FormErrorMessage>
                                                            </FormControl>
                                                        )
                                                    }}
                                                </Field>
                                            </Box>
                                        </Grid>

                                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>

                                            <Box w="80%">

                                                <Field name="description">
                                                    {(props) => {
                                                        const { field, form } = props
                                                        return (
                                                            <FormControl
                                                                isInvalid={form.errors.description && form.touched.description}
                                                                isRequired
                                                            >
                                                                <FormLabel htmlFor="description" mb={2}>
                                                                    <Heading as="a" fontSize="sm">Description</Heading>
                                                                </FormLabel>
                                                                <Skeleton isLoaded={!isLoading}>
                                                                    <Textarea
                                                                        {...field}
                                                                        id="description"
                                                                        border="0"
                                                                        bg={globalStyles.lightGrey}
                                                                        focusBorderColor={globalStyles.green} />
                                                                </Skeleton>
                                                                <FormErrorMessage>{form.errors.description}</FormErrorMessage>
                                                            </FormControl>
                                                        )
                                                    }}
                                                </Field>
                                            </Box>
                                        </Grid>

                                    </Stack>
                                    <Box mt={8}>
                                        <Button
                                            disabled={formik.isSubmitting}
                                            isLoading={formik.isSubmitting}
                                            loadingText="Submitting"
                                            type="submit"
                                            bg={globalStyles.green}
                                            color="white"
                                            _hover={{ bg: globalStyles.green }}
                                        >
                                            Submit
                                        </Button>
                                    </Box>
                                </Form>
                            )
                        }
                    </Formik>
                </Box>
            </Stack>
        </Box >
    )
}

export default EditTenant;