import useSWR from 'swr';
import config from '../../utils/data/config';
import { fetcher } from '../../utils/data/fetcher';


export const useSuppliers = (path, token) => {
    const { data, error } = useSWR([`${config.baseUrl}/${path}`, token], fetcher)
    //show error here
    return {
        data: data ? data : {},
        isLoading: !error && !data,
        isError: error
    }
}


export const useAllSuppliers = (path, token) => {
    const { data, error } = useSWR([`${config.baseUrl}/${path}`, token], fetcher)
    //show error here
    return {
        data: data ? data.suppliers : [],
        isLoading: !error && !data,
        isError: error
    }
}

export const useSupplier = (path, token) => {
    const { data, error } = useSWR([`${config.baseUrl}/${path}`, token], fetcher)
    //show error here
    return {
        data: data ? data.user : {},
        isLoading: !error && !data,
        isError: error
    }
}
