import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button as AntButton } from 'antd';
import { Form, Formik, Field } from 'formik';
import {
    Box, Stack, Flex, Heading,
    Button,
    useToast, FormControl, FormLabel, Skeleton, Select, FormErrorMessage
} from '@chakra-ui/core';
import axios from 'axios';

import { ArrowLeftOutlined } from '@ant-design/icons';
import globalStyles from '../../globalStyles'
import { addLocationValidationSchema } from './helpers/validationSchema';
import { Context } from '../context/Context'
import config from '../utils/data/config'
import FormikControl from '../utils/form/FormControl';
import { useStates } from './helpers/fetcher';


const initialValues = {
    location: "",
    state: "",
}

function AddLocation() {
    const { user } = useContext(Context)
    const toast = useToast()
    const history = useHistory()

    const { data, isLoading } = useStates("fetchstates", user.token)

    const requestOptions = {
        headers: { "Authorization": `Bearer ${user.token}` }
    }

    const handleSubmit = async (values, onSubmitProps) => {
        const { location, state: stateid } = values

        const body = { location, stateid }
        try {
            const res = await axios.post(`${config.baseUrl}/createlocation`, body, requestOptions)
            if (res.data.error) {
                showToast("error", res.data.error)
            }
            else {
                showToast("success", "Successfully added tenant")
                history.goBack()
            }
        }
        catch (e) {
            showToast("error", e.message)
        }
        onSubmitProps.setSubmitting(false)

    }

    const showToast = (status, message) => {
        toast({
            title: message.toUpperCase(),
            status: status,
            duration: 2000,
            isClosable: true,
        })
    }

    return (
        <Box>
            <Stack spacing={6}>
                <Flex justify="space-between">
                    <Heading as="h1" size="sm" color={globalStyles.green}>Add New Location</Heading>
                    <Flex>
                        <AntButton onClick={history.goBack} type="link" icon={<ArrowLeftOutlined />}>Back</AntButton>
                    </Flex>
                </Flex>
                <Box shadow="lg" borderWidth="1px" p="5" w="50%">
                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={initialValues}
                        validationSchema={addLocationValidationSchema}
                    >
                        {
                            formik => (
                                <Form>
                                    <Stack spacing={8}>
                                        <Box w="80%">
                                            <FormikControl
                                                control="input"
                                                type="text"
                                                label="Location"
                                                name="location"
                                                placeholder="Location"
                                            />
                                        </Box>

                                        <Box w="80%">
                                            <Field name="state">
                                                {(props) => {
                                                    const { field, form } = props
                                                    return (
                                                        <FormControl
                                                            isInvalid={form.errors.state && form.touched.state}
                                                            isRequired
                                                        >
                                                            <FormLabel htmlFor="category" mb={2}>
                                                                <Heading as="a" fontSize="sm">State</Heading>
                                                            </FormLabel>
                                                            <Skeleton isLoaded={!isLoading}>
                                                                <Select
                                                                    {...field}
                                                                    placeholder="Select a State"
                                                                    id="state"
                                                                    bg={globalStyles.lightGrey}
                                                                    border="0"
                                                                    focusBorderColor={globalStyles.green}
                                                                >
                                                                    {
                                                                        data.map(item => (
                                                                            <option key={item.id} value={item.id}>{item.state}</option>
                                                                        ))
                                                                    }
                                                                </Select>
                                                            </Skeleton>
                                                            <FormErrorMessage>{form.errors.state}</FormErrorMessage>
                                                        </FormControl>
                                                    )
                                                }}
                                            </Field>
                                        </Box>
                                    </Stack>
                                    <Box mt={8}>
                                        <Button
                                            disabled={formik.isSubmitting}
                                            isLoading={formik.isSubmitting}
                                            loadingText="Submitting"
                                            type="submit"
                                            bg={globalStyles.green}
                                            color="white"
                                            _hover={{ bg: globalStyles.green }}
                                        >
                                            Submit
                                        </Button>
                                    </Box>
                                </Form>
                            )
                        }
                    </Formik>
                </Box>
            </Stack>
        </Box>
    )
}

export default AddLocation;