import React from 'react';
import { Link as ReachLink } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { useToasts } from 'react-toast-notifications'
import {
    FormControl,
    FormErrorMessage,
    Input,
    Box,
    Heading,
    Text,
    Button,
    Stack,
    Link,
} from "@chakra-ui/core";
import axios from 'axios';
import { forgotPasswordValidationSchema } from './helpers/validationSchemas';
import globalStyles from '../../globalStyles';
import config from '../utils/data/config';


const initialValues = {
    email: "",
}


function ForgotPassword() {

    const { addToast } = useToasts()
    const handleSubmit = async (values, onSubmitProps) => {
        try {
            const res = await axios.post(`${config.baseUrl}/forgotpassword`, values)
            if (res.data.error) {
                showToast("error", res.data.message)
            }
            else {
                showToast("success", res.data.message.toUpperCase())
            }
        }
        catch (e) {
            showToast("error", e.message)
        }
        onSubmitProps.setSubmitting(false)
    }

    const showToast = (status, message) => {
        addToast(message, { appearance: status })
    }

    return (
        <Box>
            <Stack spacing={4} mt="4%">
                <Heading textAlign="center" color={globalStyles.green} mb={4} fontSize="3xl">Honeywell eProcurement</Heading>
                <Text as="i" textAlign="center" color={globalStyles.darkGrey} fontSize="xl">...Digitizing Supply Chains</Text>
            </Stack>
            <Box
                mx="auto"
                mt="5%"
                width={[
                    "90%", // base
                    "50%", // 480px upwards
                    "50%", // 768px upwards
                    "35%", // 992px upwards
                ]}
                p={10}
                shadow="lg" borderWidth="1px">
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    validationSchema={forgotPasswordValidationSchema}
                >
                    {(formik) => (
                        <Form>
                            <Stack spacing={8}>
                                <Box>
                                    <Heading textAlign="center" color={globalStyles.green} mb={4} fontSize="xl">Forgot Password</Heading>
                                </Box>
                                <Box>
                                    {/* <FormikControl
                                        control="input"
                                        type="email"
                                        label="Email"
                                        name="email"
                                        placeholder="Email"
                                    /> */}
                                    <Field name="email">
                                        {(props) => {
                                            const { field, form } = props
                                            return (
                                                <FormControl
                                                    isInvalid={form.errors.email && form.touched.email}
                                                    isRequired
                                                >
                                                    <Input
                                                        focusBorderColor={globalStyles.green}
                                                        {...field} id="email"
                                                        placeholder="Email"
                                                        bg={globalStyles.lightGrey} />
                                                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                                                </FormControl>
                                            )
                                        }}
                                    </Field>
                                </Box>
                                <Button
                                    disabled={formik.isSubmitting}
                                    isLoading={formik.isSubmitting}
                                    loadingText="Submitting"
                                    width="100%"
                                    type="submit"
                                    bg={globalStyles.green}
                                    color="white"
                                    _hover={{ bg: globalStyles.green }}
                                >
                                    SUBMIT
                                </Button>
                            </Stack>
                        </Form>
                    )}

                </Formik>
                <Box textAlign="center" mt={4}>
                    <Link as={ReachLink} to="/login" color={globalStyles.darkGrey} textAlign="center">
                        Remembered your password? Click to Log In
                    </Link>
                </Box>
            </Box>

        </Box >
    )
}
export default ForgotPassword;