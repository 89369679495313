import React, { useContext, useState } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import {
    Box, Stack, Flex, Heading,
    Grid,
    Button,
    Skeleton,
    ButtonGroup,
    Text
} from '@chakra-ui/core';
import { Formik, Form } from 'formik';
import { useToasts } from 'react-toast-notifications';
import axios from 'axios'
import { Button as AntButton, Space } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import globalStyles from '../../globalStyles'
import { createBidValidationSchema } from './helpers/validationSchema';
import { Context } from '../context/Context';
import { useTender } from '../tenders/helpers/fetcher';
import { useBid } from './helpers/fetcher';
import config from '../utils/data/config'
import { formatCurrency } from '../utils/formatCurrency'
import FormikControl from '../utils/form/FormControl';
import { MdFileUpload } from 'react-icons/md'
import UploadButton from '../upload/UploadButton';


const initialValues = {
    units: "",
    description: "",
    unitprice: "",
    supplierQuantity: "",
}

function EditBid() {
    const [file, setFile] = useState(null)
    const [status, setStatus] = useState(null)
    const history = useHistory()
    const location = useLocation()
    const { user } = useContext(Context)
    const { addToast } = useToasts()

    const id = location.pathname.split("/")[3]

    const { data: tenderData, isLoading: isLoadingTender } = useTender(`gettenderbyid/${id}`, user.token)
    const { data: bidData } = useBid(`getbidbyid/${id}`, user.token)


    const savedValues = Object.entries(bidData).length > 0 ? {
        units: bidData.units || "",
        description: bidData.description || "",
        unitprice: bidData.unitprice || "",
        supplierQuantity: bidData.supplierQuantity || "",
    } : initialValues


    const handleSubmit = async (values, onSubmitProps) => {
        const body = { ...values, id: bidData.id, status, attachment: file ? file : undefined, tenderid: bidData.tenderid, requestedQuantity: bidData.requestedQuantity, dueDate: bidData.dueDate, }

        try {
            const res = await axios.post(`${config.baseUrl}/editbid`, body, { headers: { "Authorization": `Bearer ${user.token}` } })
            if (res.data.error) {
                showToast("error", res.data.error)
            }
            else {
                showToast("success", "Successfully edited bid")
                history.push('/supplier-bids')
            }
        }
        catch (e) {
            showToast("error", e.message)
        }
        onSubmitProps.setSubmitting(false)
    }

    const showToast = (status, message) => {
        addToast(message, { appearance: status })
    }
    return (
        <Box>
            <Stack spacing={6}>
                <Flex justify="space-between">
                    <Heading as="h1" size="sm" color={globalStyles.green}>Edit Bid</Heading>
                    <Flex>
                        <AntButton onClick={history.goBack} type="link" icon={<ArrowLeftOutlined />}>Back</AntButton>
                    </Flex>
                </Flex>
                <Box shadow="lg" borderWidth="1px" p="5">
                    <Flex>
                        <Box flex="1">
                            <Space size={20} direction="vertical">
                                <Skeleton isLoaded={!isLoadingTender}>
                                    <Space size={40}>
                                        <Box w="120px">
                                            <Heading as="h5" color={globalStyles.darkGrey} fontSize="md">Tender Name:</Heading>
                                        </Box>
                                        <Text color={globalStyles.darkGrey} fontSize="md">{tenderData && tenderData.tenderName}</Text>
                                    </Space>
                                </Skeleton>
                                <Skeleton isLoaded={!isLoadingTender}>
                                    <Space size={40}>
                                        <Box w="120px">
                                            <Heading as="h5" color={globalStyles.darkGrey} fontSize="md">Tender ID:</Heading>
                                        </Box>
                                        <Text color={globalStyles.darkGrey} fontSize="md">{tenderData && tenderData.id}</Text>
                                    </Space>
                                </Skeleton>
                                <Skeleton isLoaded={!isLoadingTender}>
                                    <Space size={40}>
                                        <Box w="120px">
                                            <Heading as="h5" color={globalStyles.darkGrey} fontSize="md">Requested Qty:</Heading>
                                        </Box>
                                        <Text color={globalStyles.darkGrey} fontSize="sm">{tenderData && tenderData.quantity}</Text>
                                    </Space>
                                </Skeleton>
                                <Skeleton isLoaded={!isLoadingTender}>
                                    <Space size={40}>
                                        <Box w="120px">
                                            <Heading as="h5" color={globalStyles.darkGrey} fontSize="md">Currency:</Heading>
                                        </Box>
                                        <Text color={globalStyles.darkGrey} fontSize="sm">NGN</Text>
                                    </Space>
                                </Skeleton>
                                <Skeleton isLoaded={!isLoadingTender}>
                                    <Link to={`/tender/${id}`}>
                                        <Button
                                            bg={globalStyles.green}
                                            color="white"
                                            _hover={{ bg: globalStyles.green }}>
                                            View Tender
                                        </Button>
                                    </Link>
                                </Skeleton>
                            </Space>
                        </Box>
                        <Box flex="0.2"></Box>
                        <Box flex="2">
                            <Box mb="10">
                                <Heading as="h4" color={globalStyles.darkGrey} fontSize="md">Enter Bid Details</Heading>
                            </Box>

                            <Formik
                                onSubmit={handleSubmit}
                                enableReinitialize
                                initialValues={savedValues}
                                validationSchema={createBidValidationSchema}
                            >
                                {
                                    formik => (
                                        <Form>
                                            <Stack spacing={8}>
                                                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                                    <Box w="100%">
                                                        <FormikControl
                                                            control="input"
                                                            label="Unit"
                                                            name="units"
                                                            placeholder="Unit"
                                                        />
                                                    </Box>
                                                    <Box w="100%">
                                                        <FormikControl
                                                            control="number"
                                                            label="Your Bid Quantity"
                                                            name="supplierQuantity"
                                                            placeholder="Quantity"
                                                        />
                                                    </Box>
                                                </Grid>

                                                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                                    <Box w="100%">
                                                        <FormikControl
                                                            control="number"
                                                            label="Your Bid Price"
                                                            name="unitprice"
                                                            placeholder="Price"
                                                        />
                                                    </Box>
                                                    <Box w="100%">
                                                        <FormikControl
                                                            control="textarea"
                                                            type="text"
                                                            label="Description"
                                                            name="description"
                                                            placeholder="Description"
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                                    <Box w="100%">
                                                        <Heading as="h5" color={globalStyles.darkGrey} fontSize="md">{formatCurrency(parseInt(formik.values.supplierQuantity || 0) * parseInt(formik.values.unitprice || 0))}</Heading>

                                                        <Text color={globalStyles.darkGrey} fontSize="sm">Total Bid Price</Text>
                                                    </Box>
                                                    <Space size={10} direction="vertical">
                                                        <Text color={globalStyles.darkGrey} fontSize="sm">Upload Additional Information</Text>
                                                        <UploadButton
                                                            path="supplieruploadattachment" setFile={setFile}
                                                        >
                                                            <Button
                                                                leftIcon={MdFileUpload}
                                                                variantColor="teal"
                                                                variant="outline"
                                                                _hover={{ bg: globalStyles.green, color: 'white' }}
                                                            >
                                                                Upload
                                                        </Button>
                                                        </UploadButton>
                                                    </Space>
                                                </Grid>
                                            </Stack>
                                            <Box my={8}>
                                                <ButtonGroup spacing={4}>
                                                    {bidData.status === 1 ? <Button
                                                        onClick={() => {
                                                            setStatus(1)
                                                            formik.submitForm()
                                                        }}
                                                        disabled={formik.isSubmitting && status === 1}
                                                        isLoading={formik.isSubmitting && status === 1}
                                                        loadingText="Submitting"
                                                        type="submit"
                                                        variantColor="teal"
                                                        variant="outline"
                                                        _hover={{ bg: globalStyles.green, color: 'white' }}
                                                    >
                                                        Save For Later
                                                    </Button> : null}

                                                    <Button
                                                        onClick={() => {
                                                            setStatus(2)
                                                            formik.submitForm()
                                                        }}
                                                        disabled={formik.isSubmitting && status === 2}
                                                        isLoading={formik.isSubmitting && status === 2}
                                                        loadingText="Submitting"
                                                        type="submit"

                                                        bg={globalStyles.green}
                                                        color="white"
                                                        _hover={{ bg: globalStyles.green }}
                                                    >
                                                        Submit
                                                    </Button>
                                                </ButtonGroup>
                                            </Box>
                                        </Form>
                                    )
                                }
                            </Formik>
                        </Box>
                    </Flex>
                </Box>
            </Stack>
        </Box >
    )
}

export default EditBid;