import * as Yup from 'yup';

export const addSupplierSchema = Yup.object({
    email: Yup.string().email("The email address you entered is invalid").required("Email is required"),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    category: Yup.string().required("Category is required"),
    subcategory: Yup.string().notRequired(""),
    businessName: Yup.string().required("Company Name is required"),
    rcnumber: Yup.string().required("RC Number is required"),
    phone: Yup.string().test('len', 'Must be exactly 11 digits', val => val.length === 11).notRequired(),
    website: Yup.string().notRequired(),
    address: Yup.string().required("Address is required")
})