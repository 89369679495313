import React from 'react';
import { MdVisibility } from 'react-icons/md';
import dayjs from 'dayjs';
import { IconButton } from '@chakra-ui/core';
import { formatCurrency } from '../../utils/formatCurrency';

export const onGoingColumns = [
    {
        title: 'Tender Name',
        dataIndex: 'tenderName',
        key: 'tenderName',
    },

    {
        title: 'Unit',
        dataIndex: 'units',
        key: 'units',
    },
    {
        title: 'Requested',
        dataIndex: 'requestedQuantity',
        key: 'requestedQuantity',
    },
    {
        title: 'Bid',
        dataIndex: 'supplierQuantity',
        key: 'supplierQuantity',
    },
    {
        title: 'Unit Bid Price',
        dataIndex: 'unitprice',
        key: 'unitprice',
        render: (text) => {
            return <span>{formatCurrency(parseInt(text))}</span>
        }
    },
    {
        title: 'Total Bid Price',
        dataIndex: 'totalbidprice',
        key: 'totalbidprice',
        render: (text) => {
            return <span>{formatCurrency(parseInt(text))}</span>
        }
    },
    {
        title: 'Date Due',
        dataIndex: 'dueDate',
        key: 'dueDate',
        render: (text) => {
            return <span>{dayjs(text).format('DD/MM/YYYY')}</span>
        }
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text) => {
            return (
                text === 1 ?
                    <span style={{ color: 'orange', fontWeight: 'bold' }}>Pending</span> :
                    <span style={{ color: 'green', fontWeight: 'bold' }}>Submitted</span>
            )
        }
    },
    {
        title: 'Edit',
        dataIndex: 'status',
        key: 'edit',
        render: (_, record) =>
            <IconButton
                // onClick={() => history.push(`/user/${record.id}`)}
                variant="outline"
                isRound
                aria-label="Send email"
                icon="edit"
            />

    },


];


export const closedColumns = [
    {
        title: 'Tender Name',
        dataIndex: 'tenderName',
        key: 'tenderName',
    },

    {
        title: 'Supplier',
        dataIndex: 'supplier',
        key: 'supplier',
    },

    {
        title: 'Unit',
        dataIndex: 'units',
        key: 'units',
    },
    {
        title: 'Requested',
        dataIndex: 'requestedQuantity',
        key: 'requestedQuantity',
    },
    {
        title: 'Bid',
        dataIndex: 'supplierQuantity',
        key: 'supplierQuantity',
    },
    {
        title: 'Unit Bid Price',
        dataIndex: 'unitprice',
        key: 'unitprice',
        render: (text) => {
            return <span>{formatCurrency(parseInt(text))}</span>
        }
    },
    {
        title: 'Total Bid Price',
        dataIndex: 'totalbidprice',
        key: 'totalbidprice',
        render: (text) => {
            return <span>{formatCurrency(parseInt(text))}</span>
        }
    },
    {
        title: 'Date Submitted',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (text) => {
            return <span>{dayjs(text).format('DD/MM/YYYY')}</span>
        }
    },

    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text) => {
            return (
                text === 1 ?
                    <span style={{ color: 'orange', fontWeight: 'bold' }}>Pending</span> :
                    <span style={{ color: 'green', fontWeight: 'bold' }}>Submitted</span>
            )
        }
    },
    {
        title: 'View',
        dataIndex: 'address',
        key: 'address',
        render: (_, record) => {
            return (
                <IconButton
                    // onClick={() => history.push(`/user/${record.id}`)}
                    variant="outline"
                    isRound
                    aria-label="Send email"
                    icon={MdVisibility}
                />
            )
        }
    },

];