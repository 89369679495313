import * as Yup from 'yup';

export const addUserValidationSchema = Yup.object({
    email: Yup.string().email("The email address you entered is invalid").required("Email is required"),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    role: Yup.string().required("Role is required"),
    phone: Yup.string().required("Phone number is required"),
    alias: Yup.string().notRequired()
})


export const editUserValidationSchema = Yup.object({
    email: Yup.string().email("The email address you entered is invalid").required("Email is required"),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    role: Yup.string().required("Role is required"),
    phone: Yup.string().required("Phone number is required"),
    alias: Yup.string().notRequired(),
})


export const createAdminValidationSchema = Yup.object({
    email: Yup.string().email("The email address you entered is invalid").required("Email is required"),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    phone: Yup.string().required("Phone number is required"),
})


export const changeAuthSchema = Yup.object({
    email: Yup.string().email("The email address you entered is invalid").required("Email is required"),
    password: Yup.string().required("Password is required"),

})
