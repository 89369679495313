import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, ModalBody, ModalOverlay, ModalContent, ModalCloseButton, ModalFooter, ModalHeader, Button, Select, Grid } from '@chakra-ui/core'
import { Upload, message, Typography } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import globalStyles from '../../globalStyles';
import { Context } from '../context/Context';
import config from '../utils/data/config';
import { downloadFile } from '../utils/download';
import { useCategories, useSubCategories } from '../categories/helpers/fetcher';


const { Dragger } = Upload;
const { Title } = Typography;

function UploadFile({ isOpen, onClose, module, previewUrl, downloadUrl, uploadUrl }) {
    //categories, subcategories
    const history = useHistory()
    const { user } = useContext(Context)
    const [category, setCategory] = useState(null)
    const [subcategory, setSubCategory] = useState(null)
    const [preview, setPreview] = useState([])
    const [uploading, setUploading] = useState(false)

    const categories = useCategories("fetchcategories", user.token)
    const { subcategories } = useSubCategories("fetchsubcategorybycategoryid", user.token, category)

    const uploadProps = {
        name: 'file',
        multiple: false,
        disabled: !category || !subcategory,
        action: `${config.baseUrl}/${previewUrl}`,
        data: {
            categoryid: category,
            subcategoryid: subcategory
        },
        headers: { "Authorization": `Bearer ${user.token}` },
        onChange(info) {
            const { status } = info.file;
            if (status === 'uploading') {
                setUploading(true)
            }
            else if (status === 'done') {
                setUploading(false)
                setPreview(info.file.response.data)
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                setUploading(false)
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    }
    return (
        <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader><Title level={4}>Upload {module}</Title></ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Dragger {...uploadProps}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p >Click or drag file to this area to upload</p>
                    </Dragger>
                    <br />
                    <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                        <Select
                            placeholder="Select a Category"
                            onChange={(e) => {
                                setCategory(e.target.value)
                            }}
                            bg={globalStyles.lightGrey}
                            border="0"
                            focusBorderColor={globalStyles.green}
                        >
                            {
                                categories.data.map(item => (
                                    <option key={item.id} value={item.id}>{item.category}</option>
                                ))
                            }
                        </Select>
                        <Select
                            placeholder="Select a Sub Category"
                            onChange={(e) => {
                                setSubCategory(e.target.value)
                            }}
                            bg={globalStyles.lightGrey}
                            border="0"
                            focusBorderColor={globalStyles.green}
                        >
                            {
                                subcategories.map(item => (
                                    <option key={item.id} value={item.id}>{item.subcategory}</option>
                                ))
                            }
                        </Select>
                    </Grid>
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={() => history.push('/upload-preview', {
                            preview,
                            uploadUrl,
                            module
                        })}
                        disabled={preview.length === 0}
                        isLoading={uploading}
                    >
                        Preview
                    </Button>
                    <Button
                        onClick={() => downloadFile(`${config.baseUrl}/${downloadUrl}`)}
                        mr={3}
                        disabled={false}
                        isLoading={false}
                        loadingText="Downloading"
                        bg={globalStyles.green}
                        color="white"
                        _hover={{ bg: globalStyles.green }}
                    >
                        Download Template (.xslx)
                    </Button>
                    <Button onClick={onClose} variant="ghost">Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal >
    )
}
export default UploadFile;