import React, { useContext } from 'react';
import { Box, Stack, Heading, Flex } from '@chakra-ui/core'
import { Link } from 'react-router-dom';
import { Table, Button } from 'antd';
import globalStyles from '../../globalStyles';
import { Context } from '../context/Context';
import { useDepartments } from './helpers/fetcher';
import Can from '../utils/rbac/Can';

function ManageDepartments() {

    const columns = [

        {
            title: 'Department',
            dataIndex: 'department',
            key: 'department',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
    ];
    const { user } = useContext(Context)

    const roleString = user.role;

    const { data, isLoading } = useDepartments("fetchdepartments", user.token)
    return (
        <Box>
            <Stack spacing={6}>
                <Flex justify="space-between">
                    <Heading as="h1" size="sm" color={globalStyles.green}>Manage Departments</Heading>

                    <Can
                        role={roleString}
                        perform="departments:add"
                        yes={() => (
                            <Link to="/departments/add">
                                <Button type="primary">
                                    Add New Department
                                </Button>
                            </Link>
                        )}
                        no={() => null}
                    />
                </Flex>
                <Box shadow="lg" borderWidth="1px" p="5">
                    <Table rowKey={record => record.id} loading={isLoading} columns={columns} dataSource={data} pagination={null} />
                </Box>
            </Stack>
        </Box>

    )
}
export default ManageDepartments;