import useSWR from 'swr';
import config from '../../utils/data/config';
import { fetcher } from '../../utils/data/fetcher';


export const useLocations = (path, token) => {
    const { data, error } = useSWR([`${config.baseUrl}/${path}`, token], fetcher)
    //show error here
    return {
        data: data ? data.locations : [],
        isLoading: !error && !data,
        isError: error
    }
}


export const useStates = (path, token) => {
    const { data, error } = useSWR([`${config.baseUrl}/${path}`, token], fetcher)
    //show error here
    return {
        data: data ? data.states : [],
        isLoading: !error && !data,
        isError: error
    }
}


// export const useSubCategories = (path, token, selectedCategory, categoryId) => {
//     // const { data: projects } = useSWR(() => '/api/projects?uid=' + user.id)
//     const { data, error } = useSWR(selectedCategory ? [`${config.baseUrl}/${path}/${selectedCategory}`, token] : null, fetcher)
//     return {
//         subcategories: data ? data.subcategory : [],
//         isLoading: !error && !data,
//         isError: error,
//         isWaiting: selectedCategory
//     }
// }
