import React from 'react';
import { Box } from '@chakra-ui/core';

function AddNewRole() {
    return (
        <Box>

        </Box>
    )
}

export default AddNewRole;