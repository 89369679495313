import React, { useContext, useState } from "react";
import {
    Box,
    Flex,
    Stack,
    Image,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody, PopoverArrow, PopoverCloseButton,
    Menu, MenuItem, MenuList, MenuButton, Button,
    Badge,
} from "@chakra-ui/core";
import { List, Skeleton } from 'antd';
import { useHistory } from 'react-router-dom';
import { IoMdNotificationsOutline, IoMdContact } from "react-icons/io"
import globalStyles from "../../globalStyles";
import logo from '../../assets/honeywell.png';
import { Context } from "../context/Context";
import { useNotifications } from "./helpers/fetcher";
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

const Header = props => {
    const { user, dispatchUser } = useContext(Context)
    const history = useHistory();

    const [isOpen, setIsOpen] = useState(false);


    const { data, loading } = useNotifications("fetchnotifications", user.token)

    const logout = () => {
        dispatchUser({
            type: 'LOG_OUT', user: {}
        });
    }
    return (
        <Box borderBottomWidth="1px">
            <Flex
                as="nav"
                align="center"
                justify="space-between"
                wrap="wrap"
                paddingX="1rem"
                color={globalStyles.darkGrey}
                {...props}
            >
                <Image size="70px" src={logo} alt="logo" />
                <Flex align="center" mr={5}>
                    <Stack isInline spacing={4} align="center">
                        {/* <InputGroup>
                            <InputLeftElement
                                children={<Box as={IoIosSearch} size="20px" color={globalStyles.darkGrey} />} />
                            <Input
                                focusBorderColor={globalStyles.green}
                                rounded="25px"
                                border="0"
                                bg={globalStyles.lightGrey}
                                type="text"
                            />
                        </InputGroup> */}
                        <Box>
                            <Popover
                                isOpen={isOpen}
                                returnFocusOnClose={false}
                            >
                                <PopoverTrigger>
                                    <Button variant="ghost" border="0" onClick={() => setIsOpen(!isOpen)}>
                                        <Box as={IoMdNotificationsOutline} size="24px" color={globalStyles.darkGrey} />
                                        {!loading && data.length > 0 &&
                                            <Badge
                                                position="absolute"
                                                bottom="6"
                                                left="8"
                                                rounded="lg" bg="red.500" color="white">
                                                {data.length}
                                            </Badge>
                                        }
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent
                                    shadow="lg"
                                    _focus={{ border: "0" }}
                                    width="600px"
                                    mt={4}
                                    overflow="scroll"
                                    height="400px"
                                    placement="left"
                                    border="0"
                                    zIndex={4}>
                                    <PopoverArrow />
                                    <PopoverCloseButton onClick={() => setIsOpen(false)} />
                                    <PopoverHeader fontWeight="bold">Notifications</PopoverHeader>
                                    <PopoverBody>
                                        <List
                                            loading={loading}
                                            itemLayout="horizontal"
                                            dataSource={data}
                                            renderItem={item => (
                                                <List.Item
                                                    onClick={() => {
                                                        setIsOpen(false)
                                                        if (item.table === "tender") {
                                                            history.push(`/supplier-bids/add/${item.tableId}`)
                                                        }
                                                        else {
                                                            history.push(`/${item.table}/${item.tableId}`)
                                                        }
                                                    }}
                                                    actions={[<span>{dayjs(item.created_at).from(dayjs())}</span>]}
                                                >
                                                    <Skeleton loading={loading} active>
                                                        <List.Item.Meta
                                                            title={<p>{item.message}</p>}
                                                        />
                                                    </Skeleton>
                                                </List.Item>
                                            )}
                                        />
                                    </PopoverBody>
                                </PopoverContent>
                            </Popover>
                        </Box>

                        {user?.firstName &&
                            <Menu>
                                <MenuButton as={Button}>
                                    <IoMdContact size={24} />
                                </MenuButton>
                                <MenuList>
                                    <MenuItem onClick={logout}>Log Out</MenuItem>
                                </MenuList>
                            </Menu>
                        }
                    </Stack>
                </Flex>
            </Flex>
        </Box>

    );
};

export default Header;