import * as Yup from 'yup';

export const addTenantValidationSchema = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    tenantName: Yup.string().required("Tenant Name is required"),
    email: Yup.string().email().required("Email is required"),
    phone: Yup.string().required("Phone Number is required"),
    description: Yup.string().required("Description is required"),
})


export const editTenantValidationSchema = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    tenantName: Yup.string().required("Tenant Name is required"),
    description: Yup.string().required("Description is required"),
})