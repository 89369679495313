import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Box, Stack, Flex, Heading, FormControl, FormErrorMessage,
    FormLabel,
    Grid,
    Button,
    Select,
    Skeleton,
} from '@chakra-ui/core';
import { Formik, Form, Field } from 'formik';
import axios from 'axios'
import { Button as AntButton, Select as AntSelect, Tag } from 'antd';
import { useToasts } from 'react-toast-notifications'
import { ArrowLeftOutlined } from '@ant-design/icons';
import globalStyles from '../../globalStyles'
import { addUserValidationSchema } from './helpers/validationSchemas';
import { Context } from '../context/Context';
import { useRoles } from '../roles/helpers/fetcher';
import { useCategories } from '../categories/helpers/fetcher';
import config from '../utils/data/config'
import FormikControl from '../utils/form/FormControl';


const { Option } = AntSelect



const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    role: "",
    alias: ""
}

function AddNewUser() {
    const history = useHistory()
    const [adminandprocurementcats, setadminandprocurementcats] = useState([])
    const { addToast } = useToasts()
    const { user } = useContext(Context)
    const roles = useRoles("getallroles", user.token)
    const categories = useCategories("fetchcategoriesadminandprocurementuserbelong", user.token)

    const handleSubmit = async (values, onSubmitProps) => {
        if (!adminandprocurementcats.length) {
            showToast("error", "Please select at least one category")
            return
        }
        const body = { ...values, adminandprocurementcats }
        try {
            const res = await axios.post(`${config.baseUrl}/createadminandprocumentuser`, body, { headers: { "Authorization": `Bearer ${user.token}` } })
            if (res.data.error) {
                showToast("error", res.data.error)
            }
            else {
                showToast("success", "Successfully added user")
                history.goBack()
            }
        }
        catch (e) {
            console.log(e)
            showToast("error", e.message)
        }
        onSubmitProps.setSubmitting(false)
    }

    const showToast = (status, message) => {
        addToast(message, { appearance: status })
    }

    function tagRender(props) {
        const { label, closable, onClose } = props;

        return (
            <Tag color={globalStyles.green} closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
                {(label.length > 15) ? label.substr(0, 15 - 1) + '...' : label}
            </Tag>
        );
    }


    return (
        <Box>
            <Stack spacing={6}>
                <Flex justify="space-between">
                    <Heading as="h1" size="sm" color={globalStyles.green}>Add New User</Heading>
                    <Flex>
                        <AntButton onClick={history.goBack} type="link" icon={<ArrowLeftOutlined />}>Back</AntButton>
                    </Flex>
                </Flex>
                <Box shadow="lg" borderWidth="1px" p="5">
                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={initialValues}
                        validationSchema={addUserValidationSchema}
                    >
                        {
                            formik => (
                                <Form>
                                    <Stack spacing={8}>
                                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                            <Box w="80%">
                                                <FormikControl
                                                    control="input"
                                                    type="text"
                                                    label="First Name"
                                                    name="firstName"
                                                    placeholder="First Name"
                                                />
                                            </Box>
                                            <Box w="80%">
                                                <FormikControl
                                                    control="input"
                                                    type="text"
                                                    label="Last Name"
                                                    name="lastName"
                                                    placeholder="Last Name"
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                            <Box w="80%">
                                                <FormikControl
                                                    control="input"
                                                    type="email"
                                                    label="Email"
                                                    name="email"
                                                    placeholder="Email"
                                                />
                                            </Box>
                                            <Box w="80%">
                                                <FormikControl
                                                    control="input"
                                                    type="text"
                                                    label="Phone Number"
                                                    name="phone"
                                                    placeholder="Phone Number"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                            <Box w="80%">
                                                <Field name="role">
                                                    {(props) => {
                                                        const { field, form } = props
                                                        return (
                                                            <FormControl
                                                                isInvalid={form.errors.role && form.touched.role}
                                                                isRequired
                                                            >
                                                                <FormLabel htmlFor="role" mb={2}>
                                                                    <Heading as="a" fontSize="sm">Role</Heading>
                                                                </FormLabel>
                                                                <Skeleton isLoaded={!roles.isLoading}>
                                                                    <Select
                                                                        {...field}
                                                                        placeholder="Select Role"
                                                                        id="role"
                                                                        bg={globalStyles.lightGrey}
                                                                        border="0"
                                                                        focusBorderColor={globalStyles.green}
                                                                    >
                                                                        {
                                                                            roles.data.map(role => (
                                                                                <option key={role.id} value={role.id}>{role.role}</option>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </Skeleton>
                                                                <FormErrorMessage>{form.errors.role}</FormErrorMessage>
                                                            </FormControl>
                                                        )
                                                    }}
                                                </Field>
                                            </Box>
                                            <Box w="80%">
                                                <FormControl isRequired>
                                                    <FormLabel htmlFor="category" mb={2}>
                                                        <Heading as="a" fontSize="sm">Categories</Heading>
                                                    </FormLabel>
                                                    <Skeleton isLoaded={!categories.isLoading}>
                                                        <AntSelect
                                                            mode="multiple"
                                                            allowClear
                                                            style={{ width: '100%', backgroundColor: globalStyles.lightGrey, minHeight: 40 }}
                                                            placeholder=""
                                                            showArrow
                                                            tagRender={tagRender}
                                                            bordered={false}
                                                            defaultValue={[]}
                                                            onChange={(e) => {
                                                                setadminandprocurementcats(e)
                                                            }}

                                                        >

                                                            {
                                                                categories.data.map(item => (
                                                                    <Option key={item.id} value={item.id}>{item.category}</Option>
                                                                ))
                                                            }
                                                        </AntSelect>
                                                    </Skeleton>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                            <Box w="80%">
                                                <FormikControl
                                                    control="input"
                                                    type="text"
                                                    label="Alias"
                                                    name="alias"
                                                    placeholder="Alias"
                                                    required={false}
                                                />
                                            </Box>
                                        </Grid>
                                    </Stack>
                                    <Box mt={8}>
                                        <Button
                                            disabled={formik.isSubmitting}
                                            isLoading={formik.isSubmitting}
                                            loadingText="Submitting"
                                            type="submit"
                                            bg={globalStyles.green}
                                            color="white"
                                            _hover={{ bg: globalStyles.green }}
                                        >
                                            Submit
                                        </Button>
                                    </Box>
                                </Form>
                            )
                        }

                    </Formik>
                </Box>
            </Stack>
        </Box >
    )
}

export default AddNewUser;