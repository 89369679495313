import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Box, Stack, Flex, Heading,
    Grid,
    Button,
    useToast
} from '@chakra-ui/core';
import { Formik, Form } from 'formik';
import axios from 'axios'
import { Button as AntButton } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import globalStyles from '../../globalStyles'
import { createAdminValidationSchema } from './helpers/validationSchemas';
import { Context } from '../context/Context';
import config from '../utils/data/config'
import FormikControl from '../utils/form/FormControl';



const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
}

function CreateAdminUser() {
    const history = useHistory()
    const toast = useToast()
    const { user } = useContext(Context)

    const handleSubmit = async (values, onSubmitProps) => {
        const body = { ...values, role: 2 }
        try {
            const res = await axios.post(`${config.baseUrl}/createadmin`, body, { headers: { "Authorization": `Bearer ${user.token}` } })
            if (res.data.error) {
                showToast("error", res.data.error)
            }
            else {
                showToast("success", "Successfully added user")
                history.push('/users')
            }
        }
        catch (e) {
            console.log(e)
            showToast("error", e.message)
        }
        onSubmitProps.setSubmitting(false)
    }

    const showToast = (status, message) => {
        toast({
            title: message.toUpperCase(),
            status: status,
            duration: 2000,
            isClosable: true,
        })
    }

    return (
        <Box>
            <Stack spacing={6}>
                <Flex justify="space-between">
                    <Heading as="h1" size="sm" color={globalStyles.green}>Add New User</Heading>
                    <Flex>
                        <AntButton onClick={history.goBack} type="link" icon={<ArrowLeftOutlined />}>Back</AntButton>
                    </Flex>
                </Flex>
                <Box shadow="lg" borderWidth="1px" p="5">
                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={initialValues}
                        validationSchema={createAdminValidationSchema}
                    >
                        {
                            formik => (
                                <Form>
                                    <Stack spacing={8}>
                                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                            <Box w="80%">
                                                <FormikControl
                                                    control="input"
                                                    type="text"
                                                    label="First Name"
                                                    name="firstName"
                                                    placeholder="First Name"
                                                />
                                            </Box>
                                            <Box w="80%">
                                                <FormikControl
                                                    control="input"
                                                    type="text"
                                                    label="Last Name"
                                                    name="lastName"
                                                    placeholder="Last Name"
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                            <Box w="80%">
                                                <FormikControl
                                                    control="input"
                                                    type="email"
                                                    label="Email"
                                                    name="email"
                                                    placeholder="Email"
                                                />
                                            </Box>
                                            <Box w="80%">
                                                <FormikControl
                                                    control="input"
                                                    type="text"
                                                    label="Phone Number"
                                                    name="phone"
                                                    placeholder="Phone Number"
                                                />
                                            </Box>
                                        </Grid>
                                        {/* <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                            <Box w="80%">
                                                <FormikControl
                                                    control="input"
                                                    type="password"
                                                    label="Password"
                                                    name="password"
                                                    placeholder="Password"
                                                />
                                            </Box>
                                        </Grid> */}
                                    </Stack>
                                    <Box mt={8}>
                                        <Button
                                            disabled={formik.isSubmitting}
                                            isLoading={formik.isSubmitting}
                                            loadingText="Submitting"
                                            type="submit"
                                            bg={globalStyles.green}
                                            color="white"
                                            _hover={{ bg: globalStyles.green }}
                                        >
                                            Submit
                                        </Button>
                                    </Box>
                                </Form>
                            )
                        }
                    </Formik>
                </Box>
            </Stack>
        </Box>
    )
}

export default CreateAdminUser;