import React, { useContext } from 'react';
import { Box, Stack, Heading, Flex, IconButton } from '@chakra-ui/core'
import { useHistory } from 'react-router-dom';
import { Table } from 'antd';
import globalStyles from '../../globalStyles';
import { Context } from '../context/Context';
import { useRoles } from './helpers/fetcher';

function ManageRoles() {

    const history = useHistory()
    const columns = [
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
        },
        {
            title: 'Role ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            render: (_, record) => {
                return (
                    <IconButton
                        onClick={() => history.push(`/role/${record.id}`, record)}
                        variant="outline"
                        isRound
                        aria-label="Send email"
                        icon="edit"
                    />
                )
            }
        },
    ];
    const { user } = useContext(Context)

    const { data, isLoading } = useRoles("getallroles", user.token)
    return (
        <Box>
            <Stack spacing={6}>
                <Flex justify="space-between">
                    <Heading as="h1" size="sm" color={globalStyles.green}>Manage Roles</Heading>
                </Flex>
                <Box shadow="lg" borderWidth="1px" p="5">
                    <Table rowKey={record => record.id} loading={isLoading} columns={columns} dataSource={data} pagination={null} />
                </Box>
            </Stack>
        </Box>

    )
}
export default ManageRoles;