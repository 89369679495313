import useSWR from 'swr';
import config from '../../utils/data/config';
import { fetcher } from '../../utils/data/fetcher';


export const useUsers = (path, token) => {
    const { data, error } = useSWR([`${config.baseUrl}/${path}`, token], fetcher)
    //show error here
    return {
        data: data ? data.user : [],
        isLoading: !error && !data,
        isError: error
    }
}

export const useUser = (path, token) => {
    const { data, error } = useSWR([`${config.baseUrl}/${path}`, token], fetcher)
    //show error here
    return {
        data: data ? data.user : {},
        isLoading: !error && !data,
        isError: error
    }
}


