export const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(value)
}

export const formatNumber = (value) => {
    return new Intl.NumberFormat().format(value)
}

export const kFormatter = (num) => {
    if (Math.abs(num) > 999999999) {
        return Math.sign(num) * ((Math.abs(num) / 1000000000).toFixed(1)) + 'B'
    }
    else if (Math.abs(num) > 999999) {
        return Math.sign(num) * ((Math.abs(num) / 1000000).toFixed(1)) + 'M'
    }
    else {
        return formatNumber(num)
    }
}