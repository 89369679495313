import React from 'react';
import {
    Box, Stack, Heading, Flex, Grid,
    Stat,
    StatLabel,
    StatNumber,
    StatGroup,
    Button
} from '@chakra-ui/core'
import globalStyles from '../../globalStyles';


function Setup() {
    return (
        <Box>
            <Stack spacing={6}>
                <Flex justify="space-between">
                    <Heading as="h1" size="sm" color={globalStyles.green}>Notification Setup</Heading>
                </Flex>
                <Box py="5">
                    <Grid templateColumns="repeat(2, 1fr)" gap={12}>
                        <Box w="100%" p="6" bg={globalStyles.green} borderRadius="md">
                            <Stat>
                                <StatLabel color="white">Sender Details</StatLabel>
                                <StatNumber color="white">Tradebuza</StatNumber>
                            </Stat>
                        </Box>
                        <Box w="100%" p="6" bg={globalStyles.green} borderRadius="md">
                            <StatGroup>
                                <Stat>
                                    <StatLabel color="white">Available Balance</StatLabel>
                                    <StatNumber color="white">345,670</StatNumber>
                                    {/* <StatHelpText>
                                        <StatArrow type="increase" />
                                        23.36%
                                    </StatHelpText> */}
                                </Stat>
                                <Stat>
                                    <StatLabel color="white">Price per SMS</StatLabel>
                                    <StatNumber color="white">45</StatNumber>
                                </Stat>
                            </StatGroup>
                            <Box mt={4}>
                                <Button>Recharge</Button>
                            </Box>
                        </Box>
                    </Grid>
                </Box>
                <Box>
                    <Box>
                        <Heading as="h1" size="sm" >Scheduled Messages</Heading>
                    </Box>
                    <Box shadow="lg">
                        {/* <Table rowSelection={rowSelection} rowKey={record => record.id} loading={suppliersLoading} columns={columns} dataSource={suppliers ? suppliers.filter(item => item.role === 4) : []} /> */}
                    </Box>
                </Box>
            </Stack>
        </Box>

    )
}
export default Setup;