import React, { useContext } from 'react';
import { Box, Stack, Heading, Flex, IconButton } from '@chakra-ui/core'
import { useHistory, useLocation, Link } from 'react-router-dom';
import { Button as AntButton } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Table, Button } from 'antd';
import globalStyles from '../../globalStyles';
import { Context } from '../context/Context';
import { useSubCategories } from './helpers/fetcher';

function ManageSubCategories() {

    const history = useHistory()
    const columns = [
        {
            title: 'Sub Category Name',
            dataIndex: 'subcategory',
            key: 'subcategory',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (text) => {
                return <span>{text ? text : 'N/A'}</span>
            }
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            render: (_, record) => {
                return (
                    <IconButton
                        onClick={() => history.push(`/categories/sub/edit/${record.id}`, record)}
                        variant="outline"
                        isRound
                        aria-label="Send email"
                        icon="edit"
                    />
                )
            }
        },

    ];


    const location = useLocation()
    const id = location.pathname.split("/")[3]
    const { user } = useContext(Context)

    const { subcategories, isLoading } = useSubCategories(`fetchsubcategorybycategoryid`, user.token, id)

    return (
        <Box>
            <Stack spacing={6}>
                <Flex justify="space-between">
                    <Heading as="h1" size="sm" color={globalStyles.green}>Manage Sub Categories</Heading>
                    <Flex>
                        <AntButton onClick={history.goBack} type="link" icon={<ArrowLeftOutlined />}>Back</AntButton>
                        <Link to="/categories/add/sub">
                            <Button type="primary">
                                Add New Sub Category
                            </Button>
                        </Link>
                    </Flex>

                </Flex>
                <Box shadow="lg" borderWidth="1px" p="5">
                    <Table rowKey={record => record.id} loading={isLoading} columns={columns} dataSource={subcategories} pagination={null} />
                </Box>
            </Stack>
        </Box>

    )
}
export default ManageSubCategories;