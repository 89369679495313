import React from 'react';
import { Field } from 'formik';
import {
    FormControl, FormLabel, Heading, NumberInput, NumberInputStepper, NumberIncrementStepper,
    NumberDecrementStepper,
    NumberInputField,
    FormErrorMessage
} from '@chakra-ui/core';
import globalStyles from '../../../globalStyles';

function ChakraNumberInput(props) {
    const { label, name, ...rest } = props;

    return (
        <Field name={name}>
            {(props) => {
                const { field, form } = props
                return (
                    <FormControl
                        isInvalid={form.errors[name] && form.touched[name]}
                        isRequired
                    >
                        <FormLabel htmlFor={name} mb={2}>
                            <Heading as="a" fontSize="sm">{label}</Heading>
                        </FormLabel>
                        <NumberInput>
                            <NumberInputField
                                {...field}
                                {...rest}
                                id={name}
                                border="0"
                                bg={globalStyles.lightGrey}
                                focusBorderColor={globalStyles.green} />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>

                        <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
                    </FormControl>
                )
            }}
        </Field>
    )
}

export default ChakraNumberInput;


