import * as Yup from 'yup';

export const addTenderValidationSchema = Yup.object({
    tenderName: Yup.string().required("Tender Name is required"),
    description: Yup.string().required("Tender Description is required"),
    categoryId: Yup.string().required("Category is required"),
    subcategoryId: Yup.string().notRequired(),
    dueDate: Yup.string().required("Due Date is required"),
    departmentId: Yup.string().notRequired(),
})
