import React, { useContext } from 'react';
import { Box, Stack, Heading, Flex } from '@chakra-ui/core'
import { Link } from 'react-router-dom';
import { Table, Button } from 'antd';
import globalStyles from '../../globalStyles';
import { Context } from '../context/Context';
import { useLocations } from './helpers/fetcher';

function ManageLocations() {

    const columns = [

        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            render: (text) => {
                return <span>{text ? text : 'N/A'}</span>
            }
        },
        // {
        //     title: 'View Sub Categories',
        //     dataIndex: 'edit',
        //     key: 'edit',
        //     render: (_, record) => {
        //         return (
        //             <IconButton
        //                 onClick={() => history.push(`/categories/sub/${record.id}`, record)}
        //                 variant="outline"
        //                 isRound
        //                 aria-label="Send email"
        //                 icon={MdVisibility}
        //             />
        //         )
        //     }
        // },
        // {
        //     title: 'Edit',
        //     dataIndex: 'edit',
        //     key: 'edit',
        //     render: (_, record) => {
        //         return (
        //             <IconButton
        //                 onClick={() => history.push(`/category/${record.id}/edit`, record)}
        //                 variant="outline"
        //                 isRound
        //                 aria-label="Send email"
        //                 icon="edit"
        //             />
        //         )
        //     }
        // },
    ];
    const { user } = useContext(Context)

    const { data, isLoading } = useLocations("getlocations", user.token)
    return (
        <Box>
            <Stack spacing={6}>
                <Flex justify="space-between">
                    <Heading as="h1" size="sm" color={globalStyles.green}>Manage Categories</Heading>
                    <Link to="/locations/add">
                        <Button type="primary">
                            Add New Location
                    </Button>
                    </Link>
                </Flex>
                <Box shadow="lg" borderWidth="1px" p="5">
                    <Table rowKey={record => record.id} loading={isLoading} columns={columns} dataSource={data} pagination={null} />
                </Box>
            </Stack>
        </Box>

    )
}
export default ManageLocations;