import { useSWRInfinite } from 'swr';
import React, { useContext, useState, useEffect } from 'react';
import * as dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Box, Flex, Stack, Avatar, Heading, Text, Tag, AccordionItem, AccordionHeader, Accordion, AccordionPanel, AccordionIcon, Select } from '@chakra-ui/core';
import { Context } from '../context/Context'
import { MdAttachFile } from 'react-icons/md';
import { AiOutlineDownload } from 'react-icons/ai';
import globalStyles from '../../globalStyles';
import { Divider, Button, Spin, Table, Empty } from 'antd';
import { fetcher } from '../utils/data/fetcher';
import { useCategories, useSubCategories } from '../categories/helpers/fetcher';
import Can from '../utils/rbac/Can';
import { downloadFile } from '../utils/download';
import config from '../utils/data/config';

const { Column } = Table;

function Tenders() {
    const { user } = useContext(Context);
    const roleString = user.role;
    const [selectedCategory, setSelectedCategory] = useState("")
    const [selectedSubCategory, setSelectedSubCategory] = useState("")

    const [selectedTender, setSelectedTender] = useState(null)

    const filterCategory = selectedCategory ? `&categoryid=${selectedCategory}` : ''
    const filterSubCategory = selectedSubCategory ? `&subcategoryid=${selectedSubCategory}` : ''

    const filterPath = filterCategory + filterSubCategory;

    const { data: categories } = useCategories("fetchcategoriesadminandprocurementuserbelong", user.token)
    const { subcategories } = useSubCategories("fetchsubcategorybycategoryid", user.token, selectedCategory)

    const { data, error, size, setSize } = useSWRInfinite(
        index => [`${config.baseUrl}/filtertenders?page=${index + 1}${filterPath}`, user.token],
        fetcher, { revalidateOnFocus: false }
    );

    const tenderData = data ? [].concat(...data) : [];
    const isLoadingInitialData = !data && !error;
    const isLoadingMore =
        isLoadingInitialData ||
        (size > 0 && data && typeof data[size - 1] === "undefined");
    const isEmpty = data?.[0]?.length === 0;
    const isReachingEnd =
        isEmpty || (data && data[data.length - 1]?.length < 10);


    useEffect(() => {
        if (!selectedTender && tenderData.length && tenderData[0].tenders.length) {
            setSelectedTender(tenderData[0].tenders.filter(tender => tender.tenderItemsObject)[0])
        }

    }, [tenderData, selectedTender])

    return (
        <>
            <Box bg={globalStyles.green} px={4} py={2} rounded="md">
                <Stack isInline spacing={8} align="center">
                    <Text color="white" fontSize="md">Filter By:</Text>
                    <Select
                        w="25%"
                        placeholder="Category"
                        id="category"
                        onChange={(e) => {
                            setSelectedTender(null)
                            setSelectedCategory(e.target.value)
                        }}
                        bg={globalStyles.lightGrey}
                        border="0"
                        focusBorderColor={globalStyles.green}
                    >
                        {
                            categories.map(item => (
                                <option key={item.id} value={item.id}>{item.category}</option>
                            ))
                        }
                    </Select>
                    <Select
                        w="25%"
                        placeholder="Sub Category"
                        id="subcategoryId"
                        bg={globalStyles.lightGrey}
                        onChange={(e) => {
                            setSelectedTender(null)
                            setSelectedSubCategory(e.target.value)
                        }}
                        border="0"
                        focusBorderColor={globalStyles.green}
                    >
                        {
                            subcategories.map(subcategory => (
                                <option key={subcategory.id} value={subcategory.id}>{subcategory.subcategory}</option>
                            ))
                        }
                    </Select>
                </Stack>
            </Box>
            <br />
            <Flex justify="flex-end">
                <Can
                    role={roleString}
                    perform="manage-tenders:add"
                    yes={() => (
                        <Link to="/tenders/add">
                            <Button type="primary">Add New Tender</Button>
                        </Link>
                    )}
                    no={() => null}
                />
            </Flex>
            <br />
            <Flex w="100%">
                <Box w="30%" h="80vh" overflowY="scroll" shadow="lg" borderWidth="1px">

                    {!isLoadingInitialData && tenderData.map(data => data.tenders.filter(item => item.tenderItemsObject).length)[0] === 0 ?
                        <Flex justify="center" h="40vh" align="center">
                            <Empty />
                        </Flex> :
                        null
                    }
                    {isLoadingInitialData &&
                        <Box textAlign="center">
                            <Spin />
                        </Box>
                    }
                    {tenderData.map(data => data.tenders.filter(item => item.tenderItemsObject).map(tender => {
                        return (
                            <Stack
                                key={tender.id}
                                cursor="pointer"
                                isInline
                                onClick={() => {
                                    setSelectedTender(tender)
                                }}
                                spacing={4} p="2"
                                bg={selectedTender?.id === tender.id ? "#e1f7f4" : "white"}>
                                <Box>
                                    <Avatar name="Christian Nwamba" src="https://bit.ly/code-beast" />
                                </Box>
                                <Box flex="1">
                                    <Flex justify="space-between" align="start">
                                        <Text fontSize="xs" color={globalStyles.darkGrey}>{tender.category?.category ?? tender.categoryName}</Text>
                                        <Box>
                                            <Text fontSize="xs" color={globalStyles.darkGrey}>{dayjs(tender.created_at).format('DD MMM YYYY')}</Text>
                                        </Box>
                                    </Flex>
                                    <Box>
                                        <Flex justify="space-between">
                                            <Text>
                                                {tender.tenderName}
                                            </Text>
                                            <Text fontSize="xs" color={globalStyles.darkGrey}>
                                                {tender.attachement ? <MdAttachFile /> : null}
                                            </Text>
                                        </Flex>
                                    </Box>
                                    <Box w="80">
                                        <Text fontSize="xs" color={globalStyles.darkGrey}>
                                            {tender.description}
                                        </Text>
                                    </Box>
                                </Box>
                            </Stack>
                        )
                    }))}
                    {isEmpty ?
                        <Box textAlign="center" my={5}>
                            <Button
                                disabled={isLoadingMore || isReachingEnd}
                                onClick={() => setSize(size + 1)}
                            >
                                {isLoadingMore
                                    ? <Spin />
                                    : isReachingEnd
                                        ? "no more tenders"
                                        : "load more"}
                            </Button>
                        </Box>
                        : null
                    }
                </Box>
                <Box w="70%" px="2" shadow="lg" borderWidth="1px">

                    {!isLoadingInitialData && tenderData.map(data => data.tenders.filter(item => item.tenderItemsObject).length)[0] === 0 ?
                        <Flex justify="center" h="80vh">
                            <Empty />
                        </Flex> :
                        null
                    }
                    {isLoadingInitialData &&
                        <Box textAlign="center">
                            <Text>Loading...</Text>
                        </Box>
                    }
                    {selectedTender &&
                        <>
                            <Flex justify="space-between" px="4" pt="2">
                                <Stack isInline spacing={4}>
                                    <Avatar name="Christian Nwamba" src="https://bit.ly/code-beast" size="lg" />
                                    <Stack spacing={1}>
                                        <Text color="#7F63F4">{selectedTender.createdBy?.name} {selectedTender.createdBy?.lastName}</Text>
                                        <Text fontSize="xs" color={globalStyles.darkGrey}>Due Date:  {dayjs(selectedTender.dueDate).format('DD MMM YYYY')}</Text>
                                        <Text fontSize="xs" color={globalStyles.darkGrey}>Created:   {dayjs(selectedTender.created_at).format('DD MMM YYYY')}</Text>
                                    </Stack>
                                </Stack>
                                <Box>
                                    {selectedTender.status === 0 ? <Heading as="h6" size="xs" color={globalStyles.green}>Open</Heading> : <Heading as="h6" size="xs" color={globalStyles.red}>Closed</Heading>}
                                </Box>
                            </Flex>
                            <Divider />
                            <Box px="4" pb="8">
                                <Stack spacing={8}>
                                    <Box>
                                        <Text color={globalStyles.green}>{selectedTender.tenderName}</Text>
                                        {/* <Text color={globalStyles.darkGrey} fontSize="xs">To:me,chisom@gmail.com</Text> */}
                                    </Box>
                                    {/* <Box>
                                        <Text color={globalStyles.darkGrey} fontSize="xs">Ingredia Nutrisha, A collection of textile samples lay spread out on the table - Samsa was a travelling salesman - and above it there hung a picture</Text>
                                    </Box> */}
                                    <Box>
                                        <Text color={globalStyles.darkGrey} fontSize="xs">
                                            {selectedTender.description}
                                        </Text>
                                    </Box>
                                    {selectedTender.attachement &&
                                        <Box w="50%" borderWidth="1px" p="4" cursor="pointer" onClick={() => downloadFile(selectedTender.attachement)}>
                                            <Flex justify="space-between" align="center">
                                                <Box>
                                                    <Text fontWeight="bold" fontSize="xs">Attachment</Text>
                                                </Box>
                                                <Heading as="h4" size="md"><AiOutlineDownload /></Heading>
                                            </Flex>
                                        </Box>
                                    }
                                </Stack>
                            </Box>
                            <Heading as="h6" size="xs">BID TERMS</Heading>
                            <br />
                            <Accordion allowToggle>
                                {JSON.parse(selectedTender.tenderItemsObject).map(item => {
                                    return (
                                        <AccordionItem key={item.id}>
                                            <AccordionHeader>
                                                <Box flex="1" textAlign="left">
                                                    <Flex justify="space-between" align="center">
                                                        <Box>
                                                            <Heading as="h6" size="xs">{item.itemName}</Heading>
                                                            <Text fontSize="xs" color={globalStyles.darkGrey}> {item.deliveryTerm === 1 ? "Staggered Delivery" : "Bulk Delivery"}</Text>
                                                        </Box>
                                                        <Box>
                                                            <Tag variantColor="cyan" rounded="full" size="sm" variant="subtle">
                                                                {item.fixedPrice === 0 ? "No" : "Yes"}
                                                            </Tag>
                                                            <Text fontSize="xs" color={globalStyles.darkGrey}>Fixed Prices</Text>
                                                        </Box>
                                                        <Box>
                                                            <Tag variantColor="cyan" rounded="full" size="sm">
                                                                {item.invoicePaymentTerms} Days
                                                            </Tag>
                                                            <Text fontSize="xs" color={globalStyles.darkGrey}>Invoice Payment Terms</Text>
                                                        </Box>
                                                        <AccordionIcon />
                                                    </Flex>
                                                </Box>
                                            </AccordionHeader>
                                            <AccordionPanel pb={4}>
                                                <Table size="small" pagination={false} rowKey={record => record.id} dataSource={item.subItems}>
                                                    <Column
                                                        title={<Heading as="h6" size="xs">Material</Heading>}
                                                        dataIndex='materialDescription'
                                                        key='materialDescription'

                                                    />
                                                    <Column
                                                        title={<Heading as="h6" size="xs">Unit</Heading>}
                                                        dataIndex='unit'
                                                        key='unit'
                                                    />
                                                    <Column
                                                        title={<Heading as="h6" size="xs">Quantity</Heading>}
                                                        dataIndex='quantity'
                                                        key='quantity'
                                                    />
                                                    <Column
                                                        title={<Heading as="h6" size="xs">Location</Heading>}
                                                        dataIndex='location'
                                                        key='location'
                                                    />
                                                    <Column
                                                        title={<Heading as="h6" size="xs">Delivery Time</Heading>}
                                                        dataIndex='deliveryTime'
                                                        key='deliveryTime'
                                                        render={(text) => dayjs(text).format('DD MMM YYYY')}
                                                    />
                                                </Table>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    )
                                })}
                            </Accordion>
                            <br />
                            <Can
                                role={roleString}
                                perform="supplier-dash:view"
                                // data={{
                                //     hasBid: data.hasBidded,
                                //     status: data.status
                                // }}
                                yes={() => (
                                    <Link to={`/supplier-bids/add/${selectedTender.id}`}>
                                        <Button type="primary">Respond</Button>
                                    </Link>
                                )}
                                no={() => null}
                            />
                        </>
                    }
                </Box>
            </Flex>
        </>
    )
}

export default Tenders;