import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button as AntButton } from 'antd';
import { Form, Formik, Field } from 'formik';
import {
    Box, Stack, Flex, Heading, FormControl, FormLabel, Input, FormErrorMessage,
    Button,
    Skeleton
} from '@chakra-ui/core';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useUser } from '../users/helpers/fetcher';

import globalStyles from '../../globalStyles'
import { changeAuthSchema } from './helpers/validationSchemas';
import { Context } from '../context/Context'
import config from '../utils/data/config'
import { mutate } from 'swr';


const initialValues = {
    email: "",
    password: "",
}



function ChangeAuth() {
    const { user } = useContext(Context)
    const { addToast } = useToasts()
    const history = useHistory()
    const location = useLocation()

    const id = location.pathname.split("/")[2]

    const requestOptions = {
        headers: { "Authorization": `Bearer ${user.token}` },
    }

    const { data, isLoading } = useUser(`getuserbyid/${id}`, user.token)

    const savedValues = Object.entries(data).length > 0 ? {
        email: data.email,
        password: "",
    } : initialValues




    const handleSubmit = async (values, onSubmitProps) => {
        const body = { ...values, id }
        try {
            const res = await axios.post(`${config.baseUrl}/updateuserpasswordandemail`, body, requestOptions)
            if (res.data.error) {
                showToast("error", res.data.error)
            }
            else {
                showToast("success", "Successfully changed credentials")
                mutate([`${config.baseUrl}/getuserbyid.${id}`, user.token])
            }
        }
        catch (e) {
            showToast("error", e.message)
        }
        onSubmitProps.setSubmitting(false)
    }

    const showToast = (status, message) => {
        addToast(message, { appearance: status })
    }

    return (
        <Box>
            <Stack spacing={6}>
                <Flex justify="space-between">
                    <Heading as="h1" size="sm" color={globalStyles.green}>Change Auth</Heading>
                    <Flex>
                        <AntButton onClick={history.goBack} type="link" icon={<ArrowLeftOutlined />}>Back</AntButton>
                    </Flex>
                </Flex>
                <Box shadow="lg" borderWidth="1px" p="5" w="50%">
                    <Formik
                        onSubmit={handleSubmit}
                        enableReinitialize={true}
                        initialValues={savedValues}
                        validationSchema={changeAuthSchema}
                    >
                        {
                            formik => (
                                <Form>
                                    <Stack spacing={8}>
                                        <Box w="80%">

                                            <Field name="email">
                                                {(props) => {
                                                    const { field, form } = props
                                                    return (
                                                        <FormControl
                                                            isInvalid={form.errors.email && form.touched.email}
                                                            isRequired
                                                        >
                                                            <FormLabel htmlFor="email" mb={2}>
                                                                <Heading as="a" fontSize="sm">Email</Heading>
                                                            </FormLabel>
                                                            <Skeleton isLoaded={!isLoading}>
                                                                <Input
                                                                    {...field}
                                                                    id="email"
                                                                    border="0"
                                                                    bg={globalStyles.lightGrey}
                                                                    focusBorderColor={globalStyles.green} />
                                                            </Skeleton>


                                                            <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                                                        </FormControl>
                                                    )
                                                }}
                                            </Field>
                                        </Box>
                                        <Box w="80%">
                                            <Field name="password">
                                                {(props) => {
                                                    const { field, form } = props
                                                    return (
                                                        <FormControl
                                                            isInvalid={form.errors.password && form.touched.password}
                                                            isRequired
                                                        >
                                                            <FormLabel htmlFor="password" mb={2}>
                                                                <Heading as="a" fontSize="sm">Password</Heading>
                                                            </FormLabel>
                                                            <Skeleton isLoaded={!isLoading}>
                                                                <Input
                                                                    {...field}
                                                                    id="password"
                                                                    type="password"
                                                                    border="0"
                                                                    bg={globalStyles.lightGrey}
                                                                    focusBorderColor={globalStyles.green} />
                                                            </Skeleton>
                                                            <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                                                        </FormControl>
                                                    )
                                                }}
                                            </Field>
                                        </Box>
                                    </Stack>
                                    <Box mt={8}>
                                        <Button
                                            disabled={formik.isSubmitting}
                                            isLoading={formik.isSubmitting}
                                            loadingText="Submitting"
                                            type="submit"
                                            bg={globalStyles.green}
                                            color="white"
                                            _hover={{ bg: globalStyles.green }}
                                        >
                                            Submit
                                        </Button>
                                    </Box>
                                </Form>
                            )
                        }
                    </Formik>
                </Box>
            </Stack>
        </Box >
    )
}

export default ChangeAuth;