import React from 'react';
import { Box, Stack, Heading, Flex, Text } from '@chakra-ui/core'
import globalStyles from '../../globalStyles';

function ManageContracts() {

    return (
        <Box>
            <Stack spacing={6}>
                <Flex justify="space-between">
                    <Heading as="h1" size="sm" color={globalStyles.green}>Manage Contracts</Heading>
                </Flex>
                <Box py="5">
                    <Text textAlign="center" fontSize="md" as="i">Coming Soon</Text>
                </Box>
            </Stack>

        </Box>

    )
}
export default ManageContracts;