import React, { useContext, useState } from 'react';
import { Box, Stack, Heading, Flex, Grid, Text, Select } from '@chakra-ui/core'
import { Checkbox } from 'antd';
import globalStyles from '../../globalStyles';
import { Context } from '../context/Context'
import LineChart from './Line';
import { useDashboard, useChart } from './helpers/fetcher';
import { kFormatter } from '../utils/formatCurrency';
import Can from '../utils/rbac/Can';
import Tenders from './Tenders';
import AmountLineChart from './AmountLine';


function Dashboard() {
    const { user } = useContext(Context);

    const roleString = user.role

    const [showBidsWonAmount, setShowBidsWonAmount] = useState(false)

    const path = roleString === 4 ? 'fetchsupplierdashboarddata' : 'fetchadmindashboarddata'
    const chartPath = roleString === 4 ? 'fetchsupplierlastthirtydaysdata' : 'fetchadminlastthirtydaysdata'
    const bidsAmountChartPath = roleString === 4 ? 'fetchsupplierlastthirtydaysbidswonamount' : 'fetchadminlastthirtydaysbidswonamount'

    const [chartDateFilter, setChartDateFilter] = useState(chartPath)
    const [bidsWonAmountDateFilter, setBidsWonDateFilter] = useState(bidsAmountChartPath)
    const { data, isLoading } = useDashboard(path, user.token)
    const { data: chart } = useChart(showBidsWonAmount ? bidsWonAmountDateFilter : chartDateFilter, user.token)

    return (
        <Box>
            <Stack spacing={6}>
                <Flex justify="space-between">
                    <Heading as="h1" size="sm" color={globalStyles.green}>Dashboard</Heading>
                </Flex>
                <Box py="5">
                    <Can
                        role={roleString}
                        perform="supplier-dash:view"
                        yes={() => (
                            <Grid templateColumns="repeat(3, 1fr)" gap={6}>
                                <Box w="100%" py="6" bg={globalStyles.green} textAlign="center" borderRadius="md">
                                    <Text color={"#FFF"}>
                                        Total Tenders
                                </Text>
                                    <Heading as="h2" size="xl" color={"#FFF"}>
                                        {data.tendersAllocated || 0}
                                    </Heading>
                                </Box>
                                <Box w="100%" py="6" bg={globalStyles.green} textAlign="center" borderRadius="md">
                                    <Text color={"#FFF"}>
                                        Total Bids Won
                                </Text>
                                    <Heading as="h2" size="xl" color={"#FFF"}>
                                        {data.bidsWon || 0}
                                    </Heading>
                                </Box>
                                <Box w="100%" py="6" bg={globalStyles.green} textAlign="center" borderRadius="md">
                                    <Text color={"#FFF"}>
                                        Total Amount of Bids Won
                                </Text>
                                    <Heading as="h2" size="xl" color={"#FFF"}>
                                        NGN {data && kFormatter(data.totalBidsWonAmount || 0)}
                                    </Heading>
                                </Box>
                            </Grid>
                        )}
                        no={() => null}
                    />
                    <Can
                        role={roleString}
                        perform="admin-dash:view"
                        yes={() => (
                            <Grid templateColumns="repeat(4, 1fr)" gap={3}>
                                <Box w="100%" py="6" bg={globalStyles.green} textAlign="center" borderRadius="md">
                                    <Text color={"#FFF"}>
                                        Total Suppliers
                                </Text>
                                    <Heading as="h2" size="xl" color={"#FFF"}>
                                        {data.suppliersCount || 0}
                                    </Heading>
                                </Box>
                                <Box w="100%" py="6" bg={globalStyles.green} textAlign="center" borderRadius="md">
                                    <Text color={"#FFF"}>
                                        Total Tenders
                                </Text>
                                    <Heading as="h2" size="xl" color={"#FFF"}>
                                        {data.tenderCount || 0}
                                    </Heading>
                                </Box>
                                <Box w="100%" py="6" bg={globalStyles.green} textAlign="center" borderRadius="md">
                                    <Text color={"#FFF"}>
                                        Total Submitted Bids
                                </Text>
                                    <Heading as="h2" size="xl" color={"#FFF"}>
                                        {data.totalSubmittedbids || 0}
                                    </Heading>
                                </Box>
                                <Box w="100%" py="6" bg={globalStyles.green} textAlign="center" borderRadius="md">
                                    <Text color={"#FFF"}>
                                        Total Amount of Bids Won
                                </Text>
                                    <Heading as="h2" size="xl" color={"#FFF"}>
                                        ₦{data && kFormatter(data.totalBidsWonAmount || 0)}
                                    </Heading>
                                </Box>
                            </Grid>
                        )}
                        no={() => null}
                    />

                </Box>
                <Can
                    role={roleString}
                    perform="admin-dash:view"
                    yes={() => (
                        <Box py="5">
                            <Stack isInline spacing={8} align="center">
                                <Heading size="sm">Filter By:</Heading>
                                {showBidsWonAmount ?
                                    <Select
                                        w="25%"
                                        placeholder=""
                                        onChange={(e) => {
                                            setBidsWonDateFilter(e.target.value)
                                        }}
                                        bg={globalStyles.lightGrey}
                                        border="0"
                                        focusBorderColor={globalStyles.green}
                                    >
                                        <option value={"fetchadminlastthirtydaysbidswonamount"}>Last 30 Days</option>
                                        <option value={"fetchadminlastninetydaysbidswonamount"}>Last 90 Days</option>
                                        <option value={"fetchadminthismonthbidswonamount"}>This Month</option>
                                        <option value={"fetchadminthisweekbidswonamount"}>This Week</option>
                                    </Select>
                                    :
                                    <Select
                                        w="25%"
                                        placeholder=""
                                        onChange={(e) => {
                                            setChartDateFilter(e.target.value)
                                        }}
                                        bg={globalStyles.lightGrey}
                                        border="0"
                                        focusBorderColor={globalStyles.green}
                                    >
                                        <option value={"fetchadminlastthirtydaysdata"}>Last 30 Days</option>
                                        <option value={"fetchadminlastninetydaysdata"}>Last 90 Days</option>
                                        <option value={"fetchadminthismonthdata"}>This Month</option>
                                        <option value={"fetchadminthisweekdata"}>This Week</option>
                                    </Select>

                                }
                                <Checkbox
                                    onChange={(e) => setShowBidsWonAmount(e.target.checked)}
                                    checked={showBidsWonAmount}
                                >
                                    Show Total Bids Won Amount
                                </Checkbox>
                            </Stack>

                            <Box mt='5'>
                                {showBidsWonAmount
                                    ? <AmountLineChart chartData={chart} />
                                    : <LineChart chartData={chart} />
                                }

                            </Box>
                        </Box>
                    )}
                    no={() => null}
                />
                <Can
                    role={roleString}
                    perform="admin-dash:view"
                    yes={() => (
                        <div>
                            <Heading as="h4" size="sm" my="4" color={globalStyles.darkGrey}>Latest Open Tenders</Heading>
                            <Box shadow="lg" borderWidth="1px" p="5">
                                <Tenders data={data} isLoading={isLoading} />
                            </Box>
                        </div>

                    )}
                    no={() => null}
                />
                <Can
                    role={roleString}
                    perform="supplier-dash:view"
                    yes={() => (
                        <Box py="5">
                            <Stack isInline spacing={8} align="center">
                                <Heading size="sm">Filter By:</Heading>
                                {showBidsWonAmount
                                    ? <Select
                                        w="25%"
                                        placeholder=""
                                        onChange={(e) => {
                                            setShowBidsWonAmount(e.target.value)
                                        }}
                                        bg={globalStyles.lightGrey}
                                        border="0"
                                        focusBorderColor={globalStyles.green}
                                    >
                                        <option value={"fetchsupplierlastthirtydaysbidswonamount"}>Last 30 Days</option>
                                        <option value={"fetchsupplierlastninetydaysbidswonamount"}>Last 90 Days</option>
                                        <option value={"fetchsupplierthismonthbidswonamount"}>This Month</option>
                                        <option value={"fetchsupplierthisweekbidswonamount"}>This Week</option>
                                    </Select> :

                                    <Select
                                        w="25%"
                                        placeholder=""
                                        onChange={(e) => {
                                            setChartDateFilter(e.target.value)
                                        }}
                                        bg={globalStyles.lightGrey}
                                        border="0"
                                        focusBorderColor={globalStyles.green}
                                    >
                                        <option value={"fetchsupplierlastthirtydaysdata"}>Last 30 Days</option>
                                        <option value={"fetchsupplierlastninetydaysdata"}>Last 90 Days</option>
                                        <option value={"fetchsupplierthismonthdata"}>This Month</option>
                                        <option value={"fetchsupplierthisweekdata"}>This Week</option>
                                    </Select>
                                }
                                <Checkbox

                                    onChange={(e) => setShowBidsWonAmount(e.target.checked)}
                                    checked={showBidsWonAmount}
                                >
                                    Show Total Bids Won Amount
                                </Checkbox>
                            </Stack>
                            <Box mt='5'>
                                {showBidsWonAmount
                                    ? <AmountLineChart chartData={chart} />
                                    : <LineChart chartData={chart} />
                                }

                            </Box>
                        </Box>
                    )}
                    no={() => null}
                />
                <Can
                    role={roleString}
                    perform="supplier-dash:view"
                    yes={() => (
                        <div>
                            <Heading as="h4" size="sm" my="4" color={globalStyles.darkGrey}>Pending Bids</Heading>
                            <Box shadow="lg" borderWidth="1px" p="5">
                                <Tenders data={data} isLoading={isLoading} />
                            </Box>
                        </div>

                    )}
                    no={() => null}
                />
            </Stack>

        </Box>

    )
}
export default Dashboard;