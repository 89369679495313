import React from 'react'
import { Box, Text } from '@chakra-ui/core'
import { Link } from 'react-router-dom';
import globalStyles from '../../globalStyles';

export const LinkAddCategories = () => (
    <Link to="/categories/add">
        <Box mt={4}>
            <Text color={globalStyles.green} fontSize="sm">Create New Category</Text>
        </Box>
    </Link>
)

export const LinkAddSubCategories = () => (
    <Link to="/categories/add/sub">
        <Box mt={4}>
            <Text color={globalStyles.green} fontSize="sm">Create New Sub-Category</Text>
        </Box>
    </Link>
)