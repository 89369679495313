import React, { useContext } from 'react';
import { Link as ReachLink } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { useToasts } from 'react-toast-notifications'
import {
    FormControl,
    FormErrorMessage,
    Input,
    Box,
    Heading,
    Text,
    Button,
    Stack,
    Link,
} from "@chakra-ui/core";
import axios from 'axios';
import { loginValidationSchema } from './helpers/validationSchemas';
import globalStyles from '../../globalStyles';
import { Context } from '../context/Context';
import config from '../utils/data/config';
import logo from '../../assets/honeywell.png';


const initialValues = {
    email: "",
    password: ""
}


function LoginPage() {
    const { addToast } = useToasts()
    const { dispatchUser } = useContext(Context)


    const handleSubmit = async (values, onSubmitProps) => {
        try {
            const res = await axios.post(`${config.baseUrl}/loginuser`, values)
            if (res.data.error) {
                showToast("error", res.data.error)
            }
            else {
                const user = { ...res.data.user, token: res.data.token }
                dispatchUser({
                    type: 'LOG_IN', user: user
                });
            }
        }
        catch (e) {
            showToast("error", e.messager)
        }
        onSubmitProps.setSubmitting(false)
    }

    const showToast = (status, message) => {
        addToast(message, { appearance: status })
    }

    return (
        <Box>
            <Box mt="1%" textAlign="center">
                <img width="100px" src={logo} alt="logo" style={{ display: 'inline-block' }} />
            </Box>
            <Stack spacing={4} mt="1%">
                <Heading textAlign="center" color={globalStyles.green} mb={4} fontSize="3xl">Honeywell eProcurement Beta</Heading>
                <Text as="i" textAlign="center" color={globalStyles.darkGrey} fontSize="xl">...Digitizing Supply Chains</Text>
            </Stack>
            <Box
                mx="auto"
                mt="4%"
                width={[
                    "90%", // base
                    "50%", // 480px upwards
                    "50%", // 768px upwards
                    "35%", // 992px upwards
                ]}
                p={10}
                shadow="lg" borderWidth="1px">
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    validationSchema={loginValidationSchema}
                >
                    {(formik) => (
                        <Form>
                            <Stack spacing={8}>
                                <Box>
                                    <Heading textAlign="center" color={globalStyles.green} mb={4} fontSize="xl">Sign In</Heading>
                                </Box>
                                <Box>
                                    <Field name="email">
                                        {(props) => {
                                            const { field, form } = props
                                            return (
                                                <FormControl
                                                    isInvalid={form.errors.email && form.touched.email}
                                                    isRequired
                                                >
                                                    {/* <FormLabel htmlFor="email">Email</FormLabel> */}
                                                    <Input
                                                        focusBorderColor={globalStyles.green}
                                                        {...field} id="email"
                                                        placeholder="Email"
                                                        bg={globalStyles.lightGrey} />
                                                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                                                </FormControl>
                                            )
                                        }}
                                    </Field>
                                </Box>
                                <Box>
                                    <Field name="password">
                                        {(props) => {
                                            const { field, form } = props
                                            return (
                                                <FormControl
                                                    isInvalid={form.errors.password && form.touched.password}
                                                    isRequired
                                                >
                                                    {/* <FormLabel htmlFor="password">Password</FormLabel> */}
                                                    <Input
                                                        focusBorderColor={globalStyles.green}
                                                        type="password"
                                                        {...field} id="password"
                                                        placeholder="Password"
                                                        bg="#F0F0F1"
                                                        border="0" />
                                                    <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                                                </FormControl>
                                            )
                                        }}
                                    </Field>
                                </Box>
                                <Button
                                    disabled={formik.isSubmitting}
                                    isLoading={formik.isSubmitting}
                                    loadingText="Submitting"
                                    width="100%"
                                    type="submit"
                                    bg={globalStyles.green}
                                    color="white"
                                    _hover={{ bg: globalStyles.green }}
                                >
                                    LOGIN
                                </Button>
                            </Stack>
                        </Form>
                    )}

                </Formik>
                <Box textAlign="center" mt={4}>
                    <Link as={ReachLink} to="/forgotpassword" color={globalStyles.darkGrey} textAlign="center">
                        Forgot Password
                    </Link>
                </Box>
            </Box>

        </Box >
    )
}
export default LoginPage;