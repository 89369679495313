import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, CSSReset } from '@chakra-ui/core';
import { ToastProvider } from 'react-toast-notifications'
import './index.css';
import App from './App';
import { customTheme } from './theme';
import * as serviceWorker from './serviceWorker';
import ContextProvider from './components/context/Context';

ReactDOM.render(

  <ContextProvider>
    <ThemeProvider theme={customTheme}>
      <CSSReset />
      <ToastProvider
        autoDismiss
        autoDismissTimeout={3000}>
        <App />
      </ToastProvider>
    </ThemeProvider>
  </ContextProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
