import * as Yup from 'yup';

export const createBidValidationSchema = Yup.object({
    description: Yup.string().required("Description is required"),
    unitprice: Yup.string().required("Unit Price is required"),
    supplierQuantity: Yup.string().required("Quantity is required"),
    invoicePaymentTerms: Yup.string().required("Invoice Terms required"),
    deliveryDate: Yup.string().required("Delivery Date is required"),
    deliveryTerm: Yup.string().required("Delivery Term is required"),
    unitId: Yup.string().required("Unit is required"),
    itemName: Yup.string().required("Item Name is required"),
    itemDescription: Yup.string().required("Item Description is required"),
    fixedPrice: Yup.string().required(),

})
