const rules = {
    1: {
        static: [
            "dashboard:visit",
            "user-management:visit",
            "manage-roles-sub:visit",
            "configuration:visit",
            "tenants:visit",
            "audit-log:visit",
            // "manage-users-sub:visit",

            "manage-suppliers:visit",
            "manage-bids:visit",
            "configuration:visit",
            "tenants:filter",
            "super-manage-sourcing:visit",
            "user:password-change"
        ]
    },
    2: {
        static: [
            "admin-dash:view",
            "dashboard:visit",
            "user-management:visit",
            "manage-users-sub:visit",
            "manage-users:add",
            "manage-users:upload",
            "manage-suppliers:visit",
            "manage-bids:visit",
            "manage-tenders:add",
            "manage-tenders:delete",
            "configuration:visit",
            "merge-contracts:visit",
            "audit-trail:visit",
            "manage-sourcing:visit",
            "categories:manage",
            "create:admin",
            "performace:manage",
            "locations:visit",
            "notifications:visit",
            "departments:visit",
            "departments:add",
            "units:visit",
            "units:add",
            "new-sourcing"

        ],
        dynamic: {
            "tenders:edit": ({ status }) => {
                if (!status) return true
                return status === 0;
            },
            "user:edit": ({ userRole, user }) => {
                if (!userRole) return false;
                if (!user) return false;
                if (userRole === 4) return false;
                return userRole !== 2 || user === userRole;
            },
            "user:delete": ({ userRole }) => {
                if (!userRole) return false;
                return userRole === 3;
                // return true;
            },
        }
    },
    3: {
        static: [
            "admin-dash:view",
            "dashboard:visit",
            "user-management:visit",
            "manage-users-sub:visit",
            // "manage-users:add",
            "manage-users:upload",
            "manage-suppliers:visit",
            "manage-bids:visit",
            "manage-tenders:add",
            "tenders:edit",
            "manage-tenders:delete",
            "configuration:visit",
            "merge-contracts:visit",
            "audit-trail:visit",
            "manage-sourcing:visit",
            // "categories:manage",
            "performace:manage",
            "locations:visit",
            "new-sourcing"

        ]
    },
    4: {
        static: [
            "supplier-dash:view",
            "dashboard:visit",
            "tenders:visit",
            "bids:visit",
            "merge-contracts:visit",
            "audit-trail:visit",
            "reverse-auction:visit"
        ],
        dynamic: {
            "tenders:respond": ({ hasBid, status }) => {
                if (hasBid === undefined) return false;
                return hasBid === 0 && status === 0

            },
            "bid:edit": ({ status }) => {
                if (!status) return false;
                return status === 1;
            }
        }
    },
};

export default rules;




