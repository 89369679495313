import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button as AntButton } from 'antd';
import { Form, Formik, Field } from 'formik';
import {
    Box, Stack, Flex, Heading, FormControl, FormLabel, Input, FormErrorMessage,
    Button,
    Select,
    Skeleton,
    useToast
} from '@chakra-ui/core';
import axios from 'axios';

import { ArrowLeftOutlined } from '@ant-design/icons';
import globalStyles from '../../globalStyles'
import { addSubCategorySchema } from './helpers/validationSchema';
import { Context } from '../context/Context'
import { useCategories } from './helpers/fetcher';
import config from '../utils/data/config'
import FormikControl from '../utils/form/FormControl';



function AddSubCategory() {
    const { user } = useContext(Context)
    const toast = useToast()
    const history = useHistory()

    const { data, isLoading } = useCategories("fetchcategories", user.token)


    const requestOptions = {
        headers: { "Authorization": `Bearer ${user.token}` },
    }

    const initialValues = {
        subcategory: '',
        description: '',
        categoryId: ''
    }

    const handleSubmit = async (values, onSubmitProps) => {
        const body = { ...values }
        try {
            const res = await axios.post(`${config.baseUrl}/createsubcategory`, body, requestOptions)
            if (res.data.error) {
                showToast("error", res.data.error)
            }
            else {
                showToast("success", "Successfully Added Sub Category")
                history.goBack()
            }
        }
        catch (e) {
            showToast("error", e.message)
        }
        onSubmitProps.setSubmitting(false)
    }

    const showToast = (status, message) => {
        toast({
            title: message.toUpperCase(),
            status: status,
            duration: 2000,
            isClosable: true,
        })
    }

    return (
        <Box>
            <Stack spacing={6}>
                <Flex justify="space-between">
                    <Heading as="h1" size="sm" color={globalStyles.green}>Add Sub Category</Heading>
                    <Flex>
                        <AntButton onClick={history.goBack} type="link" icon={<ArrowLeftOutlined />}>Back</AntButton>
                    </Flex>
                </Flex>
                <Box shadow="lg" borderWidth="1px" p="5" w="50%">
                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={initialValues}
                        validationSchema={addSubCategorySchema}
                    >
                        {
                            formik => (
                                <Form>
                                    <Stack spacing={8}>
                                        <Box w="80%">
                                            <Field name="subcategory">
                                                {(props) => {
                                                    const { field, form } = props
                                                    return (
                                                        <FormControl
                                                            isInvalid={form.errors.subcategory && form.touched.subcategory}
                                                            isRequired
                                                        >
                                                            <FormLabel htmlFor="subcategory" mb={2}>
                                                                <Heading as="a" fontSize="sm">Sub Category</Heading>
                                                            </FormLabel>
                                                            <Input
                                                                {...field}
                                                                id="subcategory"
                                                                border="0"
                                                                bg={globalStyles.lightGrey}
                                                                focusBorderColor={globalStyles.green} />

                                                            <FormErrorMessage>{form.errors.subcategory}</FormErrorMessage>
                                                        </FormControl>
                                                    )
                                                }}
                                            </Field>
                                            <br />
                                            <FormikControl
                                                control="textarea"
                                                type="text"
                                                label="Description"
                                                name="description"
                                                placeholder="Description"
                                                required={false}
                                            />
                                            <br />
                                            <Field name="categoryId">
                                                {(props) => {
                                                    const { field, form } = props
                                                    return (
                                                        <FormControl
                                                            isInvalid={form.errors.categoryId && form.touched.categoryId}
                                                            isRequired
                                                        >
                                                            <FormLabel htmlFor="categoryId" mb={2}>
                                                                <Heading as="a" fontSize="sm">Category</Heading>
                                                            </FormLabel>
                                                            <Skeleton isLoaded={!isLoading}>
                                                                <Select
                                                                    {...field}
                                                                    placeholder="Select Category"
                                                                    id="categoryId"
                                                                    bg={globalStyles.lightGrey}
                                                                    border="0"
                                                                    focusBorderColor={globalStyles.green}
                                                                >
                                                                    {
                                                                        data.map(category => (
                                                                            <option key={category.id} value={category.id}>{category.category}</option>
                                                                        ))
                                                                    }
                                                                </Select>
                                                            </Skeleton>
                                                            <FormErrorMessage>{form.errors.categoryId}</FormErrorMessage>
                                                        </FormControl>
                                                    )
                                                }}
                                            </Field>
                                        </Box>
                                    </Stack>
                                    <Box mt={8}>
                                        <Button
                                            disabled={formik.isSubmitting}
                                            isLoading={formik.isSubmitting}
                                            loadingText="Submitting"
                                            type="submit"
                                            bg={globalStyles.green}
                                            color="white"
                                            _hover={{ bg: globalStyles.green }}
                                        >
                                            Submit
                                        </Button>
                                    </Box>
                                </Form>
                            )
                        }
                    </Formik>
                </Box>
            </Stack>
        </Box >
    )
}

export default AddSubCategory;