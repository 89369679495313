import React, { useContext, useState } from 'react';
import { mutate } from 'swr';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Space, message } from 'antd';
import { Link } from 'react-router-dom';
import {
    Box, Stack, Flex, Heading, Text, Skeleton
} from '@chakra-ui/core';
import axios from 'axios';
import { ArrowLeftOutlined, DownloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import globalStyles from '../../globalStyles'
import { Context } from '../context/Context';
import { useTender } from './helpers/fetcher';
import Can from '../utils/rbac/Can'
import { downloadFile } from '../utils/download';
import config from '../utils/data/config';

function ViewTender() {
    const [closing, setClosing] = useState(false)
    const history = useHistory()

    const location = useLocation()
    const id = location.pathname.split("/")[2]

    const { user } = useContext(Context);
    const roleString = user.role

    const path = roleString === 4 ? `suppliergettenderbyid/${id}` : `gettenderbyid/${id}`

    const { data, isLoading } = useTender(path, user.token)

    console.log(data)


    const closeTender = async () => {
        setClosing(true)
        const body = { id: data.id }
        try {
            const res = await axios.post(`${config.baseUrl}/adminclosetender`, body, { headers: { "Authorization": `Bearer ${user.token}` } })
            if (res.data.error) {
                message.error(res.data.error);
            }
            else {
                message.success('Successfully closed tender');
                mutate([path, user.token])
            }
            setClosing(false)
        }
        catch (e) {
            console.log(e.response)
            setClosing(false)
            message.error(e.message);
        }
    }

    return (
        <Box>
            <Stack spacing={6}>
                <Flex justify="space-between">
                    <Heading as="h1" size="sm" color={globalStyles.green}>View Tender</Heading>
                    <Flex>
                        <Button onClick={history.goBack} type="link" icon={<ArrowLeftOutlined />}>Back</Button>
                    </Flex>
                </Flex>
                <Box shadow="lg" borderWidth="1px" p="5">
                    <Flex justify="space-between">
                        <Box pt={5} pb={10} w="70%">
                            <Space size={20} direction="vertical">
                                <Skeleton isLoaded={!isLoading}>
                                    <Space size={40}>
                                        <Box w="150px">
                                            <Heading as="h4" color={globalStyles.darkGrey} fontSize="md">Tender Name:</Heading>
                                        </Box>
                                        <Text color={globalStyles.darkGrey} fontSize="md">{data && data.tenderName}</Text>
                                    </Space>
                                </Skeleton>
                                <Skeleton isLoaded={!isLoading}>
                                    <Space size={40}>
                                        <Box w="150px">
                                            <Heading as="h4" color={globalStyles.darkGrey} fontSize="md">Description:</Heading>
                                        </Box>
                                        <Text color={globalStyles.darkGrey} fontSize="md">{data && data.description}</Text>
                                    </Space>
                                </Skeleton>
                                <Skeleton isLoaded={!isLoading}>
                                    <Space size={40}>
                                        <Box w="150px">
                                            <Heading as="h4" color={globalStyles.darkGrey} fontSize="md">Category:</Heading>
                                        </Box>
                                        <Text color={globalStyles.darkGrey} fontSize="sm">{data && data.category && data.category.category}</Text>
                                    </Space>
                                </Skeleton>
                                <Skeleton isLoaded={!isLoading}>
                                    <Space size={40}>
                                        <Box w="150px">
                                            <Heading as="h4" color={globalStyles.darkGrey} fontSize="md">Sub Category:</Heading>
                                        </Box>
                                        <Text color={globalStyles.darkGrey} fontSize="sm">{data && data.subcategory && data.subcategory.subcategory}</Text>
                                    </Space>
                                </Skeleton>
                                <Skeleton isLoaded={!isLoading}>
                                    <Space size={40}>
                                        <Box w="150px">
                                            <Heading as="h4" color={globalStyles.darkGrey} fontSize="md">Department:</Heading>
                                        </Box>
                                        <Text color={globalStyles.darkGrey} fontSize="sm">{data?.department}</Text>
                                    </Space>
                                </Skeleton>
                                <Skeleton isLoaded={!isLoading}>
                                    <Space size={40}>
                                        <Box w="150px">
                                            <Heading as="h4" color={globalStyles.darkGrey} fontSize="md">Quantity:</Heading>
                                        </Box>
                                        <Text color={globalStyles.darkGrey} fontSize="sm">{data && data.quantity}</Text>
                                    </Space>
                                </Skeleton>
                                <Skeleton isLoaded={!isLoading}>
                                    <Space size={40}>
                                        <Box w="150px">
                                            <Heading as="h4" color={globalStyles.darkGrey} fontSize="md">Date Created:</Heading>
                                        </Box>
                                        <Text color={globalStyles.darkGrey} fontSize="sm">{data && dayjs(data.created_at).format('DD MMMM YYYY')}</Text>
                                    </Space>

                                </Skeleton>
                                <Skeleton isLoaded={!isLoading}>
                                    <Space size={40}>
                                        <Box w="150px">
                                            <Heading as="h4" color={globalStyles.darkGrey} fontSize="md">Due Date:</Heading>
                                        </Box>
                                        <Text color={globalStyles.darkGrey} fontSize="sm">{data && dayjs(data.dueDate).format('DD MMMM YYYY')}</Text>
                                    </Space>
                                </Skeleton>
                                <Skeleton isLoaded={!isLoading}>
                                    <Space size={40}>
                                        <Box w="150px">
                                            <Heading as="h4" color={globalStyles.darkGrey} fontSize="md">Status:</Heading>
                                        </Box>
                                        <Text color={globalStyles.darkGrey} fontSize="sm">
                                            {data && data.status === 0 ?
                                                <span style={{ fontWeight: 'bold', color: 'green' }}>Open</span>
                                                :
                                                <span style={{ fontWeight: 'bold', color: 'red' }}>Closed</span>
                                            }
                                        </Text>
                                    </Space>
                                </Skeleton>
                                <Skeleton isLoaded={!isLoading}>
                                    <Space size={40}>
                                        <Box w="150px">
                                            <Heading as="h4" color={globalStyles.darkGrey} fontSize="md">Download Additional Tender Instructions:</Heading>
                                        </Box>
                                        {data.attachement ?
                                            <Button
                                                onClick={() => downloadFile(`${config.baseUrl}/admindownloadattachement/${data.attachement}`)}
                                                icon={<DownloadOutlined />}
                                                type="default"
                                                style={{ color: globalStyles.green }}>Click to Download</Button>
                                            :
                                            <Text color={globalStyles.darkGrey} fontSize="sm">N/A</Text>}
                                    </Space>
                                </Skeleton>
                                <Skeleton isLoaded={!isLoading}>
                                    <Heading as="h3" color={globalStyles.green} fontSize="lg">Bid Terms:</Heading>
                                </Skeleton>
                                <Skeleton isLoaded={!isLoading}>
                                    <Space size={40}>
                                        <Box w="150px">
                                            <Heading as="h4" color={globalStyles.darkGrey} fontSize="md">Unit:</Heading>
                                        </Box>
                                        <Text color={globalStyles.darkGrey} fontSize="sm">{data?.unit}</Text>
                                    </Space>
                                </Skeleton>
                                <Skeleton isLoaded={!isLoading}>
                                    <Space size={40}>
                                        <Box w="150px">
                                            <Heading as="h4" color={globalStyles.darkGrey} fontSize="md">Delivery Date:</Heading>
                                        </Box>
                                        <Text color={globalStyles.darkGrey} fontSize="sm">{data && dayjs(data.deliveryDate).format('DD MMMM YYYY')}</Text>
                                    </Space>
                                </Skeleton>
                                <Skeleton isLoaded={!isLoading}>
                                    <Space size={40}>
                                        <Box w="150px">
                                            <Heading as="h4" color={globalStyles.darkGrey} fontSize="md">Fixed Price:</Heading>
                                        </Box>
                                        <Text color={globalStyles.darkGrey} fontSize="sm">{data?.fixedPrice === "1" ? "Yes" : "No"}</Text>
                                    </Space>
                                </Skeleton>
                                <Skeleton isLoaded={!isLoading}>
                                    <Space size={40}>
                                        <Box w="150px">
                                            <Heading as="h4" color={globalStyles.darkGrey} fontSize="md">Delivery Expectations:</Heading>
                                        </Box>
                                        <Text color={globalStyles.darkGrey} fontSize="sm">{data?.deliveryTerm === "1" ? "Staggered" : "Bulk"}</Text>
                                    </Space>
                                </Skeleton>
                                <Skeleton isLoaded={!isLoading}>
                                    <Space size={40}>
                                        <Box w="150px">
                                            <Heading as="h4" color={globalStyles.darkGrey} fontSize="md">Payment Terms:</Heading>
                                        </Box>
                                        <Text color={globalStyles.darkGrey} fontSize="sm">{data?.invoicePaymentTerms} Days</Text>
                                    </Space>
                                </Skeleton>
                            </Space>

                        </Box>
                        <Box>
                            <Flex>
                                <Space size={20}>
                                    {!isLoading &&
                                        <Can
                                            role={roleString}
                                            perform="tenders:edit"
                                            data={{
                                                status: data.status,
                                            }}
                                            yes={() => (
                                                <Button
                                                    loading={closing}
                                                    onClick={closeTender} type="primary">Close Tender</Button>
                                            )}
                                            no={() => null}
                                        />
                                    }
                                    {!isLoading &&
                                        <Can
                                            role={roleString}
                                            perform="tenders:respond"
                                            data={{
                                                hasBid: data.hasBidded,
                                                status: data.status
                                            }}
                                            yes={() => (
                                                <Link to={`/supplier-bids/add/${id}`}>
                                                    <Button type="primary">Respond</Button>
                                                </Link>
                                            )}
                                            no={() => null}
                                        />
                                    }
                                </Space>
                            </Flex>
                        </Box>
                    </Flex>
                </Box>
            </Stack>
        </Box >
    )
}

export default ViewTender;