import React from 'react';
export const renderRole = (role) => {
    switch (role) {
        case 1: return "Super Admin"
        case 2: return "Admin"
        case 3: return "User"
        case 4: return "Supplier"
        default: return "Unknown Role"
    }
}


export const renderRoleString = (role) => {
    switch (role) {
        case 1: return "superadmin"
        case 2: return "admin"
        case 3: return "user"
        case 4: return "supplier"
        default: return "unknown"
    }
}


export const renderAdminAction = (action) => {
    switch (action) {
        case "0": return <span style={{ color: "orange", fontWeight: 'bold' }}>Pending</span>
        case "3": return <span style={{ color: "green", fontWeight: 'bold' }}>Won</span>
        case "4": return <span style={{ color: "red", fontWeight: 'bold' }}>Lost</span>
        default: return <span>Unknown</span>
    }
}