import React, { useContext, useState } from 'react';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import {
    Box, Stack, Flex, Heading, FormControl, FormErrorMessage,
    FormLabel,
    Grid,
    Button,
    Select,
    useToast,
    Skeleton,
} from '@chakra-ui/core';
import { Formik, Form, Field } from 'formik';

import { Button as AntButton } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import globalStyles from '../../globalStyles'
import { addSupplierSchema } from './helpers/validationSchemas';
import config from '../utils/data/config';
import { useCategories, useSubCategories } from '../categories/helpers/fetcher';
import { useUser } from '../users/helpers/fetcher';
import { Context } from '../context/Context';
import FormikControl from '../utils/form/FormControl';
import { LinkAddCategories, LinkAddSubCategories } from '../utils/link';

const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    category: "",
    subcategory: "",
    businessName: "",
    rcnumber: "",
    website: "",
    address: "",
    role: 4
}

function EditSupplier() {
    const [selectedCategory, setSelectedCategory] = useState(null)
    const history = useHistory()
    const toast = useToast()
    const { user } = useContext(Context)

    const location = useLocation()
    const id = location.pathname.split("/")[3]

    const { data } = useUser(`getuserbyid/${id}`, user.token)
    const categories = useCategories("fetchcategories", user.token)


    const { subcategories, isLoading: subcategoryLoading, isWaiting } = useSubCategories("fetchsubcategorybycategoryid", user.token, selectedCategory ? selectedCategory : data.category)

    const savedValues = Object.entries(data).length > 0 ? {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        role: data.role,
        businessName: data.supplyInfo.businessName,
        category: data.category,
        subcategory: data.subcategory,
        rcnumber: data.supplyInfo.rcnumber,
        website: data.supplyInfo.website,
        address: data.supplyInfo.address,
    } : initialValues
    const handleSubmit = async (values, onSubmitProps) => {
        const body = { ...values, id: data.id }
        try {
            const res = await axios.post(`${config.baseUrl}/editsupplier`, body, { headers: { "Authorization": `Bearer ${user.token}` } })
            if (res.data.error) {
                showToast("error", res.data.error)
            }
            else {
                showToast("success", "Successfully edited supplier")
                history.goBack()
            }
        }
        catch (e) {
            console.log(e)
            showToast("error", e.message)
        }
        onSubmitProps.setSubmitting(false)
    }

    const showToast = (status, message) => {
        toast({
            title: message.toUpperCase(),
            status: status,
            duration: 2000,
            isClosable: true,
        })
    }

    return (
        <Box>
            <Stack spacing={6}>
                <Flex justify="space-between">
                    <Heading as="h1" size="sm" color={globalStyles.green}>Edit Supplier</Heading>
                    <Flex>
                        <AntButton onClick={history.goBack} type="link" icon={<ArrowLeftOutlined />}>Back</AntButton>
                    </Flex>
                </Flex>
                <Box shadow="lg" borderWidth="1px" p="5">
                    <Formik
                        enableReinitialize={true}
                        onSubmit={handleSubmit}
                        initialValues={savedValues}
                        validationSchema={addSupplierSchema}
                    >
                        {
                            formik => (
                                <Form>
                                    <Stack spacing={8}>
                                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                            <Box w="80%">
                                                <FormikControl
                                                    control="input"
                                                    type="text"
                                                    label="Company Name"
                                                    name="businessName"
                                                    placeholder="Company Name"
                                                />
                                            </Box>
                                            <Box w="80%">
                                                <FormikControl
                                                    control="input"
                                                    type="text"
                                                    label="RC Number"
                                                    prefix="RC-"
                                                    name="rcnumber"
                                                    placeholder="RC Number"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                            <Box w="80%">
                                                <FormikControl
                                                    control="input"
                                                    type="text"
                                                    label="Website"
                                                    name="website"
                                                    placeholder="Website"
                                                    required={false}
                                                />
                                            </Box>
                                            <Box w="80%">
                                                <Field name="category">
                                                    {(props) => {
                                                        const { field, form } = props
                                                        return (
                                                            <FormControl
                                                                isInvalid={form.errors.category && form.touched.category}
                                                                isRequired
                                                            >
                                                                <FormLabel htmlFor="category" mb={2}>
                                                                    <Heading as="a" fontSize="sm">Category</Heading>
                                                                </FormLabel>
                                                                <Skeleton isLoaded={!categories.isLoading}>
                                                                    <Select
                                                                        {...field}
                                                                        placeholder="Select a Category"
                                                                        id="category"
                                                                        onChange={(e) => {
                                                                            form.handleChange(e)
                                                                            setSelectedCategory(e.target.value)
                                                                        }}
                                                                        bg={globalStyles.lightGrey}
                                                                        border="0"
                                                                        focusBorderColor={globalStyles.green}
                                                                    >
                                                                        {
                                                                            categories.data.map(item => (
                                                                                <option key={item.id} value={item.id}>{item.category}</option>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </Skeleton>
                                                                <FormErrorMessage>{form.errors.category}</FormErrorMessage>
                                                            </FormControl>
                                                        )
                                                    }}
                                                </Field>
                                                <LinkAddCategories />
                                            </Box>
                                        </Grid>
                                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                            <Box w="80%">
                                                <Field name="subcategory">
                                                    {(props) => {
                                                        const { field, form } = props
                                                        return (
                                                            <FormControl
                                                                isInvalid={form.errors.subcategory && form.touched.subcategory}
                                                            >
                                                                <FormLabel htmlFor="subcategory" mb={2}>
                                                                    <Heading as="a" fontSize="sm">Sub Category</Heading>
                                                                </FormLabel>
                                                                <Skeleton isLoaded={!subcategoryLoading || !isWaiting}>
                                                                    <Select
                                                                        {...field}
                                                                        placeholder="Select Sub Category"
                                                                        id="subcategory"
                                                                        bg={globalStyles.lightGrey}
                                                                        border="0"
                                                                        focusBorderColor={globalStyles.green}
                                                                    >
                                                                        {
                                                                            subcategories.map(subcategory => (
                                                                                <option key={subcategory.id} value={subcategory.id}>{subcategory.subcategory}</option>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </Skeleton>
                                                                <FormErrorMessage>{form.errors.subcategory}</FormErrorMessage>
                                                            </FormControl>
                                                        )
                                                    }}
                                                </Field>
                                                <LinkAddSubCategories />
                                            </Box>
                                        </Grid>
                                        <br />
                                        <Heading as="h2" fontSize="sm" color={globalStyles.green}>Contact Details</Heading>
                                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                            <Box w="80%">
                                                <FormikControl
                                                    control="input"
                                                    type="text"
                                                    label="First Name"
                                                    name="firstName"
                                                    placeholder="First Name"
                                                />
                                            </Box>
                                            <Box w="80%">
                                                <FormikControl
                                                    control="input"
                                                    type="text"
                                                    label="Last Name"
                                                    name="lastName"
                                                    placeholder="Last Name"
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                            <Box w="80%">
                                                <FormikControl
                                                    control="input"
                                                    type="email"
                                                    label="Email"
                                                    name="email"
                                                    placeholder="Email"
                                                />
                                            </Box>
                                            <Box w="80%">
                                                <FormikControl
                                                    control="input"
                                                    type="text"
                                                    label="Phone Number"
                                                    name="phone"
                                                    placeholder="Phone Number"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                            <Box w="80%">
                                                <FormikControl
                                                    control="textarea"
                                                    type="text"
                                                    label="Address"
                                                    name="address"
                                                    placeholder="Address"
                                                />
                                            </Box>
                                        </Grid>
                                    </Stack>
                                    <Box mt={8}>
                                        <Button
                                            disabled={formik.isSubmitting}
                                            isLoading={formik.isSubmitting}
                                            loadingText="Submitting"
                                            type="submit"
                                            bg={globalStyles.green}
                                            color="white"
                                            _hover={{ bg: globalStyles.green }}
                                        >
                                            Submit
                                        </Button>
                                    </Box>
                                </Form>
                            )
                        }

                    </Formik>
                </Box>
            </Stack>
        </Box >
    )
}

export default EditSupplier;