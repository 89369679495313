import React, { useContext, useState } from 'react';
import { mutate } from 'swr';
import { Box, Stack, Heading, Flex, useDisclosure, useToast, Select, Text } from '@chakra-ui/core'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Button, Space } from 'antd';
import config from '../utils/data/config';
import { useCategories, useSubCategories } from '../categories/helpers/fetcher';
import { useSuperAdminTenants } from '../tenants/helpers/fetcher';
import { Context } from '../context/Context'
import globalStyles from '../../globalStyles';
import { useSuppliers } from './helpers/fetcher';
import UploadFile from '../upload/UploadDragger';
import SuppliersTable from './helpers/data/suppliers';
import Can from '../utils/rbac/Can';

function ManageSuppliers() {
    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { user } = useContext(Context)

    const roleString = user.role;
    const [page, setPage] = useState(1)
    const [selectedCategory, setSelectedCategory] = useState("")
    const [selectedSubCategory, setSelectedSubCategory] = useState("")
    const [selectedTenant, setSelectedTenant] = useState("")

    const filterPage = `?page=${page}`;
    const filterCategory = selectedCategory ? `&categoryid=${selectedCategory}` : ''
    const filterSubCategory = selectedSubCategory ? `&subcategoryid=${selectedSubCategory}` : ''
    const filterTenant = selectedTenant ? `&tenantid=${selectedTenant}` : ''

    const categories = useCategories("fetchcategoriesadminandprocurementuserbelong", user.token)
    const { subcategories } = useSubCategories("fetchsubcategorybycategoryid", user.token, selectedCategory)

    const filterPath = filterPage + filterCategory + filterSubCategory + filterTenant

    const supplierUrl = roleString === 1 ? `superadminfiltersuppliers${filterPath}` : `filtersuppliers${filterPath}`;

    const { data, isLoading } = useSuppliers(supplierUrl, user.token)
    const { data: tenants } = useSuperAdminTenants('fetchtenants', user.token, roleString)

    const suppliers = data ? data.user : []

    const pagination = {
        pageSize: 10,
        total: data ? data.totalNumber : 0,
        current: data ? parseInt(data.page) : 1,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
    }

    const handleTableChange = (pagination) => {
        setPage(pagination.current)
    }


    const checkValue = async (val, record) => {
        const body = {
            userId: record.id,
            is_active: val ? 1 : 0
        }
        //mutate
        try {
            const res = await axios.post(`${config.baseUrl}/changeuseractivestatus`, body, { headers: { "Authorization": `Bearer ${user.token}` } })
            if (res.data.error) {
                showToast("error", res.data.error)
            }
            else {
                mutate([`${config.baseUrl}/getallusers`, user.token])
                showToast("success", "Successfully changed status")
            }
        }
        catch (e) {
            console.log(e)
            showToast("error", e.message)
        }
    }

    const showToast = (status, message) => {
        toast({
            title: message.toUpperCase(),
            status: status,
            duration: 2000,
            isClosable: true,
        })
    }

    return (
        <Box>
            <Stack spacing={6}>
                <Flex justify="space-between">
                    <Heading as="h1" size="sm" color={globalStyles.green}>Manage Suppliers</Heading>
                    <Flex>
                        <Can
                            role={roleString}
                            perform="manage-users:add"
                            yes={() => (
                                <Space size={24}>
                                    <Link to="/suppliers/add">
                                        <Button type="primary">Add New Supplier</Button>
                                    </Link>
                                    <Button onClick={onOpen} type="primary">Upload Suppliers</Button>
                                </Space>
                            )}
                            no={() =>
                                null
                            }
                        />

                    </Flex>
                </Flex>
                <Box bg={globalStyles.green} px={4} py={2} rounded="md">
                    <Stack isInline spacing={8} align="center">
                        <Text color="white" fontSize="md">Filter By:</Text>
                        <Select
                            w="25%"
                            placeholder="Category"
                            onChange={(e) => {
                                setPage(1)
                                setSelectedCategory(e.target.value)
                                setSelectedSubCategory("")
                            }}
                            bg={globalStyles.lightGrey}
                            border="0"
                            focusBorderColor={globalStyles.green}
                        >
                            {
                                categories.data.map(item => (
                                    <option key={item.id} value={item.id}>{item.category}</option>
                                ))
                            }
                        </Select>
                        <Select
                            w="25%"
                            placeholder="Sub Category"
                            bg={globalStyles.lightGrey}
                            onChange={(e) => {
                                setSelectedSubCategory(e.target.value)
                            }}
                            border="0"
                            focusBorderColor={globalStyles.green}
                        >
                            {
                                subcategories.map(subcategory => (
                                    <option key={subcategory.id} value={subcategory.id}>{subcategory.subcategory}</option>
                                ))
                            }
                        </Select>
                        <Can
                            role={roleString}
                            perform="tenants:filter"
                            yes={() => (
                                <Select
                                    w="25%"
                                    placeholder="Tenants"
                                    onChange={(e) => {
                                        setPage(1)
                                        setSelectedTenant(e.target.value)
                                    }}
                                    bg={globalStyles.lightGrey}
                                    border="0"
                                    focusBorderColor={globalStyles.green}
                                >
                                    {
                                        tenants.map(item => (
                                            <option key={item.id} value={item.id}>{item.tenantName}</option>
                                        ))
                                    }
                                </Select>
                            )}
                            no={() =>
                                null
                            }
                        />

                    </Stack>
                </Box>
                <Box shadow="lg" borderWidth="1px" p="5">
                    <SuppliersTable
                        onChange={handleTableChange}
                        pagination={pagination}
                        loading={isLoading}
                        dataSource={suppliers ? suppliers.filter(item => item.role === 4) : []}
                        checkValue={checkValue}
                    />
                    {/* <Table
                        size="middle"
                        onChange={handleTableChange}
                        pagination={pagination}
                        rowKey={record => record.id}
                        loading={isLoading}
                        columns={columns}
                        dataSource={suppliers ? suppliers.filter(item => item.role === 4) : []} /> */}
                </Box>
            </Stack>
            <UploadFile
                downloadUrl="downloadsuppliersample"
                isOpen={isOpen}
                onClose={onClose}
                module="Suppliers"
                uploadUrl="bulkuploadsuppliers"
                previewUrl="previewsuppliersexcel" />
        </Box>

    )
}
export default ManageSuppliers;