import useSWR from 'swr';
import config from '../../utils/data/config';
import { fetcher } from '../../utils/data/fetcher';


export const useTenders = (path, token) => {
    const { data, error } = useSWR([`${config.baseUrl}/${path}`, token], fetcher)
    //show error here
    return {
        data: data ? data : {},
        tenders: data ? data.tenders : [],
        isLoading: !error && !data,
        isError: error
    }
}


export const useTender = (path, token) => {
    const { data, error } = useSWR([`${config.baseUrl}/${path}`, token], fetcher)
    //show error here
    return {
        data: data ? data.tender : {},
        isLoading: !error && !data,
        isError: error
    }
}



