import React from 'react';
import { Line } from 'react-chartjs-2';

function LineChart(props) {

    const { chartData } = props
    const labels = chartData.map(item => Object.keys(item)[0])
    const bids = chartData.map(item => Object.values(item)[0].bids)
    const tenders = chartData.map(item => Object.values(item)[0].tenders)
    const data = {
        labels,
        datasets: [
            {
                label: "Number of Tenders",
                data: tenders,
                borderColor: ['#009688'],
                backgroundColor: ['rgba(0,150,136,0.2)']
            },
            {
                label: "Number of Submitted Bids",
                data: bids,
                borderColor: ['#fadcac'],
                backgroundColor: ['rgba(250,220,172,0.5)']
            }
        ]

    }
    return (
        <Line
            data={data}
            width={100}
            height={400}
            options={{
                maintainAspectRatio: false,
                scales: { xAxes: [{ gridLines: { display: false } }] }

            }}
        />
    )
}

export default LineChart