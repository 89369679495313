import React, { useContext } from 'react';
import { Box, Stack, Heading, Flex } from '@chakra-ui/core';
import { Table } from 'antd';
import globalStyles from '../../globalStyles';
import { Context } from '../context/Context';
import { useAuditLogs } from './helpers/fetcher';
import dayjs from 'dayjs';

function AuditLog() {
    const columns = [
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Email Address',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
        },
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'address',
            render: (text) => {
                const date = dayjs(text).format("DD-MM-YYYY hh:mm:a")
                return (
                    <span>{date}</span>
                )
            }
        },
    ];

    const { user } = useContext(Context)

    const { data, isLoading } = useAuditLogs("viewauditlog", user.token)


    return (
        <Box>
            <Stack spacing={6}>
                <Flex justify="space-between">
                    <Heading as="h1" size="sm" color={globalStyles.green}>Audit Logs</Heading>
                </Flex>
                <Box shadow="lg" borderWidth="1px" p="5">
                    <Table rowKey={record => record.id} loading={isLoading} columns={columns} dataSource={data} pagination={null} />
                </Box>
            </Stack>

        </Box>

    )
}
export default AuditLog;