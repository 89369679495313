import React from 'react';
import { Table } from 'antd';

const { Column } = Table;

function UploadPreviewData(props) {
    return (
        <Table rowKey={record => record.email} loading={props.loading} dataSource={props.dataSource}>
            <Column
                title="Company"
                dataIndex="businessName"
                key="businessName"
            />
            <Column
                title="First Name"
                dataIndex="firstName"
                key="firstName"
            />
            <Column
                title="Last Name"
                dataIndex="lastName"
                key="lastName"
            />
            <Column
                title="Email"
                dataIndex="email"
                key="email"
            />
            <Column
                title="Phone"
                dataIndex="phone"
                key="phone"
            />
            <Column
                title="Cateogry"
                dataIndex="category"
                key="category"
            />
            <Column
                title="Sub-Category"
                dataIndex="subcategory"
                key="subcategory"
            />
            <Column
                title="RC Number"
                dataIndex="rcnumber"
                key="rcnumber"
            />
            <Column
                title="Tenant"
                dataIndex="tenantName"
                key="tenantName"
            />

            <Column
                title="Website"
                dataIndex="status"
                key="status"
            />
        </Table>
    )
}

export default UploadPreviewData;

