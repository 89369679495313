import config from '../utils/data/config'


export const downloadFile = (link) => {
    console.log(link)
    window.open(`${config.baseUrl}/admindownloadattachement/${link}`, "_blank")

}

// import React, { useContext, useState, useEffect } from 'react';
// import { Box, Stack, Heading, Flex, Text, Select } from '@chakra-ui/core'
// import { useHistory } from 'react-router-dom';
// import axios from 'axios';
// import { Table, Button, Row, Col, Spin } from 'antd';
// import { useSWRInfinite } from "swr";
// import { Context } from '../context/Context'
// import { useBidsByTenderId } from './helpers/fetcher';
// import { useCategories, useSubCategories } from '../categories/helpers/fetcher';
// import globalStyles from '../../globalStyles';
// import dayjs from 'dayjs';
// import "./helpers/styles.css"
// import config from '../utils/data/config';
// import { formatCurrency } from '../utils/formatCurrency';



// const fetcher = (url, token) =>
//     axios.get(url, { headers: { "Authorization": `Bearer ${token}` } }).then(res => res.data)


// function ReverseAuction() {
//     const history = useHistory()
//     const { user } = useContext(Context);
//     const columns = [
//         {
//             title: 'Supplier',
//             dataIndex: 'supplierid',
//             key: 'supplierid',
//         },
//         {
//             title: 'Unit',
//             dataIndex: 'units',
//             key: 'units',
//         },
//         {
//             title: 'Requested Quantity',
//             dataIndex: 'requestedQuantity',
//             key: 'requestedQuantity',
//         },
//         {
//             title: 'Bid Quantity',
//             dataIndex: 'supplierQuantity',
//             key: 'supplierQuantity',
//         },
//         {
//             title: 'Unit Bid Price',
//             dataIndex: 'unitprice',
//             key: 'unitprice',
//             render: (text) => {
//                 return <span>{formatCurrency(parseInt(text))}</span>
//             }
//         },
//         {
//             title: 'Total Bid Price',
//             dataIndex: 'totalbidprice',
//             key: 'totalbidprice',
//             render: (text) => {
//                 return <span>{formatCurrency(parseInt(text))}</span>
//             }
//         },

//         {
//             title: 'Action',
//             dataIndex: 'action',
//             key: 'action',
//             render: (_, record) => {
//                 return (
//                     <Button type="link" onClick={() => history.push(`/bids/${record.id}`)}>
//                         View
//                     </Button >
//                 )
//             }
//         },
//     ];

//     const [page, setPage] = useState(1)

//     const [selectedSupplier, setSelectedSupplier] = useState(null)
//     const [selectedCategory, setSelectedCategory] = useState("")
//     const [selectedSubCategory, setSelectedSubCategory] = useState("")


//     const [selectedTender, setSelectedTender] = useState(null)

//     const filterCategory = selectedCategory ? `&categoryid=${selectedCategory}` : ''
//     const filterSubCategory = selectedSubCategory ? `&subcategoryid=${selectedSubCategory}` : ''

//     const filterPath = filterCategory + filterSubCategory

//     const filterPage = `&page=${page}`;
//     const filterSupplier = selectedSupplier ? `&supplierid=${selectedSupplier}` : ''

//     const bidsFilterPath = filterPage + filterSupplier;

//     const { data: bidsForTender, isLoading } = useBidsByTenderId(selectedTender ? `filterbidsbytenderid?tenderid=${selectedTender}${bidsFilterPath}` : null, user.token)
//     const { data: categories } = useCategories("fetchcategoriesadminandprocurementuserbelong", user.token)
//     const { subcategories } = useSubCategories("fetchsubcategorybycategoryid", user.token, selectedCategory)


//     const { data, error, size, setSize, isValidating } = useSWRInfinite(
//         index => [`${config.baseUrl}/filtertenders?page=${index + 1}${filterPath}`, user.token],
//         fetcher, { revalidateOnFocus: false }
//     );

//     const tenderData = data ? [].concat(...data) : [];
//     const isLoadingInitialData = !data && !error;
//     const isLoadingMore =
//         isLoadingInitialData ||
//         (size > 0 && data && typeof data[size - 1] === "undefined");
//     const isEmpty = data?.[0]?.length === 0;
//     const isReachingEnd =
//         isEmpty || (data && data[data.length - 1]?.length < 10);
//     const isRefreshing = isValidating && data && data.length === size;

//     useEffect(() => {
//         if (!selectedTender && tenderData.length) {
//             setSelectedTender(tenderData[0].tenders[0].id)
//         }
//     }, [tenderData, selectedTender])

//     console.log(bidsForTender)

//     return (
//         <Box>
//             <Stack spacing={6}>
//                 <Flex justify="space-between">
//                     <Heading as="h1" size="sm" color={globalStyles.green}>Reverse Auction</Heading>
//                 </Flex>
//                 <Box bg={globalStyles.green} px={4} py={2} rounded="md">
//                     <Stack isInline spacing={8} align="center">
//                         <Text color="white" fontSize="md">Filter By:</Text>
//                         <Select
//                             w="25%"
//                             placeholder="Category"
//                             id="category"
//                             onChange={(e) => {
//                                 setSelectedTender(null)
//                                 setSelectedCategory(e.target.value)
//                             }}
//                             bg={globalStyles.lightGrey}
//                             border="0"
//                             focusBorderColor={globalStyles.green}
//                         >
//                             {
//                                 categories.map(item => (
//                                     <option key={item.id} value={item.id}>{item.category}</option>
//                                 ))
//                             }
//                         </Select>
//                         <Select
//                             w="25%"
//                             placeholder="Sub Category"
//                             id="subcategoryId"
//                             bg={globalStyles.lightGrey}
//                             onChange={(e) => {
//                                 setSelectedSubCategory(e.target.value)
//                             }}
//                             border="0"
//                             focusBorderColor={globalStyles.green}
//                         >
//                             {
//                                 subcategories.map(subcategory => (
//                                     <option key={subcategory.id} value={subcategory.id}>{subcategory.subcategory}</option>
//                                 ))
//                             }
//                         </Select>
//                     </Stack>
//                 </Box>

//                 <Row gutter={24}>
//                     <Col span={6}>
//                         <div className="tender-box">
//                             <Heading as="h1" size="sm" color={globalStyles.green}>Tenders</Heading>
//                             <Box shadow="lg" borderWidth="1px">
//                                 {isEmpty ? <p>No Tenders</p> : null}

//                                 {tenderData.map(data => data.tenders.map(tender => {
//                                     return (
//                                         <Box
//                                             cursor="pointer"
//                                             onClick={() => setSelectedTender(tender.id)}
//                                             bg={tender.id === selectedTender ? "#e1f7f4" : "white"}
//                                             key={tender.id}
//                                             p={4}
//                                         >
//                                             <Text color={globalStyles.green} fontSize="md" fontWeight={700}>
//                                                 {tender.tenderName}
//                                             </Text>
//                                             <h4>{tender.category.category}</h4>
//                                             <h4>Created: {dayjs(tender.created_at).format('DD/MM/YYYY')} </h4>
//                                             <h4>Due: {dayjs(tender.dueDate).format('DD/MM/YYYY')}</h4>
//                                             <br />
//                                         </Box>
//                                     )
//                                 }))}
//                                 <Box textAlign="center" my={5}>
//                                     <Button
//                                         disabled={isLoadingMore || isReachingEnd}
//                                         onClick={() => setSize(size + 1)}
//                                     >
//                                         {isLoadingMore
//                                             ? <Spin />
//                                             : isReachingEnd
//                                                 ? "no more issues"
//                                                 : "load more"}
//                                     </Button>
//                                 </Box>
//                             </Box>
//                         </div>
//                     </Col>
//                     <Col span={18}>
//                         <Box shadow="lg" borderWidth="1px" p="5">
//                             <Table rowKey={record => record.id} loading={isLoading} columns={columns} dataSource={bidsForTender} />
//                         </Box>
//                     </Col>
//                 </Row>
//             </Stack>

//         </Box>

//     )
// }
// export default ReverseAuction;