import React, { useContext } from 'react';
import { Upload, message } from 'antd';
import { Context } from '../context/Context';
import config from '../utils/data/config';


function UploadButton(props) {
    const { user } = useContext(Context)

    const uploadProps = {
        name: 'file',
        multiple: false,
        accept: props.accept ? "application/pdf" : undefined,
        action: `${config.baseUrl}/${props.path}`,
        headers: { "Authorization": `Bearer ${user.token}` },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                props.setFile(info.file.response.filename)
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    }
    return (
        <Upload {...uploadProps}>
            {props.children}
        </Upload>
    )
}

export default UploadButton;