import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button as AntButton } from 'antd';
import { Form, Formik } from 'formik';
import {
    Box, Stack, Flex, Heading,
    Button,
    Grid, useToast
} from '@chakra-ui/core';
import axios from 'axios';

import { ArrowLeftOutlined } from '@ant-design/icons';
import globalStyles from '../../globalStyles'
import { addTenantValidationSchema } from './helpers/validationSchemas';
import { Context } from '../context/Context'
import config from '../utils/data/config'
import FormikControl from '../utils/form/FormControl';


const initialValues = {
    firstName: "",
    lastName: "",
    tenantName: "",
    email: "",
    phone: "",
    description: "",
}

function AddNewTenant() {
    const { user } = useContext(Context)
    const toast = useToast()
    const history = useHistory()

    const requestOptions = {
        headers: { "Authorization": `Bearer ${user.token}` }
    }

    const handleSubmit = async (values, onSubmitProps) => {
        try {
            const res = await axios.post(`${config.baseUrl}/createtenant`, values, requestOptions)
            if (res.data.error) {
                showToast("error", res.data.error)
            }
            else {
                showToast("success", "Successfully added tenant")
                history.goBack()
            }
        }
        catch (e) {
            showToast("error", e.message)
        }
        onSubmitProps.setSubmitting(false)
    }

    const showToast = (status, message) => {
        toast({
            title: message.toUpperCase(),
            status: status,
            duration: 2000,
            isClosable: true,
        })
    }

    return (
        <Box>
            <Stack spacing={6}>
                <Flex justify="space-between">
                    <Heading as="h1" size="sm" color={globalStyles.green}>Add New Tenant</Heading>
                    <Flex>
                        <AntButton onClick={history.goBack} type="link" icon={<ArrowLeftOutlined />}>Back</AntButton>
                    </Flex>
                </Flex>
                <Box shadow="lg" borderWidth="1px" p="5">
                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={initialValues}
                        validationSchema={addTenantValidationSchema}
                    >
                        {
                            formik => (
                                <Form>
                                    <Stack spacing={8}>
                                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                            <Box w="80%">
                                                <FormikControl
                                                    control="input"
                                                    type="text"
                                                    label="First Name"
                                                    name="firstName"
                                                    placeholder="First Name"
                                                />
                                            </Box>
                                            <Box w="80%">
                                                <FormikControl
                                                    control="input"
                                                    type="text"
                                                    label="Last Name"
                                                    name="lastName"
                                                    placeholder="Last Name"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                            <Box w="80%">
                                                <FormikControl
                                                    control="input"
                                                    type="text"
                                                    label="Tenant Name"
                                                    name="tenantName"
                                                    placeholder="Tenant Name"
                                                />
                                            </Box>
                                            <Box w="80%">
                                                <FormikControl
                                                    control="input"
                                                    type="email"
                                                    label="Emai Address"
                                                    name="email"
                                                    placeholder="Email Address"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                            <Box w="80%">
                                                <FormikControl
                                                    control="input"
                                                    type="text"
                                                    label="Phone Number"
                                                    name="phone"
                                                    placeholder="Phone Number"
                                                />
                                            </Box>
                                            <Box w="80%">
                                                <FormikControl
                                                    control="textarea"
                                                    type="text"
                                                    label="Description"
                                                    name="description"
                                                    placeholder="Description"
                                                />
                                            </Box>
                                        </Grid>
                                    </Stack>
                                    <Box mt={8}>
                                        <Button
                                            disabled={formik.isSubmitting}
                                            isLoading={formik.isSubmitting}
                                            loadingText="Submitting"
                                            type="submit"
                                            bg={globalStyles.green}
                                            color="white"
                                            _hover={{ bg: globalStyles.green }}
                                        >
                                            Submit
                                        </Button>
                                    </Box>
                                </Form>
                            )
                        }
                    </Formik>
                </Box>
            </Stack>
        </Box>
    )
}

export default AddNewTenant;