import React, { useContext } from 'react';
import { Table, Switch } from 'antd';
import { useHistory } from 'react-router-dom';
import { IconButton, } from '@chakra-ui/core';
import Can from '../../../utils/rbac/Can';
import globalStyles from '../../../../globalStyles';
import { Context } from '../../../context/Context';

const { Column } = Table;

function UsersTable(props) {
    const history = useHistory()
    const { user } = useContext(Context)

    const roleString = user.role;
    return (
        <Table size="middle" rowKey={record => record.id} loading={props.loading} dataSource={props.dataSource}>
            <Column
                title='Name'
                dataIndex='name'
                key='name'
                render={(_, record) => {
                    return (
                        <span>{record.firstName} {record.lastName}</span>
                    )

                }}

            />
            <Column
                title='Email'
                dataIndex='email'
                key='email'
            />
            <Column
                title='Phone Number'
                dataIndex='phone'
                key='phone'
            />
            <Column
                title='Role'
                dataIndex='roleName'
                key='roleName'
            />
            <Column
                title='Category'
                dataIndex='role'
                key='role'
                render={(text, record) => {
                    if (text === 4) {
                        return <span>{record.categoryName}</span>
                    }
                    const categories = record.categoriesList
                    if (!categories.length) {
                        return <span></span>
                    }
                    else if (categories.length === 1) {
                        return <span>{categories[0]}</span>
                    }
                    else {
                        return <span>{categories[0]} <span style={{ color: globalStyles.green }}>+ {categories.length - 1} More</span></span>
                    }
                }}
            />
            {
                roleString === 1 && <Column
                    title='Tenant Name'
                    dataIndex='tenantName'
                    key='tenantName'
                    render={(text, record) => {
                        return (
                            <span>{text.tenantName}</span>
                        )

                    }}
                />
            }
            <Column
                title='Created By'
                dataIndex='createdBy'
                key='createdBy'
                render={(text, record) => {
                    return (
                        <span>{text.name}</span>
                    )
                }}
            />
            <Column
                title='Status'
                dataIndex='is_active'
                key='is_active'
                render={(text, record) => {
                    return (
                        <Switch
                            onChange={(val) => props.checkValue(val, record)}
                            checkedChildren={"Active"}
                            unCheckedChildren={"Inactive"}
                            checked={text === 1}
                        />
                    )
                }}
            />
            <Column
                title='Edit'
                dataIndex='edit'
                key='edit'
                render={(_, record) => {
                    return (
                        <Can
                            role={roleString}
                            perform="user:edit"
                            data={{
                                userRole: record.role,
                                user: user.role

                            }}
                            yes={() => (
                                <IconButton
                                    size="sm"
                                    onClick={() => history.push(`/user/${record.id}`)}
                                    variant="outline"
                                    isRound
                                    aria-label="Send email"
                                    icon="edit"
                                />
                            )}
                            no={() =>
                                <IconButton
                                    size="sm"
                                    isDisabled
                                    variant="outline"
                                    isRound
                                    aria-label="Send email"
                                    icon="edit"
                                />
                            }
                        />

                    )
                }}
            />
            <Column
                title='Delete'
                dataIndex='delete'
                key='delete'
                render={(_, record) => {
                    return (
                        <Can
                            role={roleString}
                            perform="user:delete"
                            data={{
                                userRole: record.role,
                            }}
                            yes={() => (
                                <IconButton
                                    size="sm"
                                    onClick={() => props.deleteUser(record.id)}
                                    variant="outline"
                                    variantColor="red"
                                    isRound
                                    aria-label="Send email"
                                    icon="delete"
                                />
                            )}
                            no={() =>
                                <IconButton
                                    size="sm"
                                    isDisabled
                                    variant="outline"
                                    variantColor="red"
                                    isRound
                                    aria-label="Send email"
                                    icon="delete"
                                />
                            }
                        />

                    )
                }}
            />
            {
                roleString === 1 && <Column
                    title='Change Auth'
                    dataIndex='id'
                    key='id'
                    render={(text) => {
                        return (
                            <IconButton
                                size="sm"
                                onClick={() => history.push(`/change-auth/${text}`)}
                                variant="outline"
                                isRound
                                aria-label="Send email"
                                icon="lock"
                            />
                        )

                    }}
                />
            }
        </Table>
    )
}

export default UsersTable;


