import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button as AntButton, Space } from 'antd';
import {
    Box, Stack, Flex, Heading, Tabs, TabList, TabPanels, Tab, TabPanel, Text, Skeleton
} from '@chakra-ui/core';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { renderAdminAction } from '../utils/renderRole';
import globalStyles from '../../globalStyles'
import { Context } from '../context/Context';
import { useSupplier } from './helpers/fetcher';
import { useBidHistory } from '../bids/helpers/fetcher';
import BidHistoryTable from './helpers/data/bidHistory';

function ViewSupplier() {
    const history = useHistory()

    const location = useLocation()
    const id = location.pathname.split("/")[2]

    const { user } = useContext(Context);

    const { data, isLoading } = useSupplier(`getuserbyid/${id}`, user.token)
    const { bidHistory, loadingBidHistory } = useBidHistory(`supplierbidhistory/${id}`, user.token)

    const supplyInfo = data.supplyInfo

    console.log(bidHistory)

    const titles = ["Supplier Information", "Performance History", "Bid History"]
    return (
        <Box>
            <Stack spacing={6}>
                <Flex justify="space-between">
                    <Heading as="h1" size="sm" color={globalStyles.green}>View Supplier</Heading>
                    <Flex>
                        <AntButton onClick={history.goBack} type="link" icon={<ArrowLeftOutlined />}>Back</AntButton>
                    </Flex>
                </Flex>
                <Box shadow="lg" borderWidth="1px" p="5">
                    <Tabs variant="unstyled">
                        <TabList mb="1em" bg={globalStyles.green} p={3}>
                            {titles.map(title => (
                                <Tab
                                    key={title}
                                    color="white"
                                    fontSize="sm"
                                    fontWeight='bold'
                                    _focus={{ border: '0' }}
                                    w="20%"
                                    rounded="lg"
                                    _selected={{ color: globalStyles.darkGrey, bg: "white" }}
                                >
                                    {title}
                                </Tab>
                            ))}
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <Box pt={5} pb={10}>
                                    <Space size={20} direction="vertical">
                                        <Skeleton isLoaded={!isLoading}>
                                            <Space>
                                                <Heading as="h4" color={globalStyles.darkGrey} fontSize="md">Company Name:</Heading>
                                                <Text color={globalStyles.darkGrey} fontSize="sm">{supplyInfo && supplyInfo.businessName}</Text>
                                            </Space>
                                        </Skeleton>
                                        <Skeleton isLoaded={!isLoading}>
                                            <Space>
                                                <Heading as="h4" color={globalStyles.darkGrey} fontSize="md">RC Number:</Heading>
                                                <Text color={globalStyles.darkGrey} fontSize="sm">{supplyInfo && supplyInfo.rcnumber}</Text>
                                            </Space>
                                        </Skeleton>
                                        <Skeleton isLoaded={!isLoading}>
                                            <Space>
                                                <Heading as="h4" color={globalStyles.darkGrey} fontSize="md">Category:</Heading>
                                                <Text color={globalStyles.darkGrey} fontSize="sm">{data && data.categoryName}</Text>
                                            </Space>
                                        </Skeleton>
                                        <Skeleton isLoaded={!isLoading}>
                                            <Space>
                                                <Heading as="h4" color={globalStyles.darkGrey} fontSize="md">Website:</Heading>
                                                <Text color={globalStyles.darkGrey} fontSize="sm">{supplyInfo && supplyInfo.website}</Text>
                                            </Space>
                                        </Skeleton>
                                        <Heading as="h4" mt={5} color={globalStyles.green} fontSize="md">Contact Details</Heading>
                                        <Skeleton isLoaded={!isLoading}>
                                            <Space>
                                                <Heading as="h4" color={globalStyles.darkGrey} fontSize="md">First Name:</Heading>
                                                <Text color={globalStyles.darkGrey} fontSize="sm">{data && data.firstName}</Text>
                                            </Space>

                                        </Skeleton>

                                        <Skeleton isLoaded={!isLoading}>
                                            <Space>
                                                <Heading as="h4" color={globalStyles.darkGrey} fontSize="md">Last Name:</Heading>
                                                <Text color={globalStyles.darkGrey} fontSize="sm">{data && data.lastName}</Text>
                                            </Space>
                                        </Skeleton>


                                        <Skeleton isLoaded={!isLoading}>
                                            <Space>
                                                <Heading as="h4" color={globalStyles.darkGrey} fontSize="md">Email Address:</Heading>
                                                <Text color={globalStyles.darkGrey} fontSize="sm">{data && data.email}</Text>
                                            </Space>
                                        </Skeleton>


                                        <Skeleton isLoaded={!isLoading}>
                                            <Space>
                                                <Heading as="h4" color={globalStyles.darkGrey} fontSize="md">Phone Number:</Heading>
                                                <Text color={globalStyles.darkGrey} fontSize="sm">{data && data.phone}</Text>
                                            </Space>
                                        </Skeleton>

                                    </Space>
                                </Box>
                            </TabPanel>
                            <TabPanel>
                                <p>Performace History</p>
                            </TabPanel>
                            <TabPanel>
                                <BidHistoryTable
                                    loading={loadingBidHistory}
                                    dataSource={bidHistory || []}
                                    renderAdminAction={renderAdminAction}
                                />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Box>
            </Stack>
        </Box >
    )
}

export default ViewSupplier;