import { theme } from "@chakra-ui/core";

// Let's say you want to add custom colors
export const customTheme = {
    ...theme,
    shadows: {
        outline: 'none'
    },
    colors: {
        ...theme.colors,
        green: "#118575"
        // brand: {
        //   900: "#1a365d",
        //   800: "#153e75",
        //   700: "#2a69ac",
        // },
    },
}