import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { useToasts } from 'react-toast-notifications';
import {
    FormControl,
    FormErrorMessage,
    Input,
    Box,
    Heading,
    Text,
    Button,
    Stack,
} from "@chakra-ui/core";
import axios from 'axios';
import { resetPasswordValidationSchema } from './helpers/validationSchemas';
import globalStyles from '../../globalStyles';
import config from '../utils/data/config';



const initialValues = {
    password: "",
    password2: ""
}


function ResetPassword() {

    const { addToast } = useToasts()

    const location = useLocation()
    const history = useHistory()

    const code = location.pathname.split("/")[2]

    const handleSubmit = async (values, onSubmitProps) => {
        if (values.password !== values.password2) {
            showToast("error", "Passwords do not match")
            onSubmitProps.setSubmitting(false)
            return;
        }
        const body = { resetcode: code, password: values.password }
        try {
            const res = await axios.post(`${config.baseUrl}/resetpassword`, body)
            if (res.data.error) {
                showToast("error", res.data.message.toUpperCase())
            }
            else {
                showToast("success", res.data.message.toUpperCase())
                history.push("/login")
            }
        }
        catch (e) {
            showToast("error", e.message)
        }
        onSubmitProps.setSubmitting(false)
    }

    const showToast = (status, message) => {
        addToast(message, { appearance: status })
    }

    return (
        <Box>
            <Stack spacing={4} mt="4%">
                <Heading textAlign="center" color={globalStyles.green} mb={4} fontSize="3xl">Honeywell eProcurement</Heading>
                <Text as="i" textAlign="center" color={globalStyles.darkGrey} fontSize="xl">...Digitizing Supply Chains</Text>
            </Stack>
            <Box
                mx="auto"
                mt="5%"
                width={[
                    "90%", // base
                    "50%", // 480px upwards
                    "50%", // 768px upwards
                    "35%", // 992px upwards
                ]}
                p={10}
                shadow="lg" borderWidth="1px">
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    validationSchema={resetPasswordValidationSchema}
                >
                    {(formik) => (
                        <Form>
                            <Stack spacing={8}>
                                <Box>
                                    <Heading textAlign="center" color={globalStyles.green} mb={4} fontSize="xl">Reset Password</Heading>
                                </Box>
                                <Box>
                                    <Field name="password">
                                        {(props) => {
                                            const { field, form } = props
                                            return (
                                                <FormControl
                                                    isInvalid={form.errors.password && form.touched.password}
                                                    isRequired
                                                >
                                                    {/* <FormLabel htmlFor="password">Password</FormLabel> */}
                                                    <Input
                                                        focusBorderColor={globalStyles.green}
                                                        type="password"
                                                        {...field} id="password"
                                                        placeholder="Password"
                                                        bg="#F0F0F1"
                                                        border="0" />
                                                    <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                                                </FormControl>
                                            )
                                        }}
                                    </Field>
                                </Box>
                                <Box>
                                    <Field name="password2">
                                        {(props) => {
                                            const { field, form } = props
                                            return (
                                                <FormControl
                                                    isInvalid={form.errors.password2 && form.touched.password2}
                                                    isRequired
                                                >
                                                    {/* <FormLabel htmlFor="password">Password</FormLabel> */}
                                                    <Input
                                                        focusBorderColor={globalStyles.green}
                                                        type="password"
                                                        {...field} id="password2"
                                                        placeholder="Confirm Password"
                                                        bg="#F0F0F1"
                                                        border="0" />
                                                    <FormErrorMessage>{form.errors.password2}</FormErrorMessage>
                                                </FormControl>
                                            )
                                        }}
                                    </Field>
                                </Box>
                                <Button
                                    disabled={formik.isSubmitting}
                                    isLoading={formik.isSubmitting}
                                    loadingText="Submitting"
                                    width="100%"
                                    type="submit"
                                    bg={globalStyles.green}
                                    color="white"
                                    _hover={{ bg: globalStyles.green }}
                                >
                                    SUBMIT
                                </Button>
                            </Stack>
                        </Form>
                    )}

                </Formik>
            </Box>

        </Box >
    )
}
export default ResetPassword;