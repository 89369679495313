import React, { useContext } from 'react';
import { Box, Stack, Heading, Flex, Tabs, TabList, TabPanels, Tab, TabPanel, } from '@chakra-ui/core'
import { Context } from '../context/Context'
import { useOngoingBids, useClosedBids } from './helpers/fetcher';
import { closedColumns } from './helpers/tableData';
import OngoingBidsTable from './helpers/data/ongoing';
import globalStyles from '../../globalStyles';
import ClosedBidsTable from './helpers/data/closed';

function SupplierBids() {

    const { user } = useContext(Context);

    const { data: ongoingBids, isLoading: onGoingLoading } = useOngoingBids("supplierviewongoingbids", user.token)
    const { data: closedBids, isLoading: closedLoading } = useClosedBids("supplierviewclosedbids", user.token)


    const titles = ["Ongoing Bids", "Closed Bids"]

    return (
        <Box>
            <Stack spacing={6}>
                <Flex justify="space-between">
                    <Heading as="h1" size="sm" color={globalStyles.green}>Manage Bids</Heading>
                </Flex>
                <Box shadow="lg" borderWidth="1px" p="5">
                    <Tabs variant="unstyled">
                        <TabList mb="1em" bg={globalStyles.green} p={3}>
                            {titles.map(title => (
                                <Tab
                                    key={title}
                                    color="white"
                                    fontSize="sm"
                                    fontWeight='bold'
                                    _focus={{ border: '0' }}
                                    w="20%"
                                    rounded="lg"
                                    _selected={{ color: globalStyles.darkGrey, bg: "white" }}
                                >
                                    {title}
                                </Tab>
                            ))}
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <OngoingBidsTable loading={onGoingLoading} dataSource={ongoingBids} />
                            </TabPanel>
                            <TabPanel>
                                <ClosedBidsTable loading={closedLoading} columns={closedColumns} dataSource={closedBids} />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Box>
            </Stack>
        </Box>
    )
}
export default SupplierBids;