import React, { useContext } from 'react';
import { Switch, BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import './App.less';
import LoginPage from './components/auth/LoginPage';
import AppDrawer from './components/layout/Drawer'
import ManageRoles from './components/roles/ManageRoles';
import AddNewRole from './components/roles/AddNewRole';
import ManageUsers from './components/users/ManageUsers';
import AddNewUser from './components/users/AddNewUser';
import EditUser from './components/users/EditUser';
import DashPage from './components/home/DashPage'
import { Context } from './components/context/Context';
import ManageTenants from './components/tenants/ManageTenants';
import AddNewTenant from './components/tenants/AddNewTenant';
import ViewSupplier from './components/suppliers/ViewSupplier';
import ManageSuppliers from './components/suppliers/ManageSuppliers';
import AddNewSupplier from './components/suppliers/AddNewSupplier';
import EditTenant from './components/tenants/EditTenant';
import EditRole from './components/roles/EditRole';
import ForgotPassword from './components/auth/ForgotPassword';
import ResetPassword from './components/auth/ResetPassword';
import ManageBids from './components/bids/ManageBids';
import Configuration from './components/configuration/Configuration';
import AuditLog from './components/audit/AuditLog';
import AuditTrail from './components/audit/AuditTrail';
import ManageContracts from './components/contracts/ManageContracts';
import ManageTenders from './components/tenders/ManageTenders';
import AddTender from './components/tenders/AddTender';
import ViewTender from './components/tenders/ViewTender';
import SupplierBids from './components/bids/SupplierBids';
import Respond from './components/bids/Respond';
import ManageCategories from './components/categories/ManageCategories';
import ManageSubCategories from './components/categories/ManageSubCategories';
import AddCategory from './components/categories/AddCategory';
import AddSubCategory from './components/categories/AddSubCategory';
import EditCategory from './components/categories/EditCategory';
import EditSubCategory from './components/categories/EditSubCategory';
import AllSubCategories from './components/categories/AllSubCategories';
import EditBid from './components/bids/EditBid';
import ViewBid from './components/bids/ViewBid';
import CreateAdminUser from './components/users/CreateAdminUser';
import EditSupplier from './components/suppliers/EditSupplier';
import ReverseAuction from './components/bids/ReverseAuction';
import SupplierReverseAuction from './components/bids/SupplierReverseAuction';
import ReverseAuctionBids from './components/bids/ReverseAuctionBids';
import ManageLocations from './components/locations/ManageLocations';
import AddLocation from './components/locations/AddLocation';
import UploadPreview from './components/upload/UploadPreview';
import ChangePassword from './components/settings/ChangePassword';
import ChangeAuth from './components/users/ChangeAuth';
import NewNotification from './components/notifications/NewNotification';
import Setup from './components/notifications/Setup';
import Departments from './components/departments/Departments';
import AddDepartment from './components/departments/AddDepartment';
import ManageUnits from './components/units/Units';
import AddUnit from './components/units/AddUnit';
import Sourcing from './components/sourcing/Sourcing'


function App() {
  const { user } = useContext(Context);

  const PrivateRoute = ({ children, ...rest }) => {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          user.isAuthenticated ? (
            children
          ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: location }
                }}
              />
            )
        }
      />
    );
  }

  const UnAuthRoute = ({ children, ...rest }) => {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          !user.isAuthenticated ? (
            children
          ) : (
              <Redirect
                to={{
                  pathname: "/dashboard",
                  state: { from: location }
                }}
              />
            )
        }
      />
    )
  }
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/">
            <Redirect to="/dashboard" />
          </Route>
          <UnAuthRoute exact path="/login">
            <LoginPage />
          </UnAuthRoute>
          <UnAuthRoute exact path="/forgotpassword">
            <ForgotPassword />
          </UnAuthRoute>
          <UnAuthRoute exact path="/forgotpassword/:code">
            <ResetPassword />
          </UnAuthRoute>
          <AppDrawer>
            <PrivateRoute exact path="/dashboard">
              <DashPage />
            </PrivateRoute>
            <PrivateRoute exact path="/suppliers">
              <ManageSuppliers />
            </PrivateRoute>
            <PrivateRoute exact path="/suppliers/add">
              <AddNewSupplier />
            </PrivateRoute>
            <PrivateRoute exact path="/supplier/:id">
              <ViewSupplier />
            </PrivateRoute>
            <PrivateRoute exact path="/supplier/edit/:id">
              <EditSupplier />
            </PrivateRoute>
            <PrivateRoute exact path="/users">
              <ManageUsers />
            </PrivateRoute>
            <PrivateRoute exact path="/users/add">
              <AddNewUser />
            </PrivateRoute>
            <PrivateRoute exact path="/admin/add">
              <CreateAdminUser />
            </PrivateRoute>
            <PrivateRoute exact path="/user/:id">
              <EditUser />
            </PrivateRoute>
            <PrivateRoute exact path="/edit-profile/:id">
              <EditUser />
            </PrivateRoute>
            <PrivateRoute exact path="/change-auth/:id">
              <ChangeAuth />
            </PrivateRoute>
            <PrivateRoute exact path="/roles">
              <ManageRoles />
            </PrivateRoute>
            <PrivateRoute exact path="/roles/add">
              <AddNewRole />
            </PrivateRoute>
            <PrivateRoute exact path="/categories">
              <ManageCategories />
            </PrivateRoute>
            <PrivateRoute exact path="/categories/sub/:id">
              <ManageSubCategories />
            </PrivateRoute>
            <PrivateRoute exact path="/categories/sub/edit/:id">
              <EditSubCategory />
            </PrivateRoute>
            <PrivateRoute exact path="/subcategories">
              <AllSubCategories />
            </PrivateRoute>
            <PrivateRoute exact path="/categories/add">
              <AddCategory />
            </PrivateRoute>
            <PrivateRoute exact path="/categories/add/sub">
              <AddSubCategory />
            </PrivateRoute>
            <PrivateRoute exact path="/category/:id/edit">
              <EditCategory />
            </PrivateRoute>
            <PrivateRoute exact path="/tenants">
              <ManageTenants />
            </PrivateRoute>
            <PrivateRoute exact path="/tenants/add">
              <AddNewTenant />
            </PrivateRoute>
            <PrivateRoute exact path="/change-password">
              <ChangePassword />
            </PrivateRoute>
            <PrivateRoute exact path="/tenant/:id">
              <EditTenant />
            </PrivateRoute>
            <PrivateRoute exact path="/role/:id">
              <EditRole />
            </PrivateRoute>
            <PrivateRoute exact path="/tenders">
              <ManageTenders />
            </PrivateRoute>
            <PrivateRoute exact path="/tenders/add">
              <AddTender />
            </PrivateRoute>
            <PrivateRoute exact path="/tender/:id">
              <ViewTender />
            </PrivateRoute>
            <PrivateRoute exact path="/bids">
              <ManageBids />
            </PrivateRoute>
            <PrivateRoute exact path="/bids/:id">
              <ViewBid />
            </PrivateRoute>
            <PrivateRoute exact path="/supplier-bids">
              <SupplierBids />
            </PrivateRoute>
            <Route exact path="/supplier-bids/add/:id">
              <Respond />
            </Route>
            <PrivateRoute exact path="/supplier-bids/edit/:id">
              <EditBid />
            </PrivateRoute>
            <PrivateRoute exact path="/reverse-auction">
              <ReverseAuction />
            </PrivateRoute>
            <PrivateRoute exact path="/vendor-reverse-auction/:id">
              <ReverseAuctionBids />
            </PrivateRoute>
            <PrivateRoute exact path="/vendor-reverse-auction">
              <SupplierReverseAuction />
            </PrivateRoute>
            <PrivateRoute exact path="/configuration">
              <Configuration />
            </PrivateRoute>
            <PrivateRoute exact path="/audit-log">
              <AuditLog />
            </PrivateRoute>
            <PrivateRoute exact path="/audit-trail">
              <AuditTrail />
            </PrivateRoute>
            <PrivateRoute exact path="/contracts">
              <ManageContracts />
            </PrivateRoute>
            <PrivateRoute exact path="/locations">
              <ManageLocations />
            </PrivateRoute>
            <PrivateRoute exact path="/locations/add">
              <AddLocation />
            </PrivateRoute>
            <PrivateRoute exact path="/upload-preview">
              <UploadPreview />
            </PrivateRoute>
            <PrivateRoute exact path="/notifications/new">
              <NewNotification />
            </PrivateRoute>
            <PrivateRoute exact path="/notifications/setup">
              <Setup />
            </PrivateRoute>
            <PrivateRoute exact path="/departments">
              <Departments />
            </PrivateRoute>
            <PrivateRoute exact path="/departments/add">
              <AddDepartment />
            </PrivateRoute>
            <PrivateRoute exact path="/units">
              <ManageUnits />
            </PrivateRoute>
            <PrivateRoute exact path="/units/add">
              <AddUnit />
            </PrivateRoute>
          </AppDrawer>
          {/* <Route>
            <NotFoundPage />
          </Route> */}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
